import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Form/Button';
import * as actions from '../../actions';
import messages from '../../messages';
import styles from './DownloadDataModal.pcss';


class InstallBlueCableDrivers extends React.PureComponent {


  static propTypes = {
    // Explicit actions
    onSetComponent: PropTypes.func,
  };


  onInstall() {
    this.props.onSetComponent('InstallingBlueCableDrivers');
  }


  render() {
    return (
      <div className={styles.modal__content}>
        <h3 className="modal__subheader "><FormattedMessage {...messages.installBlueCableDrivers.header} /></h3>
        <p className="modal__info"><FormattedMessage {...messages.installBlueCableDrivers.info} /></p>
        <div className={styles.illustration}>
          <img src="/assets/svg/blue-cable-drivers.svg" alt="" />
        </div>
        <Button
          styleModifier="primary"
          labelMessage={messages.buttons.installBlueCableDrivers}
          className="btn--block btn--filled mb-0"
          onClick={() => this.onInstall()}
        />
        <p className="modal__info text--center d-flex align-items-center justify-content-center mt-3">
          <FormattedMessage {...messages.installBlueCableDrivers.elevatedPermissions} />
          <img src="/assets/img/uac-shield.png" className="ml-2" alt="" />
        </p>
        <div className={styles.divider}>
          <span className={styles.divider__text}><FormattedMessage {...messages.infos.or} /></span>
        </div>
        <Button
          styleModifier="transparent"
          labelMessage={messages.buttons.changeDeviceType}
          className="btn--no-size text--primary m-0"
          onClick={() => this.props.onSetComponent('ChooseConnection')}
        />
      </div>
    );
  }

}


const mapDispatchToProps = (dispatch) => ({
  onConnect                : () => dispatch(actions.connectBlueCable()),
  onStartCheckingConnection: (connectionId) => dispatch(actions.startCheckingConnection(connectionId)),
});


const ConnectedInstallBlueCableDrivers = connect(
  null,
  mapDispatchToProps,
)(InstallBlueCableDrivers);


export default ConnectedInstallBlueCableDrivers;
