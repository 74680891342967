import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import debounce from 'lodash/debounce';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import map from 'lodash/map';
import unionBy from 'lodash/unionBy';
import isObject from 'lodash/isObject';
import isFunction from 'lodash/isFunction';
import { useAction } from 'hooks';
import Alert from 'components/Alert';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import { transformSystemAlerts } from './helpers';


interface Props {
  className: string | object,
  isGlobal?: boolean
}


const onAction = (action, dispatch) => {
  if (isFunction(action)) {
    action();
  } else if (isObject(action)) {
    dispatch(action);
  }
};


const setAlertsEffect = (alerts, state, setState, isGlobal, onDisplayAlerts) => {
  const alertsTimeThreshold = useSelector(selectors.alertsTimeThreshold);
  const dismissedAlerts = useSelector(selectors.dismissedAlerts);
  const openModalId = useSelector(selectors.modal);

  const getAlertName = (alert) => (isObject(alert.message)
    ? `${alert.message.id}_${JSON.stringify(alert.messageValues)}`
    : alert.message);

  useEffect(() => {
    if (alerts.length || alertsTimeThreshold > state.alertsTimeThreshold) {
      const localAlerts = filter(alerts, (alert) => {
        const alertName = getAlertName(alert);
        return !includes(dismissedAlerts, alertName)
          && (
            !openModalId
            || (openModalId && ((isGlobal && alert.isGlobal) || (!isGlobal && !alert.isGlobal)))
          );
      });
      let unionAlerts = unionBy([...state.alerts].reverse(), localAlerts, (alert) => getAlertName(alert));
      unionAlerts = filter(unionAlerts, (alert) => alert.timestamp >= alertsTimeThreshold).reverse();
      setState({
        ...state,
        alerts   : unionAlerts,
        alertsTimeThreshold,
        isVisible: true,
      });
      onDisplayAlerts();
    }
  }, [alerts, alertsTimeThreshold]);
};


const setSystemAlertsEffect = (systemAlerts, state, setState, isGlobal, onDisplayAlerts) => {
  if (!isGlobal) {
    return;
  }
  const systemAlertsSettings = useSelector(selectors.systemAlertsSettings);
  const localizationResources = useSelector(selectors.localizationResources);
  useEffect(() => {
    if (systemAlerts.length) {
      let unionSystemAlerts = [];
      unionSystemAlerts = unionBy([...state.systemAlerts].reverse(), systemAlerts, (systemAlert) => (
        `${systemAlert.alertId}_${systemAlert.createTimestamp}`
      ));

      if (unionSystemAlerts.length <= state.systemAlerts.length) {
        return;
      }

      unionSystemAlerts = transformSystemAlerts(unionSystemAlerts, systemAlertsSettings, localizationResources)
        .filter((systemAlert) => systemAlert && systemAlert.message).reverse();

      setState({
        ...state,
        systemAlerts: unionSystemAlerts,
        isVisible   : true,
      });
      onDisplayAlerts();
    }
  }, [systemAlerts]);
};


const AlertsBus: FC<Props> = ({ className, isGlobal = false }) => {
  const dispatch = useDispatch();
  const alerts = useSelector(selectors.alerts);
  const systemAlerts = useSelector(selectors.systemAlerts);
  const route = useSelector(selectors.route);
  const prevRouteRef = useRef(null);
  const [state, setState] = useState({
    alerts                : [],
    systemAlerts          : [],
    alertsTimeThreshold   : +moment.utc().locale('en').format('X'),
    activeClinicMembership: null,
    isCollapsed           : false,
    isVisible             : false,
    // route,
  });

  const onClose = (alert) => {
    const { id, alertConfigurationId } = alert;
    if (alertConfigurationId) {
      setState({ ...state, systemAlerts: state.systemAlerts.filter((a) => a.id !== id) });
      dispatch(actions.dismissSystemAlert(alert));
    } else {
      setState({ ...state, alerts: state.alerts.filter((a) => a.id !== id) });
      if (alert.attributes && alert.attributes.isPinned) {
        dispatch(actions.dismissAlert(alert));
      }
    }
  };
  const onDisplayAlerts = debounce(useAction(actions.displayAlerts), 150);
  const onToggleCollapse = () => {
    setState((prevState) => ({ ...prevState, isCollapsed: !prevState.isCollapsed }));
  };

  setAlertsEffect(alerts, state, setState, isGlobal, onDisplayAlerts);
  setSystemAlertsEffect(systemAlerts, state, setState, isGlobal, onDisplayAlerts);

  useEffect(() => {
    if (!route.previousName || prevRouteRef.current === route) {
      return;
    }
    setState({ ...state, alerts: filter(state.alerts, (alert) => alert.attributes && alert.attributes.isPinned) });
    prevRouteRef.current = route;
  }, [route]);

  const allAlerts = [...state.alerts, ...state.systemAlerts];

  return (
    <motion.div
      className={cn('alertsBus', className)}
      initial={{ height: 0 }}
      animate={{ height: allAlerts.length ? 'auto' : 0 }}
      transition={{ ease: 'easeIn', duration: allAlerts.length ? 0.1 : 0.3 }}
      style={{ overflow: 'hidden' }}
    >
      <AnimatePresence>
        {
          map(allAlerts, (alert, idx) => (
            <motion.div
              key={`alert-${isObject(alert.message) ? alert.message.id : alert.message}-${alert.id}`}
              initial={{ opacity: 0, marginTop: state.isCollapsed ? '-55px' : '-55px' }}
              animate={{ opacity: 1, marginTop: idx && state.isCollapsed ? '-52px' : 0 }}
              exit={{ opacity: 0, marginTop: '-55px' }}
              transition={{ ease: 'linear', duration: 0.3 }}
              style={{ position: 'relative', zIndex: allAlerts.length - idx }}
            >
              <Alert
                idx={idx}
                isCollapsed={state.isCollapsed}
                {...alert}
                actions={
                  alert.actions && alert.actions.map((action) => ({
                    ...action,
                    action: () => onAction(action.action, dispatch),
                  }))
                }
                onClose={() => onClose(alert)}
                onToggleCollapse={onToggleCollapse}
              />
            </motion.div>
          ))
        }
      </AnimatePresence>
    </motion.div>
  );
};

export default AlertsBus;
