export const MODULE_ID = 'account';

export const PROFILE_TYPES = {
  HCP: 'hcpProfile',
  PWD: 'patientProfile',
  CG : 'cgProfile',
};

export const SCOPE_NAMES = {
  PROFESSIONAL: 'Professional',
  PERSONAL    : 'Personal',
  CAREGIVER   : 'Caregiver',
};

export const ADDITIONAL_CLAIMS = {
  clinicSettings      : 'clinic_settings',
  deleteAccount       : 'delete_account',
  deletePatientProfile: 'delete_patient_profile',
  revokeMembership    : 'revoke_hcp',
  delete_clinic       : 'delete_clinic',
};

export const CLINIC_MEMBERSHIP_DROPDOWN = `${MODULE_ID}/clinicMembershipDropdown`;

export const ELEVATE_PERMISSIONS_MODAL = `${MODULE_ID}/elevatePermissionsModal`;
export const GDPR_EXPORT_MODAL = `${MODULE_ID}/gdprExportModal`;

export const SETTINGS_FORM = `${MODULE_ID}.settingsForm`;
export const HCP_PROFILE_FORM = `${MODULE_ID}.hcpProfileForm`;
export const PATIENT_PROFILE_FORM = `${MODULE_ID}.patientProfileForm`;
export const CG_PROFILE_FORM = `${MODULE_ID}.cgProfileForm`;
export const ELEVATE_PERMISSIONS_FORM = `${MODULE_ID}.elevatePermissions`;
export const REAUTH_TO_CC_FORM = `${MODULE_ID}.reauthToCCForm`;
