import generateUrls from 'universal-router/generateUrls';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import XRegExp from 'xregexp';
import { slugify } from 'transliteration';


export const serializeQuery = (obj, { prefix = '', omitFalsy = false } = {}) => {
  const stringsArr = Object.keys(obj).map((p) => {
    const k = prefix ? `${prefix}[${p}]` : p;
    const v = obj[p];

    if (v !== null && typeof v === 'object') {
      return this.serialize(v, k);
    }
    if (!v && omitFalsy) {
      return '';
    }
    return `${encodeURIComponent(k)}=${encodeURIComponent(v)}`;
  });
  return (omitFalsy ? compact(stringsArr) : stringsArr).join('&');
};

/**
 * Creates reverse routing getter function which returns translated url for route name
 *
 * @param {Router} router - Router instance
 * @returns {func} function(
 *            {string} routeName - name defined in routes
 *            {Object} [params] - route url parameters
 *            {Object} [query] - get query params
 *           )
 */
export const translateURL = (router) => {
  const getUrl = generateUrls(router);

  return (routeName, params, query) => {
    const routeUrl = getUrl(routeName, params);
    const queryStr = !isEmpty(query) ? `?${serializeQuery(query)}` : '';
    return `${routeUrl}${queryStr}`;
  };
};


/**
 * Returns a named slug which can be used anywhere in the app
 * @param {string} name - Name of the entity
 * @param {number} id - Id of the entity
 * @returns {string}
 */
export const getSlug = (name, id) => {
  const regExp = new XRegExp('[^\\w\\d\\s\\p{L}]');
  const clearName = XRegExp.replace(name, regExp, ' ', 'all').trim();
  return `${slugify(`${clearName || 'i'}`)}${id ? `-${id}` : ''}`;
};
