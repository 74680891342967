import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { AppContext } from 'context';
import Button from 'components/Form/Button';
import messages from '../../messages';
import styles from '../../components/DownloadDataModal/DownloadDataModal.pcss';


class NoLicense extends React.PureComponent {

  static contextType = AppContext;

  static propTypes = {
    // Explicit actions
    onSetComponent     : PropTypes.func,
    // Implicit actions
    onInstallDownloader: PropTypes.func,
  };


  onTryAgain() {
    this.props.onSetComponent('Initializing');
  }


  render() {
    const tryAgainLabel = <FormattedMessage {...messages.buttons.tryAgain} />;
    return (
      <div className={styles.modal__content}>
        <h3 className="modal__subheader "><FormattedMessage {...messages.sccNoLicense.header} /></h3>
        <p className="modal__info">
          <FormattedMessage {...messages.sccNoLicense.info} values={{ tryAgainLabel }} />
        </p>
        <div className={styles.illustration}>
          <img className={styles.centralImage} src="/assets/svg/lock.svg" alt="" />
        </div>
        <div className="modal__actions">
          <Button
            styleModifier="primary"
            labelMessage={messages.buttons.tryAgain}
            className="btn--block btn--filled"
            onClick={() => this.onTryAgain()}
          />
        </div>
      </div>
    );
  }

}


export default NoLicense;
