import { defineMessages } from 'react-intl';


export default defineMessages({

  headers: {
    clickAboveToInstall              : { id: 'meter.headers.clickAboveToInstall' },
    clickBelowToInstall              : { id: 'meter.headers.clickBelowToInstall' },
    downloadDataFromMeter            : { id: 'meter.headers.downloadDataFromMeter' },
    howToEnablingPairing             : { id: 'meter.headers.howToEnablingPairing' },
    howToStartConnection             : { id: 'meter.headers.howToStartConnection' },
    patientResults                   : { id: 'meter.headers.patientResults' },
    yourResults                      : { id: 'meter.headers.yourResults' },
    connectedNewDeviceWarning        : { id: 'meter.headers.connectedNewDeviceWarning' },
    connectedFailed                  : { id: 'meter.headers.connectedFailed' },
    downloaderOutdated               : { id: 'meter.headers.downloaderOutdated' },
    noLicense                        : { id: 'meter.headers.noLicense' },
    warningsBeforeDownloadData       : { id: 'meter.headers.warningsBeforeDownloadData' },
    warningNewDeviceConnected        : { id: 'meter.headers.warningNewDeviceConnected' },
    warningMismatchTime              : { id: 'meter.headers.warningMismatchTime' },
    warningMismatchTargetRanges      : { id: 'meter.headers.warningMismatchTargetRanges' },
    warningMismatchTargetRangesHcp   : { id: 'meter.headers.warningMismatchTargetRangesHcp' },
    warningConnectedNewDeviceSingle  : { id: 'meter.headers.warningConnectedNewDeviceSingle' },
    warningMismatchTimeSingle        : { id: 'meter.headers.warningMismatchTimeSingle' },
    warningMismatchTargetRangesSingle: { id: 'meter.headers.warningMismatchTargetRangesSingle' },
    warningUnavailableTime           : { id: 'meter.headers.warningUnavailableTime' },
  },

  buttons: {
    addPatient                      : { id: 'meter.buttons.addPatient' },
    cancelImport                    : { id: 'meter.buttons.cancelImport' },
    changeDeviceType                : { id: 'meter.buttons.changeDeviceType' },
    changePatient                   : { id: 'meter.buttons.changePatient' },
    choosePatient                   : { id: 'meter.buttons.choosePatient' },
    previewResults                  : { id: 'meter.buttons.previewResults' },
    continueToDownload              : { id: 'meter.buttons.continueToDownload' },
    download                        : { id: 'meter.buttons.download' },
    downloadData                    : { id: 'meter.buttons.downloadData' },
    fromMeter                       : { id: 'meter.buttons.fromMeter' },
    importResults                   : { id: 'meter.buttons.importResults' },
    installBlueCableDrivers         : { id: 'meter.buttons.installBlueCableDrivers' },
    installDownloader               : { id: 'meter.buttons.installDownloader' },
    installScc                      : { id: 'meter.buttons.installScc' },
    next                            : { id: 'meter.buttons.next' },
    pairAndDownload                 : { id: 'meter.buttons.pairAndDownload' },
    proceed                         : { id: 'meter.buttons.proceed' },
    runReinstallDownloader          : { id: 'meter.buttons.runReinstallDownloader' },
    submitPin                       : { id: 'meter.buttons.submitPin' },
    tryAgain                        : { id: 'meter.buttons.tryAgain' },
    updateNow                       : { id: 'meter.buttons.updateNow' },
    waitForApproval                 : { id: 'meter.buttons.waitForApproval' },
    waitingForConnection            : { id: 'meter.buttons.waitingForConnection' },
    connectedNewDeviceWarningReject : { id: 'meter.buttons.connectedNewDeviceWarningReject' },
    connectedNewDeviceWarningApprove: { id: 'meter.buttons.connectedNewDeviceWarningApprove' },
    warningReject                   : { id: 'meter.buttons.warningReject' },
    warningAccept                   : { id: 'meter.buttons.warningAccept' },
  },

  labels: {
    bgm               : { id: 'meter.labels.bgm' },
    continueToNextStep: { id: 'meter.labels.continueToNextStep' },
    paired            : { id: 'meter.labels.paired' },
    pin               : { id: 'meter.labels.pin' },
    serialNumber      : { id: 'meter.labels.serialNumber' },
    readingsNumber    : { id: 'meter.labels.readingsNumber' },
    low               : { id: 'meter.labels.low' },
    target            : { id: 'meter.labels.target' },
    high              : { id: 'meter.labels.high' },
    measurements      : { id: 'meter.labels.measurements' },
    measurementsRange : { id: 'meter.labels.measurementsRange' },
    measurementsType  : { id: 'meter.labels.measurementsType' },
    importFrom        : { id: 'meter.labels.importFrom' },
    noPatientMatched  : { id: 'meter.labels.noPatientMatched' },
    androidStore      : { id: 'meter.labels.androidStore' },
    iOSStore          : { id: 'meter.labels.iOSStore' },
    forAndroid        : { id: 'meter.labels.forAndroid' },
    forIOS            : { id: 'meter.labels.forIOS' },
    contour           : { id: 'meter.labels.contour' },
    updateNow         : { id: 'meter.labels.updateNow' },
  },

  placeholders: {
    pin: { id: 'meter.placeholders.pin' },
  },

  initializing: {
    header : { id: 'meter.initializing.header' },
    waiting: { id: 'meter.initializing.waiting' },
  },

  installDownloader: {
    header: { id: 'meter.installDownloader.header' },
    info  : { id: 'meter.installDownloader.info' },
  },

  installingDownloader: {
    header : { id: 'meter.installingDownloader.header' },
    info   : { id: 'meter.installingDownloader.info' },
    waiting: { id: 'meter.installingDownloader.waiting' },
  },

  installedDownloader: {
    header: { id: 'meter.installedDownloader.header' },
  },

  downloaderOutdated: {
    info          : { id: 'meter.downloaderOutdated.info' },
    clickUpdateNow: { id: 'meter.downloaderOutdated.clickUpdateNow' },
    closeAppInfo  : { id: 'meter.downloaderOutdated.closeAppInfo' },
  },


  sccInstall: {
    header        : { id: 'meter.sccInstall.header' },
    downloadInfo  : { id: 'meter.sccInstall.downloadInfo' },
    pairingInfo   : { id: 'meter.sccInstall.pairingInfo' },
    connectionInfo: { id: 'meter.sccInstall.connectionInfo' },
  },

  sccDownload: {
    header     : { id: 'meter.sccDownload.header' },
    waitingInfo: { id: 'meter.sccDownload.waitingInfo' },
  },

  noConnection: {
    header: { id: 'meter.noConnection.header' },
    info  : { id: 'meter.noConnection.info' },
  },

  sccNoConnection: {
    header: { id: 'meter.sccNoConnection.header' },
    info  : { id: 'meter.sccNoConnection.info' },
  },

  sccNoLicense: {
    header: { id: 'meter.sccNoLicense.header' },
    info  : { id: 'meter.sccNoLicense.info' },
  },

  connectionFailed: {
    header   : { id: 'meter.connectionFailed.header' },
    sccHeader: { id: 'meter.connectionFailed.sccHeader' },
    info     : { id: 'meter.connectionFailed.info' },
    steps    : {
      first : { id: 'meter.connectionFailed.steps.first' },
      second: { id: 'meter.connectionFailed.steps.second' },
      third : { id: 'meter.connectionFailed.steps.third' },
    },
    questionSteps: {
      second: { id: 'meter.connectionFailed.questionSteps.second' },
    },
  },

  timeout: {
    header: { id: 'meter.timeout.header' },
  },

  installBlueCableDrivers: {
    header             : { id: 'meter.installBlueCableDrivers.header' },
    info               : { id: 'meter.installBlueCableDrivers.info' },
    elevatedPermissions: { id: 'meter.installBlueCableDrivers.elevatedPermissions' },
  },

  installingBlueCableDrivers: {
    header : { id: 'meter.installingBlueCableDrivers.header' },
    info   : { id: 'meter.installingBlueCableDrivers.info' },
    waiting: { id: 'meter.installingBlueCableDrivers.waiting' },
  },

  installedBlueCableDrivers: {
    header: { id: 'meter.installedBlueCableDrivers.header' },
  },

  chooseConnection: {
    header            : { id: 'meter.chooseConnection.header' },
    noSupportedDevices: { id: 'meter.chooseConnection.noSupportedDevices' },
  },

  connections: {
    BlueCable : { id: 'meter.connections.blueCable' },
    Bluetooth : { id: 'meter.connections.bluetooth' },
    BuiltInUsb: { id: 'meter.connections.usb' },
    MicroUsb  : { id: 'meter.connections.usbCable' },
    UsbC      : { id: 'meter.connections.usbCCable' },
  },

  bluetoothPin: {
    header: { id: 'meter.bluetoothPin.header' },
  },

  connectBluetooth: {
    howToStartConnection: { id: 'meter.connectBluetooth.howToStartConnection' },
    metersWithBluetooth : { id: 'meter.connectBluetooth.metersWithBluetooth' },
    noMeters            : { id: 'meter.connectBluetooth.noMeters' },
    showInstructionsFor : { id: 'meter.connectBluetooth.showInstructionsFor' },
    tags                : {
      paired  : { id: 'meter.connectBluetooth.tags.paired' },
      unpaired: { id: 'meter.connectBluetooth.tags.unpaired' },
    },
    longLight: {
      first : { id: 'meter.connectBluetooth.longLight.first' },
      second: { id: 'meter.connectBluetooth.longLight.second' },
      third : { id: 'meter.connectBluetooth.longLight.third' },
    },
    round3BtnOKBt: {
      first : { id: 'meter.connectBluetooth.round3BtnOKBt.first' },
      second: { id: 'meter.connectBluetooth.round3BtnOKBt.second' },
      third : { id: 'meter.connectBluetooth.round3BtnOKBt.third' },
    },
  },

  pairingBluetooth: {
    round3BtnOKBt: {
      first : { id: 'meter.pairingBluetooth.round3BtnOKBt.first' },
      second: { id: 'meter.pairingBluetooth.round3BtnOKBt.second' },
    },
  },

  connectBlueCable: {
    info  : { id: 'meter.connectBlueCable.info' },
    pressM: { id: 'meter.connectBlueCable.pressM' },
  },

  connectUsb: {
    info: { id: 'meter.connectUsb.info' },
  },

  connectUsbCable: {
    info: { id: 'meter.connectUsbCable.info' },
  },

  connectUsbCCable: {
    info: { id: 'meter.connectUsbCCable.info' },
  },

  downloading: {
    header : { id: 'meter.downloading.header' },
    info   : { id: 'meter.downloading.info' },
    waiting: { id: 'meter.downloading.waiting' },
  },

  addPatient: {
    header: { id: 'meter.addPatient.header' },
  },

  choosePatient: {
    header    : { id: 'meter.choosePatient.header' },
    info      : { id: 'meter.choosePatient.info' },
    listHeader: { id: 'meter.choosePatient.listHeader' },
  },

  infos: {
    or                                   : { id: 'meter.infos.or' },
    notAvailable                         : { id: 'meter.infos.notAvailable' },
    waitAndClickAboveToInstall           : { id: 'meter.infos.waitAndClickAboveToInstall' },
    waitAndClickBelowToInstall           : { id: 'meter.infos.waitAndClickBelowToInstall' },
    connectedNewDeviceWarning            : { id: 'meter.infos.connectedNewDeviceWarning' },
    warningConnectedNewDevice            : { id: 'meter.infos.warningConnectedNewDevice' },
    warningMismatchTime                  : { id: 'meter.infos.warningMismatchTime' },
    warningMismatchTimeSingle            : { id: 'meter.infos.warningMismatchTimeSingle' },
    warningUnavailableTime               : { id: 'meter.infos.warningUnavailableTime' },
    warningUnavailableTimeSingle         : { id: 'meter.infos.warningUnavailableTimeSingle' },
    warningMismatchTargetRanges          : { id: 'meter.infos.warningMismatchTargetRanges' },
    warningMismatchTargetRangesHcp       : { id: 'meter.infos.warningMismatchTargetRangesHcp' },
    warningConnectedNewDeviceSingle      : { id: 'meter.infos.warningConnectedNewDeviceSingle' },
    warningMismatchTargetRangesSingle    : { id: 'meter.infos.warningMismatchTargetRangesSingle' },
    warningMismatchTargetRangesSingleHcp : { id: 'meter.infos.warningMismatchTargetRangesSingleHcp' },
    warningMismatchTargetRangesSingleInfo: { id: 'meter.infos.warningMismatchTargetRangesSingleInfo' },
    patientsResults                      : { id: 'meter.infos.patientsResults' },
  },

  btErrors: {
    NotWindows10           : { id: 'meter.btErrors.NotWindows10' },
    BuildNumberNotCompliant: { id: 'meter.btErrors.BuildNumberNotCompliant' },
    NoBleTransmitter       : { id: 'meter.btErrors.NoBleTransmitter' },
  },

  errors: {
    businessErrors: {
      BluetoothPinError: { id: 'meter.errors.businessErrors.BluetoothPinError' },
    },
  },

});
