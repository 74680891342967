import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ReactTooltip from 'react-tooltip';
import { AnimatePresence, motion } from 'framer-motion';
import isEmpty from 'lodash/isEmpty';
import camelCase from 'lodash/camelCase';
import { AppContext } from 'context';
import Button from 'components/Form/Button';
import App from 'modules/App';
import intlShape from 'shapes/intlShape';
import Help from 'svg/help.svg';
import Chevron from 'svg/chevron-right.svg';
import Dots from 'svg/dots.svg';
import MenuExtend from 'svg/menu-extend.svg';
import MenuCollapse from 'svg/menu-collapse.svg';
import messages from '../../../messages';
import * as constants from '../../../constants';


class Sidebar extends React.Component {

  static contextType = AppContext;

  static propTypes = {
    // Explicit props
    direction          : PropTypes.string,
    menuOpen           : PropTypes.bool,
    selectedMenuItem   : PropTypes.string,
    activeVisit        : PropTypes.object,
    isPinnedMenu       : PropTypes.bool,
    // Explicit actions
    setMenuOpen        : PropTypes.func,
    setSelectedMenuItem: PropTypes.func,
    // Implicit props
    intl               : intlShape,
  }

  constructor(props) {
    super(props);
    this.state = {
      hovered: '',
    };

    this.styles = {};
  }


  onHoverExit() {
    this.setState({ hovered: '' });
  }


  onHover(name) {
    this.setState({ hovered: name });
  }


  onToggleMenu() {
    this.onSetMenuOpen(!this.props.menuOpen);
  }


  onCloseSelectedMenu() {
    this.onSelectMenuItem(null);
  }


  onHideAllMenu() {
    if (!this.props.isPinnedMenu) {
      this.onSetMenuOpen(false);
    }
    this.onSelectMenuItem(null);
  }


  onClickDisabled(evt) {
    evt.preventDefault();
    this.onHideAllMenu();
  }


  onSelectMenuItem(item) {
    this.props.setSelectedMenuItem(this.props.selectedMenuItem !== item ? item : null);
  }

  onSetMenuOpen(isMenuOpen) {
    this.props.setMenuOpen(isMenuOpen);
  }


  get isOpenMenu() {
    return this.props.menuOpen && !this.props.activeVisit;
  }


  get isOpenSelectedMenu() {
    return !isEmpty(this.props.selectedMenuItem) && !this.props.activeVisit;
  }


  get isShrinkedMenu() {
    return !this.isOpenMenu && !this.isOpenSelectedMenu;
  }


  // STARTS METHODS TO OVERRIDE IN CHILDREN
  get menuItems() {
    return [];
  }

  get menuItemsSelected() {
    return {};
  }
  // END METHODS TO OVERRIDE IN CHILDREN


  isSelectedMenuItem(item) {
    return this.props.selectedMenuItem === item;
  }


  renderSelectedMenuHeader(name) {
    const title = (
      <span className="sidebar__elementContentExtend--title">
        <FormattedMessage {...messages.sidebar.buttons[name]} />
      </span>
    );
    return (
      <>
        {this.renderMenuButtonItem(`${name}-header`, 1, title, () => this.onCloseSelectedMenu())}
      </>
    );
  }


  renderMenuButtonItem(name, index, menuItem, onClick = () => {}, selected, to, noHover) {
    const { activeVisit, intl } = this.props;
    if (activeVisit) {
      onClick = (evt) => this.onClickDisabled(evt);
    }
    const tooltipText = activeVisit ? intl.formatMessage(App.messages.infos.menuDisabledIfActiveVisit) : name;
    const isHovered = name === this.state.hovered && !activeVisit && !noHover;
    return (
      <Button
        styleModifier="transparent"
        className="sidebar__element"
        onClick={onClick}
        type={to ? 'link' : 'button'}
        to={to}
        key={`menu-item-${camelCase(name)}-${index}`}
      >
        <div
          className={cn('sidebar__elementContent', {
            'sidebar__elementContent--selected'          : selected && !to,
            sidebar__elementContentExtend                : index === 1,
            sidebar__elementShrinked                     : index === 0,
            'sidebar__elementContentExtend--selected'    : selected && index === 1 && !to,
            'sidebar__elementContent--linkSelected'      : selected && to && index === 0 && !isHovered,
            'sidebar__elementContentExtend--linkSelected': selected && to && index === 1 && !isHovered,
            'sidebar__elementContent--hovered'           : isHovered,
            'sidebar__elementContent--disabled'          : activeVisit,
          })}
          onMouseEnter={() => this.onHover(name)}
          onMouseLeave={() => this.onHoverExit()}
          data-for="sidebar"
          data-tip={tooltipText}
        >
          {menuItem}
        </div>
      </Button>
    );
  }


  renderMenuButtonItems(name, menuItems, onClick = () => this.onHideAllMenu(), selected, to) {
    return menuItems.map((item, index) => this.renderMenuButtonItem(name, index, item, onClick, selected, to));
  }


  renderHr(key) {
    return [<hr key={`hr-${key}-1`} />, <hr key={`hr-${key}-2`} />];
  }


  renderAnimatedChevron(selected) {
    const { direction } = this.props;
    return (
      <motion.div
        initial={{ '--rotate': direction === 'ltr' ? '0deg' : '180deg' }}
        animate={selected ? constants.VARIANTS_TYPE.OPEN : constants.VARIANTS_TYPE.CLOSED}
        variants={constants.ANIMATION_VARIANTS_CHEVRON[direction]}
        className="d-flex justify-content-center align-items-center"
      >
        <Chevron />
      </motion.div>
    );
  }


  renderOptions(onClick = () => {}, Menu) {
    return (
      <div className="sidebar__dots">
        <div
          onClick={(evt) => {
            evt.preventDefault();
            evt.stopPropagation();
            onClick();
          }}
          onKeyDown={() => {}}
          className="d-flex justify-content-center align-items-center"
        >
          <Dots />
        </div>
        <Menu />
      </div>
    );
  }


  renderMoreCell(count, key) {
    const text = count > 99 ? '99+' : `+${count}`;
    return (
      <div className="sidebar__moreCell" key={key}>
        <div className="sidebar__moreCell--firstBorder" />
        <div className="sidebar__moreCell--secondBorder" />
        <div className="sidebar__moreCell--content">
          {text}
        </div>
      </div>
    );
  }


  renderMenuHeader() {
    const menuIcon = this.isOpenMenu ? <MenuCollapse /> : <MenuExtend />;
    const menuText = (
      <span className="sidebar__elementContentExtend--title">
        <FormattedMessage {...messages.sidebar.buttons.menu} />
      </span>
    );

    return this.renderMenuButtonItems(
      this.props.intl.formatMessage(messages.sidebar.buttons.menu),
      [menuIcon, menuText],
      () => this.onToggleMenu(),
      false
    );
  }


  renderItemSelected() {
    return this.menuItemsSelected[this.props.selectedMenuItem];
  }


  renderMenu() {
    const { direction, activeVisit } = this.props;
    return (
      <motion.div
        className={cn('sidebar', { 'sidebar--disabled': activeVisit })}
        animate={this.isShrinkedMenu ? constants.VARIANTS_TYPE.CLOSED : constants.VARIANTS_TYPE.OPEN}
        variants={constants.ANIMATION_VARIANTS[direction].SHRINKED}
        transition={{ bounce: 0 }}
      >
        <div className={cn('sidebar__menuContainer')}>
          {this.renderMenuHeader()[0]}
          {this.menuItems.map((menuItem) => menuItem && menuItem[0])}
        </div>
        <App.components.LanguageSelector
          hideLabel
          className="sidebar__languageSelector"
          withTooltip
          tooltipOffset={direction === 'ltr' ? "{ 'left': -16 }" : "{ 'right': -14 }"}
          tooltipPlace={direction === 'ltr' ? 'right' : 'left'}
          activeVisit={activeVisit}
        />
        <a
          href="https://support.glucocontro.online/hc"
          target="_blank"
          onClick={activeVisit ? (evt) => this.onClickDisabled(evt) : undefined}
        >
          <Help
            className="sidebar__help"
            data-for="sidebar"
            data-offset={direction === 'ltr' ? "{ 'left': -16 }" : "{ 'right': -14 }"}
            data-tip={this.props.intl.formatMessage(
              activeVisit
                ? App.messages.infos.menuDisabledIfActiveVisit
                : messages.sidebar.buttons.helpCenter
            )}
          />
        </a>
      </motion.div>
    );
  }


  renderMenuExpanded() {
    const { direction } = this.props;
    const initialPosition = direction === 'ltr' ? '-400px' : '400px';
    return (
      <AnimatePresence initial={false}>
        {
          this.isOpenMenu
          && (
            <motion.div
              initial={{ x: initialPosition }}
              animate={
                this.isOpenSelectedMenu
                  ? constants.VARIANTS_TYPE.OPEN
                  : constants.VARIANTS_TYPE.CLOSED
              }
              variants={constants.ANIMATION_VARIANTS[direction].EXPANDED}
              exit={{ x: initialPosition }}
              className={cn('sidebar__expanded')}
              transition={{ bounce: 0 }}
            >
              <div className={cn('sidebar__menuContainer')}>
                {this.renderMenuHeader()[1]}
                {this.menuItems.map((menuItem) => menuItem && menuItem[1])}
              </div>
            </motion.div>
          )
        }
      </AnimatePresence>
    );
  }


  renderMenuSelected() {
    const { direction } = this.props;
    return (
      <AnimatePresence initial={false}>
        {
          this.isOpenSelectedMenu
            && (
              <motion.div
                initial={{ x: direction === 'ltr' ? '-566px' : '566px' }}
                animate={this.isOpenMenu ? constants.VARIANTS_TYPE.OPEN : constants.VARIANTS_TYPE.CLOSED}
                variants={constants.ANIMATION_VARIANTS[direction].SELECTED}
                exit={{ x: direction === 'ltr' ? '-566px' : '566px' }}
                className={cn('sidebar__selected')}
                transition={{ bounce: 0 }}
              >
                <div className={cn('sidebar__menuContainer')}>
                  {this.renderItemSelected()}
                </div>
              </motion.div>
            )
      }
      </AnimatePresence>
    );
  }


  renderTooltip() {
    const { direction } = this.props;
    if (!process.env.BROWSER || this.isOpenMenu) {
      return null;
    }
    return (
      <ReactTooltip
        id="sidebar"
        place={direction === 'ltr' ? 'right' : 'left'}
        type="dark"
        effect="solid"
        offset={direction === 'ltr' ? { left: -10 } : { right: -8 }}
      />
    );
  }


  renderContent() {
    return (
      <div id="menu-sidebar">
        { this.renderMenu() }
        { this.renderMenuExpanded() }
        { this.renderMenuSelected() }
        { this.renderTooltip() }
      </div>
    );
  }

}


export default Sidebar;
