import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import cn from 'classnames';
import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';
import last from 'lodash/last';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import intlShape from 'shapes/intlShape';
import * as actions from '../../actions';
import * as shapes from '../../shapes';
import messages from '../../messages';
import styles from './DownloadDataModal.pcss';


class ChooseConnection extends React.PureComponent {

  static propTypes = {
    // Explicit props
    downloader      : shapes.downloader,
    devicesByChannel: shapes.devicesByChannel,
    isOpen          : PropTypes.bool,
    intl            : intlShape,
    // Explicit actions
    onSetComponent  : PropTypes.func,
    onSetChannel    : PropTypes.func,
    // Implicit actions
    onCheckStatus   : PropTypes.func,
  };


  componentDidMount() {
    if (!this.props.isOpen) {
      return;
    }
    this.props.onCheckStatus();
  }


  renderDevices(devices) {
    const devicesNames = uniq(map(devices, (device) => device.name)).sort();
    return (
      <ul className={styles.connection__models}>
        {
          map(devicesNames, (deviceName) => (
            <li key={deviceName} className={styles.connection__model}><span>{deviceName}</span></li>
          ))
        }
      </ul>
    );
  }


  renderConnection(name) {
    const isDriverInstalled = get(this.props.downloader, 'driver.isDriverInstalled', false);
    const driverInstallationState = get(this.props.downloader, 'driver.installationState');

    let isActive = true;
    let inactiveInfo = '';
    let blueCable = 'ConnectBlueCable';

    if (!isDriverInstalled) {
      blueCable = driverInstallationState === 'Completed' ? 'InstallingBlueCableDrivers' : 'InstallBlueCableDrivers';
    }

    const devices = get(this.props.devicesByChannel, name, []);

    if (!devices.length) {
      isActive = false;
      inactiveInfo = this.props.intl.formatMessage(messages.chooseConnection.noSupportedDevices);
    } else if (name === 'Bluetooth') {
      isActive = this.props.downloader.bleAvailable;
      if (!isActive) {
        const bleAvailabilityReason = last(this.props.downloader.bleAvailabilityReason.split(',')).trim();
        const inactiveMessage = get(messages, ['btErrors', bleAvailabilityReason]);
        if (inactiveMessage) {
          inactiveInfo = this.props.intl.formatMessage(inactiveMessage);
        }
      }
    }

    const components = {
      BlueCable : blueCable,
      Bluetooth : 'ConnectBluetooth',
      BuiltInUsb: 'ConnectUsb',
      MicroUsb  : 'ConnectUsbCable',
      UsbC      : 'ConnectUsbCCable',
    };

    let imageFileName = kebabCase(name);
    if (!isActive) {
      imageFileName += '-inactive';
    }

    return (
      <div>
        <button
          type="button"
          id={`connect${components[name]}`}
          className={cn(styles.connection__btn, { [styles['connection__btn--inactive']]: !isActive })}
          disabled={!isActive}
          onClick={() => {
            this.props.onSetChannel(name);
            this.props.onSetComponent(components[name]);
          }}
        >
          <span className={styles.connection__imageContainer}>
            <img src={`/assets/svg/${imageFileName}.svg`} alt="" />
          </span>
        </button>
        <h3 className={cn('modal__subheader', { 'text--muted': !isActive })}>
          <FormattedMessage {...messages.connections[name]} /><br />
          { !isActive && <FormattedMessage {...messages.infos.notAvailable} /> }
        </h3>
        {
          isActive
            ? this.renderDevices(devices)
            : <div className={styles.connection__inactiveInfo}>{inactiveInfo}</div>
        }
      </div>
    );
  }


  render() {
    return (
      <div>
        <h3 className="modal__subheader "><FormattedMessage {...messages.chooseConnection.header} /></h3>
        <div className="row">
          <div className="col">{ this.renderConnection('BlueCable') }</div>
          <div className="col">{ this.renderConnection('MicroUsb') }</div>
          <div className="col">{ this.renderConnection('UsbC') }</div>
          <div className="col">{ this.renderConnection('BuiltInUsb') }</div>
          <div className="col">{ this.renderConnection('Bluetooth') }</div>
        </div>
      </div>
    );
  }

}


const mapDispatchToProps = (dispatch) => ({
  onCheckStatus: () => Promise.all([
    dispatch(actions.checkStatus()),
    dispatch(actions.checkBlueCableDriver()),
  ]),
});


const ConnectedChooseConnection = connect(
  null,
  mapDispatchToProps,
)(injectIntl(ChooseConnection));


export default ConnectedChooseConnection;
