import filter from 'lodash/filter';
import find from 'lodash/find';
import findLast from 'lodash/findLast';
import findKey from 'lodash/findKey';
import forEach from 'lodash/forEach';
import forIn from 'lodash/forIn';
import get from 'lodash/get';
import hasIn from 'lodash/hasIn';
import pick from 'lodash/pick';
import toLower from 'lodash/toLower';
import upperFirst from 'lodash/upperFirst';
import unset from 'lodash/unset';
import { call, fork, put, takeLatest, getContext, select, delay, take } from 'redux-saga/effects';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import MetricConversions from 'libs/MetricConversions';
import LocalStorage from 'libs/LocalStorage';
import Validator from 'libs/Validator';
import {
  getKeyFromPem,
  decrypt,
  hybridEncrypt,
} from 'helpers/crypto';
import appInsights from 'helpers/appInsights';
import history from 'helpers/history';
import { getSlug } from 'helpers/urlTools';
import request from 'helpers/request';
import { LocalError } from 'helpers/errorTypes';
import { getStandards } from 'helpers/settings';
import AlertService from 'services/AlertService';
import CloudDriveService from 'services/CloudDriveService';
import CountryLocalizationService from 'services/CountryLocalizationService';
import { openTransaction, prolongTransaction, closeTransaction, commitTransaction } from 'services/MdtcService';
import JobTrackerService from 'services/JobTrackerService';
import StorageExchangeTokenService from 'services/StorageExchangeTokenService';
import ApiService from 'services/ApiService';
import App from 'modules/App';
import Account from 'modules/Account';
import CloudDrive from 'modules/CloudDrive';
import Statistics from 'modules/Statistics';
import Hcp from 'modules/Hcp';
import Information from 'modules/Information';
import Visit from 'modules/Visit';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import * as selectors from './selectors';
import messages from './messages';


function* manageOrganization({ payload }) {
  try {
    const { organizationUID } = payload;
    yield put(App.actions.goToAid(`organization-membership/${organizationUID}`));
  } catch (err) {
    // Background action
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* fetchClinicCustomIdentifiers({ payload }) {
  try {
    const { countryId } = payload;
    const clinicCustomIdentifiersCountryId = yield select(selectors.clinicCustomIdentifiersCountryId);
    if (clinicCustomIdentifiersCountryId === countryId) {
      yield put(actions.fetchClinicCustomIdentifiersSuccess(countryId));
      return;
    }
    const requestUrl = `/api/Localization/country/${countryId}/identifiers/Clinic`;
    const clinicCustomIdentifiers = yield call(ApiService.originalRequest, requestUrl);
    yield put(actions.fetchClinicCustomIdentifiersSuccess(countryId, clinicCustomIdentifiers));
  } catch (err) {
    yield put(actions.fetchClinicCustomIdentifiersError(err));
    yield call(App.dispatchError, err, messages);
  }
}


function* storeClinic({ payload }) {
  try {
    const { clinicValues } = payload;
    LocalStorage.setItem('newClinic', JSON.stringify({ ...clinicValues }));
    yield delay(50);
    yield put(actions.storeClinicSuccess(clinicValues));
  } catch (err) {
    yield put(actions.storeClinicError(err));
    yield call(App.dispatchError, err, messages);
  }
}


function* getStoredClinic() {
  try {
    const storedClinic = LocalStorage.getItem('newClinic');
    const clinicValues = storedClinic ? JSON.parse(storedClinic) : {};
    yield put(actions.storeClinicSuccess(clinicValues));
  } catch (err) {
    // We don't need to do anything
  }
}


function* getOrganizationMembership(organizationUID) {
  const organizationMemberships = yield select(Information.selectors.organizationMemberships);
  const organizationMembership = find(
    organizationMemberships,
    (om) => om.organization.organizationUID === organizationUID
  );
  if (!organizationMembership) {
    throw new LocalError({ code: 'NoOrganizationMembership' });
  }
  return organizationMembership;
}


function* sendClinicExchangeToken(clinicId, encryptedExchangeToken) {
  yield call(ApiService.regionalRequest, `/api/Clinic/${clinicId}/ExchangeToken`, {
    method: 'POST',
    body  : {
      clinicId,
      encryptedExchangeToken,
    },
  });
}


function* setClinicCloudDrive({ payload }) {
  try {
    const { clinicValues } = payload;
    const { authorizationCode, clinicId, organizationUID } = clinicValues;
    const organizationMembership = yield call(getOrganizationMembership, organizationUID);
    const { organization } = organizationMembership;
    const organizationPubKeyObj = getKeyFromPem(organization.publicKey);

    const { exchangeToken, storageProvider, storageAccount } = yield call(
      StorageExchangeTokenService.fetchExchangeToken,
      authorizationCode, 'ClinicMembership', clinicId,
    );
    const encryptedExchangeToken = yield call(hybridEncrypt, exchangeToken, organizationPubKeyObj);

    yield call(ApiService.regionalRequest, `/api/Clinic/${clinicId}/setCloudDrive`, {
      method: 'POST',
      body  : {
        storageProvider,
        storageAccount,
      },
    });
    yield call(sendClinicExchangeToken, clinicId, encryptedExchangeToken);
    yield put(Account.actions.fetchMemberships());
    yield take([Account.actionTypes.FETCH_MEMBERSHIPS_SUCCESS, Account.actionTypes.FETCH_MEMBERSHIPS_ERROR]);
    yield put(actions.setClinicCloudDriveSuccess());
  } catch (err) {
    yield put(actions.setClinicCloudDriveError(err));
    yield call(App.dispatchError, err, messages);
  } finally {
    yield put(CloudDrive.actions.clearAuthorizationCode());
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* updateClinicGlucoseSettings({ payload }) {
  try {
    const { clinicId, settings } = payload;
    yield call(ApiService.regionalRequest, `/api/Clinic/${clinicId}/settings`, {
      method: 'PUT',
      body  : { ...settings },
    });
    yield put(actions.updateClinicGlucoseSettingsSuccess());
  } catch (err) {
    yield put(actions.updateClinicGlucoseSettingsError(err));
  }
}


function* updateClinicKpiSettings({ payload }) {
  try {
    const { clinicId, settings } = payload;
    yield call(ApiService.regionalRequest, `/api/Clinic/${clinicId}/settings`, {
      method: 'PUT',
      body  : { ...settings },
    });
    yield put(actions.updateClinicKpiSettingsSuccess());
  } catch (err) {
    yield put(actions.updateClinicKpiSettingsError(err));
  }
}


function* getClinicMemberships(clinicId) {
  const requestURL = `/api/ClinicMembership/Clinic/${clinicId}`;
  return yield call(ApiService.regionalRequest, requestURL);
}


function* reauthorizeClinicCloudDrive({ payload }) {
  try {
    const { authorizationCode, clinicMembership } = payload;
    const { clinic } = clinicMembership;
    const { clinicId } = clinic;

    const { exchangeToken } = yield call(StorageExchangeTokenService.fetchExchangeToken,
      authorizationCode,
      'ClinicMembership',
      clinicId,
    );

    const organizationPubKeyObj = getKeyFromPem(clinic.publicKey);
    const encryptedExchangeToken = yield call(hybridEncrypt, exchangeToken, organizationPubKeyObj);
    yield call(sendClinicExchangeToken, clinicId, encryptedExchangeToken);
    yield put(Account.actions.fetchMemberships());
    yield take([Account.actionTypes.FETCH_MEMBERSHIPS_SUCCESS, Account.actionTypes.FETCH_MEMBERSHIPS_ERROR]);
    yield put(actions.reauthorizeClinicCloudDriveSuccess());
    yield put(App.actions.setAlert({
      type   : 'success',
      message: messages.alerts.clinicCloudDriveReauthorized,
    }));
  } catch (err) {
    yield put(actions.reauthorizeClinicCloudDriveError(err));
    yield call(App.dispatchError, err, messages);
  } finally {
    yield put(CloudDrive.actions.clearAuthorizationCode());
  }
}


function* fetchOrganizationMemberships({ payload }) {
  try {
    const { organizationUID, statuses } = payload;
    let query = '';
    const statusesQuery = statuses.map((status) => `statuses=${status}`).join('&');
    if (statusesQuery) query += `?${statusesQuery}`;
    const requestURL = `/api/Organization/${organizationUID}/memberships${query}`;
    const organizationMemberships = yield call(ApiService.regionalRequest, requestURL);
    yield put(actions.fetchOrganizationMembershipsSuccess(organizationMemberships));
  } catch (err) {
    yield put(actions.fetchOrganizationMembershipsError(err));
    yield call(App.dispatchError, err, messages);
  }
}

function* checkPatientsWithoutLeadingHcp() {
  try {
    const activeClinicMembership = yield select(Account.selectors.activeClinicMembership);
    if (!activeClinicMembership || !activeClinicMembership.isAdmin) {
      return;
    }
    const clinicMemberships = yield select(selectors.clinicMemberships);
    const clinicMembershipsProfilesId = clinicMemberships.reduce((acc, cm) => acc.add(cm.hcpProfileId), new Set());
    const patients = yield select(Hcp.selectors.patients);
    const patientsHcpProfilesId = patients.reduce((acc, patient) => acc.add(patient.hcpProfileId), new Set());
    const missingProfilesId = new Set();
    patientsHcpProfilesId.forEach((profileId) => {
      if (!clinicMembershipsProfilesId.has(profileId)) {
        missingProfilesId.add(profileId);
      }
    });
    if (missingProfilesId.size) {
      const getUrl = yield getContext('getUrl');
      const { organizationUID, name } = get(activeClinicMembership, 'clinic', {});
      const clinicSlug = getSlug(name);
      const patientsListUrl = getUrl('patients-list', { clinicSlug, organizationUID });
      const alert = yield call(AlertService.createAlertDispatcher, { isGlobal: true });
      yield alert.warning(messages.alerts.patientsWithoutLeadingHcp, null,
        [{ action: () => history.push(patientsListUrl), message: messages.buttons.managePatients }],
        { isPinned: true },
      );
    }

  } catch (err) {
    // Background action
    if (__DEV__) {
      console.error(err);
    } else {
      appInsights.trackException(err);
    }
  }
}


function* checkPendingOrganizationMemberships() {
  try {
    const activeClinicMembership = yield select(Account.selectors.activeClinicMembership);
    if (activeClinicMembership && activeClinicMembership.isAdmin) {
      const { organizationUID } = activeClinicMembership.clinic;
      const requestURL = `/api/Organization/${organizationUID}/memberships?statuses=Pending`;
      const organizationMemberships = yield call(ApiService.regionalRequest, requestURL);
      const pendingMemberships = filter(organizationMemberships, { status: 'Pending' });
      if (pendingMemberships.length) {
        const alert = yield call(AlertService.createAlertDispatcher, { isGlobal: true });
        yield alert.warning(messages.alerts.hcpApprovalWaiting, null,
          [{ action: actions.manageOrganization(organizationUID), message: messages.buttons.manageOrganization }],
          { isPinned: true },
        );
      }
    }
  } catch (err) {
    // Background action
    if (__DEV__) {
      console.error(err);
    } else {
      appInsights.trackException(err);
    }
  }
}


function* fetchClinicMemberships({ payload }) {
  const alert = yield call(AlertService.createAlertDispatcher, { isGlobal: true });
  try {
    const { clinicId } = payload;
    const activeClinicMembership = yield select(Account.selectors.activeClinicMembership);
    const clinicStatus = get(activeClinicMembership, 'clinic.clinicStatus');
    const membershipStatus = get(activeClinicMembership, 'membershipStatus');
    if (
      clinicStatus === 'Pending'
      || clinicStatus === 'Cleared'
      || clinicStatus === 'WaitingForLicence'
      || membershipStatus === 'Pending') {
      yield put(actions.fetchClinicMembershipsSuccess([]));
      return;
    }
    const clinicMemberships = yield call(getClinicMemberships, clinicId);
    if (activeClinicMembership && activeClinicMembership.clinicId === clinicId && activeClinicMembership.isAdmin) {
      const pendingMemberships = filter(clinicMemberships, { membershipStatus: 'Pending' });
      if (pendingMemberships.length) {
        const getUrl = yield getContext('getUrl');
        const { organizationUID, name } = get(activeClinicMembership, 'clinic', {});
        const clinicSlug = getSlug(name);
        const clinicSettingsUrl = getUrl('clinic-settings', { clinicSlug, organizationUID });
        yield alert.warning(messages.alerts.hcpApprovalWaiting, { clinicId },
          [{ action: () => history.push(clinicSettingsUrl), message: messages.buttons.openClinicSettings }],
          { isPinned: true },
        );
      }
    }
    yield put(actions.fetchClinicMembershipsSuccess(clinicMemberships));
  } catch (err) {
    yield put(actions.fetchClinicMembershipsError(err));
    yield call(App.dispatchError, err, messages, alert);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* removePatient(patient, clinicMembership) {
  yield put(Hcp.actions.removePatient(patient, clinicMembership));
  yield take([
    Hcp.actionTypes.REMOVE_PATIENT_SUCCESS,
    Hcp.actionTypes.REMOVE_PATIENT_ERROR,
  ]);
  yield put(actions.removeClinicPatientDataSuccess());
}


function* removePatientWorker(patient, clinicMembership) {
  yield fork(removePatient, patient, clinicMembership);
  return yield take([
    actionTypes.REMOVE_CLINIC_PATIENT_DATA_SUCCESS,
    actionTypes.REMOVE_CLINIC_DATA_PAUSE,
  ]);
}


function* removePatientsList(clinicMembership) {
  const profilesReferenceKey = get(clinicMembership, 'clinic.profilesReferenceKey');
  const storageProvider = get(clinicMembership, 'clinic.storageProvider');
  const accessToken = get(clinicMembership, 'accessToken');

  const transaction = yield call(openTransaction, [{
    lockType    : 'Exclusive',
    referenceKey: profilesReferenceKey,
  }]);
  const { transactionId, revisions } = transaction;
  const revisionDocumentId = get(revisions, [0, 'documentId']);

  const updatedRevisions = [{
    previousDocumentId: revisionDocumentId,
    newDocumentId     : null,
    referenceKey      : profilesReferenceKey,
  }];

  const forksHistory = yield call(commitTransaction, transactionId, updatedRevisions);
  yield call(CloudDriveService.clearForks, forksHistory, storageProvider, accessToken);
}


function* removePatients(clinicMembership) {
  yield put(Hcp.actions.fetchPatients(clinicMembership));
  const fetchPatientsResult = yield take([
    Hcp.actionTypes.FETCH_PATIENTS_SUCCESS,
    Hcp.actionTypes.FETCH_PATIENTS_ERROR,
  ]);
  if (fetchPatientsResult.type === Hcp.actionTypes.FETCH_PATIENTS_ERROR) {
    throw fetchPatientsResult.error;
  }

  const { patients } = fetchPatientsResult.payload;
  const patientsCount = patients.length;
  yield put(actions.setPatientsCount(patientsCount));
  if (!patientsCount) {
    return 'done';
  }

  for (let i = 0; i < patientsCount; i++) {
    const removePatientResult = yield call(removePatientWorker, patients[i], clinicMembership);
    yield put(actions.setPatientsCount(patientsCount - (i + 1)));
    if (removePatientResult.type === actionTypes.REMOVE_CLINIC_DATA_PAUSE) {
      yield put(actions.setPatientsCount(null));
      return 'break';
    }
  }
  return 'done';
}


function* clearClinicData(clinicMembership) {
  const { clinicId } = clinicMembership;
  const requestUrl = `/api/Clinic/${clinicId}/data`;
  yield call(ApiService.regionalRequest, requestUrl, {
    method: 'DELETE',
  });
}


function* removeClinicData({ payload }) {
  try {
    const { clinicMembership } = payload;
    const clinicStatus = get(clinicMembership, 'clinic.clinicStatus');
    if (clinicStatus !== 'Deleted') {
      const err = new LocalError({ code: 'CantRemoveDataFromNotDeletedClinic' });
      yield put(actions.removeClinicDataError(err));
      yield call(App.dispatchError, err, messages);
      return;
    }
    const removePatientsResult = yield call(removePatients, clinicMembership);
    if (removePatientsResult === 'done') {
      yield call(removePatientsList, clinicMembership);
      yield call(clearClinicData, clinicMembership);
      yield put(Account.actions.setActiveClinicMembershipCleared());
    }
    yield put(actions.removeClinicDataSuccess());
  } catch (err) {
    yield put(actions.removeClinicDataError(err));
    yield call(App.dispatchError, err, messages);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* importPatientReadings(api, patientId, phiSet, phiSetDocumentId, phiSetReferenceKey, storageProvider, accessToken) {
  const fetch = yield getContext('fetch');
  const requestUrl = `http://localhost:12345/${api}/patients/${patientId}/readings`;
  const deviceImportData = yield call(request, fetch, requestUrl);
  // @TODO: exchange serialNumber to deviceSerialNumberToken
  deviceImportData.deviceSerialNumberToken = deviceImportData.serialNumber;
  unset(deviceImportData, 'serialNumber');
  forEach(deviceImportData.readings, (reading) => {
    reading.deviceSerialNumberToken = reading.serialNumber;
    reading.phisetVisitId = reading.visitId;
    unset(reading, 'visitId');
    unset(reading, 'serialNumber');
  });
  yield put(CloudDrive.actions.storeReadings(
    deviceImportData,
    phiSet,
    phiSetDocumentId,
    { phiSetReferenceKey, storageProvider, accessToken },
  ));

  const storeReadingsResult = yield take([
    CloudDrive.actionTypes.STORE_READINGS_SUCCESS,
    CloudDrive.actionTypes.STORE_READINGS_ERROR,
  ]);

  if (storeReadingsResult.type === CloudDrive.actionTypes.STORE_READINGS_ERROR) {
    throw new LocalError({ code: 'StoreReadingsError' });
  }

  const { payload } = storeReadingsResult;
  phiSet = payload.updatedPhiSet;
  phiSetDocumentId = payload.phiSetDocumentId;

  return { phiSet, phiSetDocumentId, deviceImportData };
}


function getRandTimestamp(baseTimestamp) {
  const maxTime = baseTimestamp + 15 * 60;
  const minTime = baseTimestamp + 5 * 60;
  return Math.floor(Math.random() * (maxTime - minTime + 1)) + minTime;
}


function getVisitNotes(visit, timestamp) {
  const noteTypesMapping = {
    instructions       : 'Instruction',
    physicalExamination: 'PhysicalExamination',
    recommendations    : 'Recommendation',
    description        : 'Comment',
  };
  const notes = [];
  forIn(visit.additionalInformations, (content, key) => {
    const noteType = noteTypesMapping[key];
    if (noteType && content) {
      notes.push({
        phisetVisitId: visit.id,
        noteType,
        content,
        timestamp    : getRandTimestamp(timestamp),
      });
    }
  });
  return notes;
}

// @TODO: Fix names in MDA
function getMeasurementType(originalName) {
  if (originalName === 'HbA1c') return 'HbA1C';
  if (originalName === 'Body weight') return 'Weight';
  return originalName.split(' ').map((word) => upperFirst(word)).join('');
}


function getVisitMeasurements(visit) {
  const measurements = {};
  forEach(visit.measurements, (measurement) => {
    const { name: originalName, value: originalValue, unit: unitSymbol } = measurement;
    const type = getMeasurementType(originalName);
    const name = findKey(App.constants.MEASUREMENTS, { type });
    const lowerUnitSymbol = toLower(unitSymbol).replace('m2', 'm\u00B2').replace('umol', '\u00b5mol');
    if (name && +originalValue) {
      const conf = App.constants.MEASUREMENTS[name];
      const unit = findKey(App.constants.UNITS_SYMBOLS, (symbol) => toLower(symbol) === lowerUnitSymbol);
      const conversions = new MetricConversions({ [conf.metric]: unit });
      measurements[name] = conversions[conf.metric].toStorage(+originalValue);
    }
  });
  return measurements;
}


function prepareVisit(visit) {
  const createdTimestamp = +moment.utc(visit.createdDate).locale('en').format('X');
  const timestamp = getRandTimestamp(createdTimestamp);

  return {
    phisetVisitId: visit.id,
    notes        : getVisitNotes(visit, timestamp),
    measurements : getVisitMeasurements(visit),
    timestamp,
    isInactive   : true,
  };
}


function* importPatientVisits(api, patientId, phiSetReferenceKey, storageProvider, accessToken, clinicMembership) {
  const fetch = yield getContext('fetch');
  let visits = [];
  try {
    const requestUrl = `http://localhost:12345/${api}/patients/${patientId}/visits`;
    const visitsResult = yield call(request, fetch, requestUrl);
    visits = get(visitsResult, 'visits', []);
  } catch (err) {
    if (err.status === 404) {
      return visits;
    }
    throw err;
  }

  const patientData = { phiSetReferenceKey, storageProvider, accessToken, id: patientId };
  let phiSet = null;
  let phiSetDocumentId = null;

  for (let i = 0; i < visits.length; i++) {
    const visitValues = yield call(prepareVisit, visits[i]);
    yield put(Visit.actions.addVisit(phiSet, phiSetDocumentId, patientData, clinicMembership, null, visitValues));
    const addVisitResult = yield take([
      Visit.actionTypes.ADD_VISIT_SUCCESS,
      Visit.actionTypes.ADD_VISIT_ERROR,
    ]);
    if (addVisitResult.type === Visit.actionTypes.ADD_VISIT_ERROR) {
      throw new LocalError({ code: 'AddVisitError' });
    }
    const { payload } = addVisitResult;
    phiSet = payload.updatedPhiSet;
    phiSetDocumentId = payload.phiSetDocumentId;
  }

  return { phiSet, phiSetDocumentId };
}


function* importPatientPhiData(database, patient, clinicMembership, clinicMembershipPrvKeyObj, standards) {
  const { encryptedPhiSetReferenceKey } = patient;
  const phiSetReferenceKey = decrypt(encryptedPhiSetReferenceKey, clinicMembershipPrvKeyObj);
  const { accessToken, clinic } = clinicMembership;
  const { storageProvider } = clinic;
  const { api } = App.constants.PATIENT_DATABASES[database];
  const patientId = patient.sourcePatientId || patient.id;

  const { phiSet, phiSetDocumentId } = yield call(
    importPatientVisits,
    api, patientId, phiSetReferenceKey, storageProvider, accessToken, clinicMembership,
  );

  const { phiSet: updatedPhiSet, deviceImportData } = yield call(
    importPatientReadings,
    api, patientId, phiSet, phiSetDocumentId, phiSetReferenceKey, storageProvider, accessToken,
  );

  yield put(Statistics.actions.sendStatisticsForClinic(
    patient,
    updatedPhiSet,
    deviceImportData,
    standards,
    clinicMembership,
  ));
}


function* importPatient(
  database, patient, clinicMembership, clinicMembershipPrvKeyObj,
  countriesCustomIdentifiers, standards,
  jobId, controlId, indicators, counters,
) {
  const patientValues = {
    ...pick(patient, ['id', 'createTimestamp']),
    ...pick(patient.profile, ['firstName', 'lastName', 'countryId', 'gender', 'dateOfBirth']),
    ...pick(patient.phiSet, ['diabetesType', 'height']),
    source: database,
  };
  if (!patientValues.countryId) {
    patientValues.countryId = clinicMembership.clinic.countryId;
  }
  const patientInfo = pick(patientValues, ['id', 'firstName', 'lastName']);
  if (!Validator.uuid(patientValues.id)) {
    patientValues.id = uuidv4();
    patientValues.sourcePatientId = patient.id;
  }
  const middleName = get(patient, 'profile.middleName');
  if (middleName) {
    patientValues.firstName = `${patientValues.firstName} ${middleName}`;
  }

  // Custom identifiers
  const personalIdentifierValue = get(patient, 'profile.pesel');
  if (personalIdentifierValue) {
    if (!hasIn(countriesCustomIdentifiers, patientValues.countryId)) {
      const countries = yield select(App.selectors.countries);
      const country = find(countries, { countryId: patientValues.countryId });
      const countryCode = get(country, 'alpha2Code');
      if (!countryCode) {
        counters.errorCount++;
        counters.errorsList.push(patientInfo);
        yield call(JobTrackerService.trackJob, jobId, controlId, { ...indicators, ...counters });
        yield put(actions.importPatientDatabasePatientStored(counters));
        return;
      }
      const informationTemplate = yield call(
        CountryLocalizationService.fetchInformationTemplate, countryCode, Account.constants.SCOPE_NAMES.PERSONAL,
      );
      countriesCustomIdentifiers[patientValues.countryId] = find(informationTemplate, { name: 'personalIdentifier' });
    }
    const personalIdentifierTemplate = countriesCustomIdentifiers[patientValues.countryId];
    const personalIdentifierTypeTemplate = personalIdentifierTemplate
      && personalIdentifierTemplate.fields
      && find(personalIdentifierTemplate.fields, { name: 'personalIdentifierType' });
    if (personalIdentifierTypeTemplate && personalIdentifierTypeTemplate.options) {
      for (let i = 0; i < personalIdentifierTypeTemplate.options.length; i++) {
        const { slaveRegExp } = personalIdentifierTypeTemplate.options[i];
        if (slaveRegExp && personalIdentifierValue.match(slaveRegExp)) {
          patientValues.personalIdentifier = {
            personalIdentifierType: personalIdentifierTypeTemplate.options[i].value,
            personalIdentifierValue,
          };
          break;
        }
      }
    }
  }

  yield put(Hcp.actions.addPatient(patientValues, clinicMembership));

  const addPatientResult = yield take([
    Hcp.actionTypes.ADD_PATIENT_SUCCESS,
    Hcp.actionTypes.ADD_PATIENT_ERROR,
  ]);

  if (addPatientResult.type === Hcp.actionTypes.ADD_PATIENT_ERROR) {
    counters.errorCount++;
    counters.errorsList.push(patientInfo);
    yield call(JobTrackerService.trackJob, jobId, controlId, { ...indicators, ...counters });
    yield put(actions.importPatientDatabasePatientStored(counters));
    return;
  }

  const { updatedPatients } = addPatientResult.payload;
  const addedPatient = findLast(updatedPatients, { id: patientValues.id });

  try {
    yield call(importPatientPhiData, database, addedPatient, clinicMembership, clinicMembershipPrvKeyObj, standards);
  } catch (err) {
    counters.errorCount++;
    counters.errorsList.push(patientInfo);
    yield call(JobTrackerService.trackJob, jobId, controlId, { ...indicators, ...counters });
    yield put(actions.importPatientDatabasePatientStored(counters));
    return;
  }

  counters.successCount++;
  yield call(JobTrackerService.trackJob, jobId, controlId, { ...indicators, ...counters });
  yield put(actions.importPatientDatabasePatientStored(counters));
}


function* importPatientWorker(
  database, patient, clinicMembership, clinicMembershipPrvKeyObj,
  countriesCustomIdentifiers, standards,
  jobId, controlId, indicators, counters,
) {
  yield fork(
    importPatient,
    database, patient, clinicMembership, clinicMembershipPrvKeyObj,
    countriesCustomIdentifiers, standards,
    jobId, controlId, indicators, counters,
  );
  return yield take([
    actionTypes.IMPORT_PATIENT_DATABASE_PATIENT_STORED,
    actionTypes.IMPORT_PATIENT_DATABASE_CANCEL,
  ]);
}


function* fetchPatientsPage(database, page = 0, pageSize = 0) {
  const { api } = App.constants.PATIENT_DATABASES[database];
  const fetch = yield getContext('fetch');
  const requestUrl = `http://localhost:12345/${api}/patients?page=${page}&pageSize=${pageSize}`;
  return yield call(request, fetch, requestUrl);
}


function* importPatientDatabase({ payload }) {
  const clinicMembershipId = get(payload, 'clinicMembership.clinicHcpMembershipId');
  const localError = new LocalError({
    code  : 'PatientDatabaseConnectionError',
    params: { clinicMembershipId },
  });
  const transactionTimeout = 30;
  let transactionId;
  try {
    const { database, clinicMembership, isCheckOnly } = payload;
    const { clinic, encryptedPrivateKey, passphrase } = clinicMembership;
    const { clinicId, settings: clinicSettings } = clinic;
    const jobId = `Import${database}PatientDatabase-${clinicId}`;

    const transaction = yield call(openTransaction, [{
      lockType    : isCheckOnly ? 'Shared' : 'Exclusive',
      referenceKey: jobId,
    }], transactionTimeout, 1);
    transactionId = transaction.transactionId;

    const preview = yield call(fetchPatientsPage, database);
    const controlId = get(preview, 'database.controlId');
    if (!controlId) {
      yield call(closeTransaction, transactionId);
      yield put(actions.importPatientDatabaseError(localError));
      yield call(App.dispatchError, localError, messages);
      return;
    }

    const job = yield call(JobTrackerService.getJob, jobId, controlId);
    const storedProgress = get(job, 'payload');
    const { pageSize, page: startPage, itemIdx = 0 } = storedProgress || payload;
    const counters = {
      successCount: get(storedProgress, 'successCount', 0),
      errorCount  : get(storedProgress, 'errorCount', 0),
      errorsList  : get(storedProgress, 'errorsList', []),
    };
    let page = startPage;

    const { totalItems } = preview;
    const currentProgress = totalItems && Math.floor((((page - 1) * pageSize + itemIdx) * 100) / totalItems);
    yield put(actions.importPatientDatabaseSuccess(currentProgress || 0));
    yield put(actions.importPatientDatabasePatientStored(counters));
    if (isCheckOnly || currentProgress === 100) {
      yield call(closeTransaction, transactionId);
      yield put(actions.importPatientDatabaseEnd(true));
      return;
    }

    const clinicMembershipPrvKeyObj = getKeyFromPem(encryptedPrivateKey, passphrase);
    const countriesCustomIdentifiers = {};
    const pages = Math.ceil(totalItems / pageSize);

    const countrySettings = yield select(App.selectors.countrySettings);
    const standards = getStandards(null, countrySettings, clinicSettings);

    while (page <= pages) {
      const patientsPage = yield call(fetchPatientsPage, database, page, pageSize);
      const { items } = patientsPage;
      const len = items.length;
      if (len) {
        // eslint-disable-next-line no-loop-func
        const calls = items.map((patient, idx) => call(
          importPatientWorker,
          database, patient, clinicMembership, clinicMembershipPrvKeyObj,
          countriesCustomIdentifiers, standards,
          jobId, controlId, { page, pageSize, itemIdx: idx + 1 }, counters,
        ));
        for (let i = (page === startPage ? itemIdx : 0); i < len; i++) {
          const callResult = yield calls[i];
          const progress = Math.floor((((page - 1) * pageSize + i + 1) * 100) / totalItems);
          yield put(actions.importPatientDatabaseSuccess(progress));
          if (callResult.type === actionTypes.IMPORT_PATIENT_DATABASE_CANCEL) {
            yield put(actions.importPatientDatabaseEnd());
            yield call(closeTransaction, transactionId);
            return;
          }
          yield call(prolongTransaction, transactionId, transactionTimeout);
        }
      }
      page += 1;
    }
    yield call(closeTransaction, transactionId);
  } catch (err) {
    let error;
    const businessError = err.getBusinessError && err.getBusinessError();
    const code = get(businessError, 'code');
    if (code === 'TransactionScopeAlreadyOpen') {
      error = new LocalError({
        code  : 'PatientDatabaseTransactionScopeAlreadyOpen',
        params: { clinicMembershipId },
      });
    } else {
      error = localError;
    }
    if (transactionId) {
      yield call(closeTransaction, transactionId);
    }
    yield put(actions.importPatientDatabaseError(error));
    yield call(App.dispatchError, error, messages);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* setClinicLicence({ payload }) {
  try {
    const { licenceKey, clinicId } = payload;
    const requestURL = `/api/Clinic/${clinicId}/setLicence`;
    yield call(ApiService.regionalRequest, requestURL, {
      method: 'POST',
      body  : { licenceKey },
    });
    yield put(actions.setClinicLicenceSuccess());
    window.location.reload();
  } catch (err) {
    yield put(actions.setClinicLicenceError(err));
    yield call(App.dispatchError, err, messages);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* fetchClinicCountrySettings({ payload }) {
  try {
    const { countryId } = payload;
    const countrySettings = yield call(CountryLocalizationService.fetchCountrySettings, countryId);
    yield put(actions.fetchClinicCountrySettingsSuccess(countrySettings));
  } catch (err) {
    yield put(actions.fetchClinicCountrySettingsError(err));
    yield call(App.dispatchError, err, messages);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* fetchClinic(clinicId) {
  const requestUrl = `/api/Clinic/${clinicId}`;
  return yield call(ApiService.regionalRequest, requestUrl);
}


function* fetchClinicSettings({ payload }) {
  try {
    const { clinicId } = payload;
    const clinic = yield call(fetchClinic, clinicId);
    yield put(actions.fetchClinicSettingsSuccess(clinic.settings));
  } catch (err) {
    yield put(actions.fetchClinicSettingsError(err));
    yield call(App.dispatchError, err, messages);
  }
}

//----------------------------------------------------------------------------------------------------------------------


function* sagas() {
  yield takeLatest(actionTypes.MANAGE_ORGANIZATION, manageOrganization);
  yield takeLatest(actionTypes.FETCH_CLINIC_CUSTOM_IDENTIFIERS, fetchClinicCustomIdentifiers);
  yield takeLatest(actionTypes.STORE_CLINIC, storeClinic);
  yield takeLatest(actionTypes.GET_STORED_CLINIC, getStoredClinic);
  yield takeLatest(actionTypes.SET_CLINIC_CLOUD_DRIVE, setClinicCloudDrive);
  yield takeLatest(actionTypes.UPDATE_CLINIC_GLUCOSE_SETTINGS, updateClinicGlucoseSettings);
  yield takeLatest(actionTypes.UPDATE_CLINIC_KPI_SETTINGS, updateClinicKpiSettings);
  yield takeLatest(actionTypes.REAUTHORIZE_CLINIC_CLOUD_DRIVE, reauthorizeClinicCloudDrive);
  yield takeLatest(actionTypes.FETCH_ORGANIZATION_MEMBERSHIPS, fetchOrganizationMemberships);
  yield takeLatest(actionTypes.CHECK_PATIENTS_WITHOUT_LEADING_HCP, checkPatientsWithoutLeadingHcp);
  yield takeLatest(actionTypes.CHECK_PENDING_ORGANIZATION_MEMBERSHIPS, checkPendingOrganizationMemberships);
  yield takeLatest(actionTypes.FETCH_CLINIC_MEMBERSHIPS, fetchClinicMemberships);
  yield takeLatest(actionTypes.REMOVE_CLINIC_DATA, removeClinicData);
  yield takeLatest(actionTypes.IMPORT_PATIENT_DATABASE, importPatientDatabase);
  yield takeLatest(actionTypes.SET_CLINIC_LICENCE, setClinicLicence);
  yield takeLatest(actionTypes.FETCH_CLINIC_COUNTRY_SETTINGS, fetchClinicCountrySettings);
  yield takeLatest(actionTypes.FETCH_CLINIC_SETTINGS, fetchClinicSettings);
}


export default [
  sagas,
];
