import PropTypes from 'prop-types';
import valuesIn from 'lodash/values';
import App from 'modules/App';
import * as constants from './constants';


export const connectorType = PropTypes.oneOf(valuesIn(constants.CONNECTOR_TYPES));
export const deviceDataType = PropTypes.oneOf(valuesIn(constants.DEVICE_DATA_TYPES));
export const status = PropTypes.oneOf(valuesIn(constants.DOWNLOADER_STATUSES));
export const connectionStatus = PropTypes.oneOf(valuesIn(constants.CONNECTION_STATUSES));

export const downloader = PropTypes.shape({
  apiVersion           : PropTypes.string,
  mdaVersion           : PropTypes.string,
  bleAvailable         : PropTypes.bool.isRequired,
  bleAvailabilityReason: PropTypes.oneOf(['Compliant', 'NotWindows10', 'BuildNumberNotCompliant', 'NoBleTransmitter']),
  driver               : PropTypes.shape({
    installationState    : PropTypes.oneOf(valuesIn(constants.DRIVER_STATUSES)),
    isDriverInstalled    : PropTypes.bool,
    lastInstallationError: PropTypes.string,
  }),
  devices: PropTypes.arrayOf(PropTypes.shape({
    id              : PropTypes.string.isRequired,
    name            : PropTypes.string.isRequired,
    isPaired        : PropTypes.bool.isRequired,
    requiresPin     : PropTypes.bool.isRequired,
    bluetoothAddress: PropTypes.number.isRequired,
  })),
});

export const scc = PropTypes.shape({
  machineId  : PropTypes.string,
  pairingCode: PropTypes.string,
});

export const devicesByChannel = PropTypes.shape({
  BlueCable : PropTypes.arrayOf(App.shapes.device),
  Bluetooth : PropTypes.arrayOf(App.shapes.device),
  BuiltInUsb: PropTypes.arrayOf(App.shapes.device),
  MicroUsb  : PropTypes.arrayOf(App.shapes.device),
});
