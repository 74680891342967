import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import Button from 'components/Form/Button';
import * as actions from '../../actions';
import messages from '../../messages';
import App from '../../../App';
import * as constants from '../../constants';
import styles from './DownloadDataModal.pcss';


class BluetoothPairingInstruction extends React.PureComponent {

  static propTypes = {
    // Explicit actions
    onSetComponent: PropTypes.func,
    // Implicit props
    formValues    : PropTypes.object,
    // Implicit actions
    onConnect     : PropTypes.func,
  };


  onNext() {
    const device = get(this.props.formValues, 'contextData.device');
    this.props.onConnect(device);
    this.props.onSetComponent('BluetoothPin');
  }


  onChangeDeviceType() {
    this.props.onSetComponent('ChooseConnection');
  }


  renderInstructionRound3BtnOKBt() {
    return (
      <div>
        <ol className={`${styles.howTo__list} ${styles['howTo__list--pairing']} row`}>
          <li className={`${styles.howTo__list__item} col-6`}>
            <div className={styles.howTo__list__item__content}>
              <p><FormattedMessage {...messages.pairingBluetooth.round3BtnOKBt.first} /></p>
              <img src="/assets/svg/Round3BtnOKBt-up.svg" className={`${styles.instruction__image} mt-4`} alt="" />
            </div>
          </li>
          <li className={`${styles.howTo__list__item} col-6`}>
            <div className={styles.howTo__list__item__content}>
              <p><FormattedMessage {...messages.pairingBluetooth.round3BtnOKBt.second} /></p>
              <img src="/assets/svg/Round3BtnOKBt-bt.svg" className={`${styles.instruction__image} mt-4`} alt="" />
            </div>
          </li>
        </ol>
      </div>
    );
  }


  render() {
    return (
      <div className={`${styles.modal__content} h-auto`}>
        <h3 className="modal__subheader "><FormattedMessage {...messages.headers.howToEnablingPairing} /></h3>
        { this.renderInstructionRound3BtnOKBt() }
        <Button
          type="submit"
          styleModifier="primary"
          labelMessage={messages.buttons.next}
          className={`btn--block btn--filled ${styles.pairing__button}`}
          onClick={() => this.onNext()}
        />
        <div className={styles.divider}>
          <span className={styles.divider__text}><FormattedMessage {...messages.infos.or} /></span>
        </div>
        <Button
          styleModifier="transparent"
          labelMessage={messages.buttons.changeDeviceType}
          className="btn--no-size text--primary m-0"
          onClick={() => this.onChangeDeviceType()}
        />
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  formValues: App.selectors.formSelector(constants.BLUETOOTH_PIN_FORM)(state),
});


const mapDispatchToProps = (dispatch) => ({
  onConnect: (device) => dispatch(actions.connectBluetooth(device)),
});


const ConnectedBluetoothPairingInstruction = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BluetoothPairingInstruction);


export default ConnectedBluetoothPairingInstruction;
