exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1r3tG{top:0;left:0;position:fixed;background:#fff;-webkit-box-shadow:0 4px 20px rgba(50,66,121,.16);box-shadow:0 4px 20px rgba(50,66,121,.16);display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;padding:16px;z-index:1030}._1r3tG,._3YdbZ{height:60px;width:100%}.I8PmT{width:36px}html[dir=ltr] .XlqRl{margin-right:24px}html[dir=rtl] .XlqRl{margin-left:24px}.XlqRl form{width:100%}.XlqRl .form-group{margin-bottom:0}._3AEiY{opacity:.3}._3AEiY>*{pointer-events:none}._2GTf3{margin:20px;width:1px;height:100%;background:#e0e8f2}._1m0yM{display:none}._2ggR2{-ms-flex:1 1;flex:1 1;display:relative}._1ljuH{position:absolute;top:50px;width:100%;max-width:430px;background:#fff;padding:16px;border-radius:8px;z-index:1030;overflow:hidden}", ""]);

// exports
exports.locals = {
	"topBar": "_1r3tG",
	"topBarHolder": "_3YdbZ",
	"logo": "I8PmT",
	"element": "XlqRl",
	"element--isDisabled": "_3AEiY",
	"separate": "_2GTf3",
	"hide": "_1m0yM",
	"search": "_2ggR2",
	"searchModal": "_1ljuH"
};