import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import cn from 'classnames';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { motion } from 'framer-motion';
import withStyles from 'isomorphic-style-loader/withStyles';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import Avatar from 'components/Avatar';
import Link from 'components/Link';
import intlShape from 'shapes/intlShape';
import PopulationDashboard from 'svg/population-dashboard.svg';
import MyFamily from 'svg/my-family.svg';
import MyDoctors from 'svg/my-doctors.svg';
import NotepadWithPen from 'svg/notepad-with-pen.svg';
import InviteIcon from 'svg/invite-icon.svg';
import App from 'modules/App';
import Account from 'modules/Account';
import Notifications from 'modules/Notifications';
import Patient from 'modules/Patient';
import * as constants from '../../../constants';
import messages from '../../../messages';
import Sidebar from '../Sidebar';
import styles from './PatientSidebar.pcss';


class PatientSidebar extends Sidebar {

  static propTypes = {
    // Explicit props
    direction                            : PropTypes.string,
    menuOpen                             : PropTypes.bool,
    selectedMenuItem                     : PropTypes.string,
    isCareGiverAccount                   : PropTypes.bool,
    // Explicit actions
    setMenuOpen                          : PropTypes.func,
    setSelectedMenuItem                  : PropTypes.func,
    // Implicit props
    route                                : App.shapes.route,
    openModalId                          : PropTypes.string,
    isClientInitialized                  : PropTypes.bool,
    openDropdownId                       : PropTypes.string,
    lastReceivedNotification             : Notifications.shapes.notification,
    sharingRequests                      : PropTypes.arrayOf(Patient.shapes.sharingRequest),
    isInProgress                         : PropTypes.bool,
    sharingRequestsRejectsInProgress     : PropTypes.arrayOf(PropTypes.string),
    intl                                 : intlShape,
    // Implicit actions
    onFetchFamilyLinks                   : PropTypes.func,
    onInviteFamily                       : PropTypes.func,
    onEnrollInClinic                     : PropTypes.func,
    onFetchSharingRequests               : PropTypes.func,
    onReceiveInvitationFromSessionStorage: PropTypes.func,
    onReauthorizeAccess                  : PropTypes.func,
    onRevokeAccess                       : PropTypes.func,
    onOpenDropdown                       : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.onFetchSharingRequests();
  }


  componentDidMount() {
    this.props.onFetchFamilyLinks();
  }


  componentDidUpdate(prevProps) {
    const { route, lastReceivedNotification, sharingRequests } = this.props;
    if (route.name !== prevProps.route.name) {
      this.onHideAllMenu();
    }
    if (
      prevProps.lastReceivedNotification !== lastReceivedNotification
      && lastReceivedNotification
      && lastReceivedNotification.notificationTrigger
      === Notifications.constants.NOTIFICATIONS_TRIGGERS.SHARING_REQUEST_ACCEPTED_BY_HCP
    ) {
      this.onFetchSharingRequests();
    }
    if (!isEqual(prevProps.sharingRequests, sharingRequests)) {
      ReactTooltip.rebuild();
    }
  }


  onFetchSharingRequests() {
    if (this.props.isCareGiverAccount || (process.env.BROWSER && !this.props.isClientInitialized)) {
      return;
    }
    this.props.onFetchSharingRequests();
  }


  onReauthorizeAccess(evt, sharingRequest) {
    evt.preventDefault();
    this.props.onReauthorizeAccess(sharingRequest);
  }


  onRevokeAccess(evt, sharingRequest) {
    evt.preventDefault();
    this.props.onRevokeAccess(sharingRequest);
  }


  get sortedSharingRequests() {
    return this.props.sharingRequests.filter((sharingRequest) => sharingRequest.sharingStatus === 'Approved');
  }


  get enrollingSharingRequest() {
    return this.props.sharingRequests.filter(
      (sharingRequest) => sharingRequest.sharingStatus === 'PendingEnrolling'
        || sharingRequest.sharingStatus === 'Enrolling',
    );
  }


  get firstSharingRequest() {
    if (this.sortedSharingRequests.length > 0) {
      return this.sortedSharingRequests[0];
    }
    return null;
  }


  get secondSharingRequest() {
    if (this.sortedSharingRequests.length > 1) {
      return this.sortedSharingRequests[1];
    }
    return null;
  }


  get menuItems() {
    return [
      this.renderMenuMyResults(),
      this.renderMenuMyFamily(),
      this.renderMenuInviteFamily(),
      this.renderEnrollInClinic(),
      this.renderHr(),
      this.renderMenuMySharingRequestsHeader(),
      this.renderMenuFirstSharingRequest(),
      this.renderMenuSecondSharingRequest(),
      this.renderMenuOtherSharingRequests(),
      ...map(this.enrollingSharingRequest, (sharingRequest) => this.renderEnrollingSharingRequest(sharingRequest)),
    ];
  }


  get menuItemsSelected() {
    return {
      [constants.PWD_MENU_SELECTED_OPTIONS.MY_FAMILY]             : this.renderMyFamily(),
      [constants.PWD_MENU_SELECTED_OPTIONS.OTHER_SHARING_REQUESTS]: this.renderOtherSharingRequests(),
    };
  }


  renderMenuSharingLink(sharingRequest) {
    if (this.props.openDropdownId !== `${constants.SIDEBAR_DROPDOWN}-${sharingRequest.sharingRequestId}`) {
      return null;
    }

    return (
      <motion.div
        initial={{ width: 0 }}
        animate={{ width: 'auto' }}
        exit={{ width: 0 }}
        transition={{ ease: 'easeOut', duration: 0.15 }}
        className="dropdownMenu__container dropdownMenu__container--oposite ml-3"
      >
        <ul className="dropdownMenu__actions">
          <li className="dropdownMenu__action">
            <Link to="" onClick={(evt) => this.onReauthorizeAccess(evt, sharingRequest)}>
              <FormattedMessage {...Patient.messages.labels.reauthorizeAccess} />
            </Link>
          </li>
          <li className="dropdownMenu__action">
            <Link to="" onClick={(evt) => this.onRevokeAccess(evt, sharingRequest)}>
              <FormattedMessage {...Patient.messages.labels.revokeAccess} />
            </Link>
          </li>
        </ul>
      </motion.div>
    );
  }


  renderMenuMyResults() {
    const { route } = this.props;
    const myResultsHref = this.context.getUrl('my-results');
    const name = this.props.intl.formatMessage(messages.sidebar.buttons.myResults);
    const selected = route.name === 'my-results';
    const fullPatientIcon = <PopulationDashboard />;
    const fullPatientText = this.props.intl.formatMessage(messages.sidebar.buttons.myResults);
    return this.renderMenuButtonItems(name, [fullPatientIcon, fullPatientText], undefined, selected, myResultsHref);
  }


  renderMenuMyFamily() {
    const name = this.props.intl.formatMessage(messages.sidebar.buttons.myFamily);
    const patientsIcon = <MyFamily />;
    const selected = this.isSelectedMenuItem(constants.PWD_MENU_SELECTED_OPTIONS.MY_FAMILY);
    const patientsText = (
      <>
        <FormattedMessage {...messages.sidebar.buttons.myFamily} />
        { this.renderAnimatedChevron(selected) }
      </>
    );
    return this.renderMenuButtonItems(
      name,
      [patientsIcon, patientsText],
      () => this.onSelectMenuItem(constants.PWD_MENU_SELECTED_OPTIONS.MY_FAMILY),
      selected,
    );
  }


  renderMenuInviteFamily() {
    const name = this.props.intl.formatMessage(messages.sidebar.buttons.inviteFamilyMember);
    const fullPatientIcon = <InviteIcon />;
    const fullPatientText = <FormattedMessage {...messages.sidebar.buttons.inviteFamilyMember} />;
    return this.renderMenuButtonItems(
      name,
      [fullPatientIcon, fullPatientText],
      () => {
        this.onHideAllMenu();
        this.props.onInviteFamily();
      },
      this.props.openModalId === Patient.constants.INVITE_FAMILY_MEMBERS_MODAL,
    );
  }


  renderEnrollInClinic() {
    if (this.props.isCareGiverAccount) {
      return null;
    }

    const name = this.props.intl.formatMessage(messages.sidebar.buttons.enrollInClinic);
    const icon = <NotepadWithPen />;
    return this.renderMenuButtonItems(
      name,
      [icon, name],
      () => {
        this.onHideAllMenu();
        this.props.onEnrollInClinic();
      },
      this.props.openModalId === Patient.constants.ENROLL_IN_CLINIC_MODAL,
    );
  }


  renderMenuMySharingRequestsHeader() {
    if (this.props.isCareGiverAccount) {
      return null;
    }
    const name = this.props.intl.formatMessage(messages.sidebar.buttons.mySharingRequests);
    const mySharingRequestsIcon = <MyDoctors />;
    const mySharingRequestsText = (
      <span className="sidebar__elementContentExtend--title">
        <FormattedMessage {...messages.sidebar.buttons.mySharingRequests} />
      </span>
    );
    return this.renderMenuButtonItems(
      name,
      [mySharingRequestsIcon, mySharingRequestsText],
      () => this.onToggleMenu(),
      false,
    );
  }


  renderSharingRequestIcon(sharingRequest, name) {
    const { direction } = this.props;
    const hcpName = sharingRequest.hcp
      ? [sharingRequest.hcp.firstName, sharingRequest.hcp.lastName]
      : sharingRequest.clinic.name;
    const avatar = sharingRequest.hcp ? sharingRequest.hcp.avatar : sharingRequest.clinic.logo;

    return (
      <div
        className="sidebar__hcpCell"
        key={`menu-item-${name}-1`}
        data-for="sidebar"
        data-tip={sharingRequest.clinic.name}
        data-offset={direction === 'ltr' ? '{ \'left\': -2 }' : '{ \'right\': 0 }'}
      >
        <motion.div
          className={cn('sidebar__elementContent--SR')}
          animate={constants.VARIANTS_TYPE.CLOSED}
          variants={constants.ANIMATION_PENDING_SHARING_REQUEST}
        >
          <div
            className={
              cn('sidebar__avatarContainer', {
                sidebar__clickable: !this.isOpenMenu,
              })
            }
            key={`item-menu-${name}`}
            onClick={() => this.onSetMenuOpen(true)}
            onKeyDown={() => {}}
          >
            <Avatar
              avatarImg={avatar}
              name={hcpName}
              imgClassName="sidebar__avatar"
              className="sidebar__avatar"
            />
          </div>
        </motion.div>
      </div>
    );
  }


  renderSharingRequestText(sharingRequest, name) {
    const hcpName = sharingRequest.hcp
      ? `${sharingRequest.hcp.firstName} ${sharingRequest.hcp.lastName}`
      : sharingRequest.clinic.name;
    const clinicName = sharingRequest.hcp
      ? sharingRequest.clinic.name
      : <FormattedMessage {...Patient.messages.labels.unassigned} />;

    return (
      <div className="sidebar__hcpCell sidebar__hcpCellExtended" key={`menu-item-${name}-2`}>
        <motion.div
          className="sidebar__elementContent sidebar__elementContentExtend sidebar__elementContent--noHover"
          animate={constants.VARIANTS_TYPE.CLOSED}
          variants={constants.ANIMATION_PENDING_SHARING_REQUEST}
        >
          <div className="sidebar__hcpNameContainer">
            <div className="sidebar__hcpCellHcpName">{ hcpName }</div>
            <div className="sidebar__hcpCellClinicName">{ clinicName }</div>
          </div>
          {
            this.renderOptions(
              () => this.props.onOpenDropdown(`${constants.SIDEBAR_DROPDOWN}-${sharingRequest.sharingRequestId}`),
              () => this.renderMenuSharingLink(sharingRequest),
            )
          }
        </motion.div>
      </div>
    );
  }


  renderSharingRequest(sharingRequest, name) {
    if (!sharingRequest || sharingRequest.sharingStatus === 'Enrolling') return null;
    const sharingRequestIcon = this.renderSharingRequestIcon(sharingRequest, name);
    const sharingRequestText = this.renderSharingRequestText(sharingRequest, name);
    return [sharingRequestIcon, sharingRequestText];
  }


  renderMenuFirstSharingRequest() {
    if (this.props.isCareGiverAccount) {
      return null;
    }
    const name = 'firstSharingRequest';
    const { firstSharingRequest } = this;
    if (!firstSharingRequest) return null;
    return this.renderSharingRequest(firstSharingRequest, name);
  }


  renderMenuSecondSharingRequest() {
    if (this.props.isCareGiverAccount) {
      return null;
    }
    const name = 'secondSharingRequest';
    const { secondSharingRequest } = this;
    if (!secondSharingRequest) return null;
    return this.renderSharingRequest(secondSharingRequest, name);
  }


  renderMenuOtherSharingRequests() {
    if (this.props.isCareGiverAccount) {
      return null;
    }
    const { sharingRequests } = this.props;
    if (sharingRequests.length <= 2) return null;
    const name = this.props.intl.formatMessage(messages.sidebar.buttons.otherSharingRequests);
    const otherSharingRequestsIcon = this.renderMoreCell(sharingRequests.length - 2);
    const selected = this.isSelectedMenuItem(constants.PWD_MENU_SELECTED_OPTIONS.OTHER_SHARING_REQUESTS);
    const otherSharingRequestsText = (
      <>
        <FormattedMessage {...messages.sidebar.buttons.otherSharingRequests} />
        { this.renderAnimatedChevron(selected) }
      </>
    );
    return this.renderMenuButtonItems(
      name,
      [otherSharingRequestsIcon, otherSharingRequestsText],
      () => this.onSelectMenuItem(constants.PWD_MENU_SELECTED_OPTIONS.OTHER_SHARING_REQUESTS),
      selected,
    );
  }


  renderEnrollingSharingRequest(sharingRequest) {
    const { direction } = this.props;
    if (
      !sharingRequest
      || (sharingRequest.sharingStatus !== 'PendingEnrolling' && sharingRequest.sharingStatus !== 'Enrolling')
    ) return null;
    const { sharingRequestId, clinic } = sharingRequest;
    const name = `enrolling-${sharingRequestId}`;

    const sharingRequestIcon = (
      <div
        className="sidebar__hcpCell"
        key={`menu-item-${name}-1`}
        data-for="sidebar"
        data-tip={sharingRequest.clinic.name}
        data-offset={direction === 'ltr' ? '{ \'left\': -2 }' : '{ \'right\': 0 }'}
      >
        <div
          className={
            cn('sidebar__avatarContainer', {
              sidebar__clickable: !this.isOpenMenu,
            })
          }
          key={`item-menu-${name}`}
          onClick={() => this.onSetMenuOpen(true)}
          onKeyDown={() => {}}
        >
          <Avatar
            avatarImg={clinic.logo}
            name={clinic.name}
            imgClassName="sidebar__avatar"
            className="sidebar__avatar"
          />
        </div>
      </div>
    );

    const infoMessage = sharingRequest.sharingStatus === 'Enrolling'
      ? Patient.messages.infos.requestIsPending
      : Patient.messages.infos.requestIsInProgress;

    const sharingRequestText = (
      <div className="sidebar__hcpCell sidebar__hcpCellExtended" key={`menu-item-${name}-2`}>
        <div className="sidebar__hcpNameContainer">
          <div className="sidebar__hcpCellHcpName">{ clinic.name }</div>
          <div className="sidebar__hcpCellClinicName"><FormattedMessage {...infoMessage} /></div>
        </div>
      </div>
    );

    return [sharingRequestIcon, sharingRequestText];
  }


  renderMyFamily() {
    const name = 'myFamily';
    return (
      <>
        { this.renderSelectedMenuHeader(name) }
        <Patient.partials.NoFamilyMembers />
        <Patient.partials.FamilyList />
      </>
    );
  }


  renderOtherSharingRequests() {
    const name = 'otherSharingRequests';
    return (
      <>
        { this.renderSelectedMenuHeader(name) }
        <Patient.partials.SidebarSharingRequestsList sharingRequests={this.sortedSharingRequests.slice(2)} />
      </>
    );
  }


  render() {
    return (
      <div className={styles.container}>
        { this.renderContent() }
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  route                   : App.selectors.route(state),
  openModalId             : App.selectors.modal(state),
  isClientInitialized     : App.selectors.isClientInitialized(state),
  openDropdownId          : App.selectors.dropdown(state),
  isCareGiverAccount      : Account.selectors.isCareGiverAccount(state),
  lastReceivedNotification: Notifications.selectors.lastReceivedNotification(state),
  sharingRequests         : Patient.selectors.sharingRequests(state),
  isInProgress            : Patient.selectors.isFetchSharingRequestsInProgress(state),
});


const mapDispatchToProps = (dispatch) => ({
  onFetchFamilyLinks    : () => dispatch(Patient.actions.fetchFamilyLinks()),
  onInviteFamily        : () => dispatch(App.actions.openModal(Patient.constants.INVITE_FAMILY_MEMBERS_MODAL)),
  onEnrollInClinic      : () => dispatch(App.actions.openModal(Patient.constants.ENROLL_IN_CLINIC_MODAL)),
  onFetchSharingRequests: () => dispatch(Patient.actions.fetchSharingRequests()),
  onReauthorizeAccess   : (sharingRequest) => Promise.all([
    dispatch(Patient.actions.setBeingApprovedSharingRequestId(sharingRequest.sharingRequestId)),
    dispatch(App.actions.openModal(Patient.constants.APPROVE_DATA_SHARING_MODAL)),
  ]),
  onRevokeAccess: (sharingRequest) => Promise.all([
    dispatch(App.actions.setFormContext(Patient.constants.REVOKE_ACCESS_FORM, sharingRequest)),
    dispatch(App.actions.openModal(Patient.constants.REVOKE_ACCESS_MODAL)),
  ]),
  onOpenDropdown: (dropdownId) => dispatch(App.actions.openDropdown(dropdownId)),
});


const ConnectedPatientSidebar = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(PatientSidebar));


export default withStyles(styles)(ConnectedPatientSidebar);
