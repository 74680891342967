import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import get from 'lodash/get';
import { AppContext } from 'context';
import { getSlug } from 'helpers/urlTools';
import Avatar from 'components/Avatar';
import Button from 'components/Form/Button';
import Account from 'modules/Account';
import styles from './ActiveClinicMembershipBtn.pcss';


class ActiveClinicMembershipBtn extends React.Component {

  static contextType = AppContext;

  static propTypes = {
    // Explicit props
    styleModifier         : PropTypes.string,
    className             : PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    // Implicit props
    activeClinicMembership: Account.shapes.clinicMembership,
  };

  static defaultProps = {
    styleModifier: 'transparent-primary',
    className    : 'btn--block btn--filled',
  };


  get activeClinicSlug() {
    const { organizationUID, name } = get(this.props.activeClinicMembership, 'clinic', {});
    return getSlug(name, organizationUID);
  }


  renderLogo(clinic) {
    const { logo, name } = clinic;
    return (
      <Avatar
        avatarImg={logo}
        name={name}
        className={styles.logo}
        imgClassName={styles.logo__img}
        initialsClassName={styles.logo__initials}
      />
    );
  }


  renderButtonContent(activeEntity) {
    const clinic = get(activeEntity, 'clinic', {});
    return (
      <div className="row">
        <div className="col-auto pr-0">
          { this.renderLogo(clinic) }
        </div>
        <div className="col">
          <p className={styles.label}>{ clinic.name }</p>
        </div>
      </div>
    );
  }


  render() {
    const { activeClinicMembership } = this.props;
    if (!activeClinicMembership) return null;
    const { organizationUID, name } = get(activeClinicMembership, 'clinic', {});
    return (
      <Button
        type="link"
        to={this.context.getUrl('general-population', { clinicSlug: getSlug(name), organizationUID })}
        styleModifier={this.props.styleModifier}
        className={cn(styles.root, this.props.className)}
      >
        { this.renderButtonContent(activeClinicMembership) }
      </Button>
    );
  }

}


const mapStateToProps = (state) => ({
  activeClinicMembership: Account.selectors.activeClinicMembership(state),
});


const ConnectedActiveClinicMembershipBtn = connect(
  mapStateToProps,
)(ActiveClinicMembershipBtn);


export default withStyles(styles)(ConnectedActiveClinicMembershipBtn);
