import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import * as shapes from '../../shapes';
import messages from '../../messages';
import styles from '../../components/DownloadDataModal/DownloadDataModal.pcss';


class Initializing extends React.PureComponent {

  static propTypes = {
    // Explicit props
    status        : shapes.status,
    scc           : shapes.scc,
    // Explicit actions
    onInitialized : PropTypes.func,
    onSetComponent: PropTypes.func,
    // Implicit props
    isInProgress  : PropTypes.bool,
    // Implicit actions
    onCheckStatus : PropTypes.func,
  };


  componentDidMount() {
    this.props.onCheckStatus();
  }


  componentDidUpdate(prevProps) {
    const { isInProgress } = this.props;
    if (prevProps.isInProgress !== isInProgress && !isInProgress) {
      const { status, scc } = this.props;
      if (status === constants.DOWNLOADER_STATUSES.READY) {
        this.props.onInitialized();
        return;
      }
      if (scc.machineId) {
        this.props.onSetComponent('NoConnection');
      } else {
        this.props.onSetComponent('InstallDownloader');
      }
    }
  }


  render() {
    return (
      <div className={styles.modal__content}>
        <h3 className="modal__subheader "><FormattedMessage {...messages.initializing.header} /></h3>
        { /* <p className="modal__info"><FormattedMessage {...messages.downloading.info} /></p> */ }
        <div className={styles.illustration}>
          <img src="/assets/svg/loader.svg" className="rotatingLoader" alt="" />
        </div>
        <p className="modal__info text--center mb-5"><FormattedMessage {...messages.initializing.waiting} /></p>
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  scc         : selectors.scc(state),
  isInProgress: selectors.isCheckSccInProgress(state),
});


const mapDispatchToProps = (dispatch) => ({
  onCheckStatus: () => dispatch(actions.checkSccStatus()),
});


const ConnectedInitializing = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Initializing);


export default ConnectedInitializing;
