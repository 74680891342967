import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import intlShape from 'shapes/intlShape';
import styles from './Switch.pcss';


class Switch extends React.PureComponent {

  static propTypes = {
    // Explicit props
    isActive          : PropTypes.bool,
    onClick           : PropTypes.func,
    labelMessage      : PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    labelMessageValues: PropTypes.object,
    isDisabled        : PropTypes.bool,
    // Implicit props
    intl              : intlShape.isRequired,
  };


  static defaultProps = {
    onClick: () => {},
  }


  renderLabel() {
    const { intl, labelMessage, labelMessageValues } = this.props;
    let label = null;

    if (labelMessage) {
      label = intl.formatMessage(labelMessage, labelMessageValues);
    }

    if (!label) {
      return null;
    }

    return (
      <p
        className={styles.switch__label}
      >
        { label }
      </p>
    );
  }


  render() {
    const { isActive, onClick, isDisabled } = this.props;
    return (
      <div className={styles.switch__container}>
        <button
          type="button"
          className={cn(styles.switch, {
            [styles['switch--on']]      : isActive,
            [styles['switch--disabled']]: isDisabled,
          })}
          onClick={isDisabled ? () => {} : onClick}
        >
          <div className={cn(styles.switch__cursor, {
            [styles['switch__cursor--on']]      : isActive,
            [styles['switch__cursor--disabled']]: isDisabled,
          })}
          />
        </button>
        <span className={styles.switch__label}>
          {this.renderLabel()}
        </span>
      </div>
    );
  }

}

export default injectIntl(withStyles(styles)(Switch));
