import { all, call, delay, put, race, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import map from 'lodash/map';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import sortBy from 'lodash/sortBy';
import { v4 as uuidv4 } from 'uuid';
import { LocalError } from 'helpers/errorTypes';
import { decrypt, encrypt, getKeyFromPem, hybridDecrypt, hybridEncrypt } from 'helpers/crypto';
import appInsights from 'helpers/appInsights';
import { getStandards } from 'helpers/settings';
import StorageExchangeTokenService from 'services/StorageExchangeTokenService';
import NotificationsService from 'services/NotificationsService';
import ApiService from 'services/ApiService';
import SecurityService from 'services/SecurityService';
import App from 'modules/App';
import Account from 'modules/Account';
import CloudDrive from 'modules/CloudDrive';
import ContourCloud from 'modules/ContourCloud';
import DataSources from 'modules/DataSources';
import Information from 'modules/Information';
import { returnDifferentInHealthData } from './helpers';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import * as selectors from './selectors';
import messages from './messages';


//----------------------------------------------------------------------------------------------------------------------

function* autoApproveSharingRequest(sharingRequest) {
  if (!sharingRequest) {
    throw new LocalError({ code: 'NoSharingRequest' });
  }
  const patientProfile = yield select(Account.selectors.patientProfile);
  let phiSet = yield select(selectors.phiSet);
  if (!phiSet) {
    const interval = 1000;
    let tries = 10;
    while (!phiSet && tries) {
      yield delay(interval);
      phiSet = yield select(selectors.phiSet);
      tries--;
    }
    if (!phiSet) {
      throw new LocalError({ code: 'NoPhiSet' });
    }
  }
  yield put(actions.approveSharingRequest(sharingRequest, patientProfile, phiSet));
  const approveResult = yield take([
    actionTypes.APPROVE_SHARING_REQUEST_SUCCESS,
    actionTypes.APPROVE_SHARING_REQUEST_ERROR,
  ]);
  if (approveResult.type === actionTypes.APPROVE_SHARING_REQUEST_ERROR) {
    throw approveResult.error;
  }
  return approveResult.payload.sharingRequest;
}


function* fetchSharingRequests() {
  try {
    const sharingRequests = yield call(ApiService.regionalRequest, '/api/SharingRequest/patient/me');
    const sharingRequestsToBeApproved = filter(
      sharingRequests,
      (sr) => sr.sharingStatus === 'Received' || sr.sharingStatus === 'PendingEnrolling',
    );

    if (sharingRequestsToBeApproved.length) {
      for (let i = 0; i < sharingRequestsToBeApproved.length; i++) {
        try {
          const approvedSharingRequest = yield call(autoApproveSharingRequest, sharingRequestsToBeApproved[i]);
          const idx = findIndex(
            sharingRequests,
            (sr) => sr.sharingRequestId === approvedSharingRequest.sharingRequestId,
          );
          if (idx >= 0) {
            sharingRequests[idx] = approvedSharingRequest;
          }
        } catch (err) {
          // Background action
          if (__DEV__) {
            console.error(err);
          } else {
            appInsights.trackException(err);
          }
        }
      }
    }
    yield put(actions.fetchSharingRequestsSuccess(sharingRequests));
  } catch (err) {
    yield put(actions.fetchSharingRequestsError(err));
    yield call(App.dispatchError, err, messages);
  }
}


function* storeExternalDataSourceToken(payload) {
  yield put(DataSources.actions.storeToken(
    payload.externalDataSourceId, payload.secret, payload.controlId, payload.scope,
  ));
  const fetchVaultResult = yield take([
    DataSources.actionTypes.STORE_TOKEN_SUCCESS,
    DataSources.actionTypes.STORE_TOKEN_ERROR,
  ]);
  if (fetchVaultResult.type === DataSources.actionTypes.STORE_TOKEN_ERROR) {
    throw fetchVaultResult.error;
  }

  return fetchVaultResult;
}


function* enrollToClinic({ payload }) {
  let sharingRequest;
  try {
    const { enrollCode, invitationCode } = payload;
    const { email, firstName, lastName, dateOfBirth } = yield select(Information.selectors.information);

    const patientData = {
      firstName,
      lastName,
      dateOfBirth,
    };

    const requestUrl = '/api/SharingRequest/patient/enroll';
    sharingRequest = yield call(ApiService.regionalRequest, requestUrl, {
      method: 'POST',
      body  : {
        enrollCode,
        invitationCode,
        patientData,
        patientEmailAddress: email,
      },
    });

    yield call(autoApproveSharingRequest, sharingRequest);
    yield put(actions.enrollInClinicSuccess());
  } catch (err) {
    yield put(actions.enrollInClinicError(err, sharingRequest));
    yield call(App.dispatchError, err, messages);
  }
}


function* approveSharingRequest({ payload }) {
  const { sharingRequest, patientProfile, phiSet } = payload;

  try {
    const { sharingRequestId, clinic } = sharingRequest;
    const { encryptedPhiSetReferenceKey, encryptedExchangeToken } = patientProfile;
    let { phiSetReferenceKey } = patientProfile;

    const keyPair = yield select(Account.selectors.keyPair);
    const passphrase = yield select(Account.selectors.passphrase);

    const pubKeyObj = getKeyFromPem(clinic.publicKey);
    const pubKeyObjSelf = getKeyFromPem(keyPair.pubKeyPem);
    const prvKeyObj = getKeyFromPem(keyPair.prvKeyPem, passphrase);

    if (!phiSetReferenceKey) {
      phiSetReferenceKey = yield call(decrypt, encryptedPhiSetReferenceKey, prvKeyObj);
    }

    const patientExchangeToken = yield call(hybridDecrypt, encryptedExchangeToken, prvKeyObj);

    const { exchangeToken } = yield call(
      StorageExchangeTokenService.assignExchangeToken,
      patientExchangeToken,
      'SharingRequest',
      sharingRequestId,
    );

    const externalDataSources = yield select(DataSources.selectors.dataSources);

    yield put(DataSources.actions.fetchVault());
    const fetchVaultResult = yield take([
      DataSources.actionTypes.FETCH_VAULT_SUCCESS,
      DataSources.actionTypes.FETCH_VAULT_ERROR,
    ]);
    if (fetchVaultResult.type === DataSources.actionTypes.FETCH_VAULT_ERROR) {
      yield put(actions.approveSharingRequestError(fetchVaultResult.error, sharingRequest));
    }
    const { vault } = fetchVaultResult.payload;

    const payloadToStoreTokens = [];

    Object.keys(vault).forEach((externalDataSourceId) => {
      const dataSource = externalDataSources.find(
        (item) => (item.externalDataSourceId === parseInt(externalDataSourceId, 10)),
      );
      if (dataSource) {
        switch (dataSource.dataSourceProvider) {
          case DataSources.constants.DATA_SOURCES_TYPE_NAME.CONTOUR_CLOUD:
            payloadToStoreTokens.push({
              externalDataSourceId: parseInt(externalDataSourceId, 10),
              dataSourceProvider  : dataSource.dataSourceProvider,
              secret              : decrypt(vault[externalDataSourceId].lastUsedEncryptedPassword, prvKeyObj),
              controlId           : sharingRequest.sharingRequestId,
            });
            break;
          default:
            payloadToStoreTokens.push({
              externalDataSourceId: parseInt(externalDataSourceId, 10),
              dataSourceProvider  : dataSource.dataSourceProvider,
              secret              : decrypt(vault[externalDataSourceId].encryptedRefreshToken, prvKeyObj),
              controlId           : sharingRequest.sharingRequestId,
            });
            break;
        }
      }
    });

    // WIPRO Style
    if (!payloadToStoreTokens.find(
      (item) => item.dataSourceProvider === DataSources.constants.DATA_SOURCES_TYPE_NAME.CONTOUR_CLOUD,
    )) {
      const dataSource = externalDataSources.find(
        (item) => (item.dataSourceProvider === DataSources.constants.DATA_SOURCES_TYPE_NAME.CONTOUR_CLOUD),
      );
      payloadToStoreTokens.push({
        externalDataSourceId: parseInt(dataSource.externalDataSourceId, 10),
        dataSourceProvider  : DataSources.constants.DATA_SOURCES_TYPE_NAME.CONTOUR_CLOUD,
        secret              : null,
        controlId           : sharingRequest.sharingRequestId,
      });
    }

    const callsStoreExternalDataSourceToken = yield payloadToStoreTokens.map(
      (payloadToStoreToken) => call(storeExternalDataSourceToken, payloadToStoreToken),
    );

    const storeTokensResults = yield all(callsStoreExternalDataSourceToken);

    const callsTokensToEncrypt = storeTokensResults.map(
      (result) => call(hybridEncrypt, result.payload.response.dataSourceExchangeToken, pubKeyObj),
    );
    const callsTokensToEncryptSelf = storeTokensResults.map(
      (result) => call(hybridEncrypt, result.payload.response.dataSourceExchangeToken, pubKeyObjSelf),
    );

    const [
      encryptedPatientPhiSetReferenceKey,
      encryptedPatientExchangeToken,
      encryptedPatientExchangeTokenSelf,
      encryptedDataSourceExchangeTokens,
      encryptedDataSourceExchangeTokensSelf,
    ] = yield all([
      call(encrypt, phiSetReferenceKey, pubKeyObj),
      call(hybridEncrypt, exchangeToken, pubKeyObj),
      call(hybridEncrypt, exchangeToken, pubKeyObjSelf),
      all(callsTokensToEncrypt),
      all(callsTokensToEncryptSelf),
    ]);

    const externalDataSourcesPayload = payloadToStoreTokens.map((item, index) => ({
      externalDataSourceId                : item.externalDataSourceId,
      encryptedDataSourceExchangeToken    : encryptedDataSourceExchangeTokens[index],
      encryptedDataSourceExchangeTokenSelf: encryptedDataSourceExchangeTokensSelf[index],
    }));

    const requestUrl = `/api/SharingRequest/patient/${sharingRequestId}/approve`;
    const { encryptedStatisticalPersonalityId } = phiSet;
    yield call(ApiService.regionalRequest, requestUrl, {
      method: 'PUT',
      body  : {
        encryptedPatientPhiSetReferenceKey,
        encryptedPatientExchangeToken,
        encryptedPatientExchangeTokenSelf,
        externalDataSources        : externalDataSourcesPayload,
        pwdStatisticalPersonalityId: decrypt(encryptedStatisticalPersonalityId, prvKeyObj),
      },
    });

    const approvedSharingRequest = {
      ...sharingRequest,
      encryptedPatientExchangeTokenSelf,
      sharingStatus: sharingRequest.sharingStatus === 'PendingEnrolling' ? 'Enrolling' : 'Approved',
    };

    yield put(actions.approveSharingRequestSuccess(approvedSharingRequest));
  } catch (err) {
    yield put(actions.approveSharingRequestError(err, sharingRequest));
    yield call(App.dispatchError, err, messages);
  } finally {
    yield put(CloudDrive.actions.clearAuthorizationCode());
  }
}


function* revokeSharingRequest({ payload }) {
  try {
    const { sharingRequest } = payload;
    const requestUrl = `/api/SharingRequest/patient/${sharingRequest.sharingRequestId}/revoke`;
    yield call(ApiService.regionalRequest, requestUrl, {
      method: 'PUT',
    });

    const revokedSharingRequest = {
      ...sharingRequest,
      sharingStatus: 'Revoked',
    };

    yield put(actions.revokeSharingRequestSuccess(revokedSharingRequest));
  } catch (err) {
    yield put(actions.revokeSharingRequestError(err));
    yield call(App.dispatchError, err, messages);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* makeSendFamilyLinkRequest(invitee) {
  const invitationCode = uuidv4();
  const requestUrl = '/api/FamilyLink';
  yield call(ApiService.regionalRequest, requestUrl, {
    method: 'POST',
    body  : { invitationCode, ...invitee },
  });
}


function* inviteFamilyMembers({ payload }) {
  try {
    const { invitees } = payload;
    yield all(map(invitees, (invitee) => call(makeSendFamilyLinkRequest, invitee)));
    yield put(actions.fetchFamilyLinkRequests());
    yield put(actions.inviteFamilyMembersSuccess());
  } catch (err) {
    yield put(actions.inviteFamilyMembersError(err));
    yield call(App.dispatchError, err, messages);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* makeFetchFamilyLinkInvitationRequestorPublicKey(familyLinkInvitation) {
  const { requestor } = familyLinkInvitation;
  const fetchProfilePublicKey = requestor.profileType === 'PWD'
    ? SecurityService.fetchPatientProfilePublicKey
    : SecurityService.fetchCgProfilePublicKey;
  return yield call(fetchProfilePublicKey, requestor.profileId);
}


function* getFamilyLinkInvitationApproveData(familyLinkInvitation) {
  const { familyLinkId } = familyLinkInvitation;
  const patientProfile = yield select(Account.selectors.patientProfile);
  if (!patientProfile) {
    throw new LocalError({ code: 'PatientProfileNotFound' });
  }
  const keyPair = yield select(Account.selectors.keyPair);
  const passphrase = yield select(Account.selectors.passphrase);
  const prvKeyObj = getKeyFromPem(keyPair.prvKeyPem, passphrase);
  const {
    encryptedPhiSetReferenceKey: patientEncryptedPhiSetReferenceKey,
    encryptedExchangeToken     : patientEncryptedExchangeToken,
  } = patientProfile;

  const patientExchangeToken = yield call(hybridDecrypt, patientEncryptedExchangeToken, prvKeyObj);
  const { exchangeToken } = yield call(
    StorageExchangeTokenService.assignExchangeToken,
    patientExchangeToken,
    'FamilySharing',
    familyLinkId,
  );

  let { phiSetReferenceKey } = patientProfile;
  if (!phiSetReferenceKey) {
    phiSetReferenceKey = yield call(decrypt, patientEncryptedPhiSetReferenceKey, prvKeyObj);
  }

  return { exchangeToken, phiSetReferenceKey };
}


function* makeSendApproveFamilyLinkInvitation(
  familyLinkInvitation,
  encryptedExchangeToken,
  encryptedPhiSetReferenceKey,
) {
  const { invitationCode } = familyLinkInvitation;
  const requestUrl = `/api/FamilyLink/${invitationCode}/approve`;
  return yield call(ApiService.regionalRequest, requestUrl, {
    method: 'PUT',
    body  : {
      encryptedExchangeToken,
      encryptedPhiSetReferenceKey,
    },
  });
}


function* approveFamilyLinkInvitation(familyLinkInvitation) {
  if (!familyLinkInvitation) {
    throw new LocalError({ code: 'NoFamilyLinkInvitation' });
  }
  const { requestor } = familyLinkInvitation;
  if (!includes(['PWD', 'CG'], requestor.profileType)) {
    throw new LocalError({ code: 'InvalidFamilyLinkInvitationRequestorProfileType' });
  }
  const { exchangeToken, phiSetReferenceKey } = yield call(getFamilyLinkInvitationApproveData, familyLinkInvitation);
  const requestorPublicKey = yield call(makeFetchFamilyLinkInvitationRequestorPublicKey, familyLinkInvitation);
  const requestorPubKeyObj = getKeyFromPem(requestorPublicKey);
  const encryptedExchangeToken = yield call(hybridEncrypt, exchangeToken, requestorPubKeyObj);
  const encryptedPhiSetReferenceKey = yield call(encrypt, phiSetReferenceKey, requestorPubKeyObj);
  return yield call(
    makeSendApproveFamilyLinkInvitation,
    familyLinkInvitation,
    encryptedExchangeToken,
    encryptedPhiSetReferenceKey,
  );
}


function* makeFetchFamilyLinkInvitations() {
  const requestUrl = '/api/FamilyLink/invitations';
  const familyLinks = yield call(ApiService.regionalRequest, requestUrl);
  const familyLinkToBeApproved = find(familyLinks, { status: 'Received' });
  if (familyLinkToBeApproved) {
    const approvedFamilyLink = yield call(approveFamilyLinkInvitation, familyLinkToBeApproved);
    const { familyLinkId } = approvedFamilyLink;
    const idx = findIndex(familyLinks, { familyLinkId });
    familyLinks[idx] = approvedFamilyLink;
  }
  return familyLinks;
}


function* makeFetchFamilyLinkRequests() {
  const requestUrl = '/api/FamilyLink/requests';
  const familyLinks = yield call(ApiService.regionalRequest, requestUrl);
  return sortBy(familyLinks, (fl) => {
    const isInactive = fl.encryptedExchangeToken && fl.status === 'Approved' ? 0 : 1;
    return `${isInactive} ${fl.invitee.lastName} ${fl.invitee.firstName}`;
  });
}


function* fetchFamilyLinks() {
  try {
    const [familyLinkInvitations, familyLinkRequests] = yield all([
      call(makeFetchFamilyLinkInvitations),
      call(makeFetchFamilyLinkRequests),
    ]);
    yield put(actions.fetchFamilyLinksSuccess(familyLinkInvitations, familyLinkRequests));
  } catch (err) {
    yield put(actions.fetchFamilyLinksError(err));
    yield call(App.dispatchError, err, messages);
  }
}


function* fetchFamilyLinkRequests() {
  try {
    const familyLinkRequests = yield call(makeFetchFamilyLinkRequests);
    yield put(actions.fetchFamilyLinkRequestsSuccess(familyLinkRequests));
  } catch (err) {
    yield put(actions.fetchFamilyLinkRequestsError(err));
    yield call(App.dispatchError, err, messages);
  }
}


function* revokeFamilyAccess({ payload }) {
  try {
    const { familyLink } = payload;
    const { invitationCode } = familyLink;
    const requestUrl = `/api/FamilyLink/${invitationCode}/revoke`;
    yield call(ApiService.regionalRequest, requestUrl, {
      method: 'PUT',
    });
    yield put(actions.revokeFamilyAccessSuccess(familyLink));
  } catch (err) {
    yield put(actions.revokeFamilyAccessError(err));
    yield call(App.dispatchError, err, messages);
  }
}


function* sync({ payload }) {
  try {
    const { patientProfile, passphrase } = payload;
    const accessToken = get(patientProfile, 'accessToken');
    if (accessToken) {
      yield put(
        CloudDrive.actions.fetchPhiSet(patientProfile, actions.setPhiSet),
      );
      const result = yield take([
        CloudDrive.actionTypes.FETCH_PHI_SET_SUCCESS,
        CloudDrive.actionTypes.FETCH_PHI_SET_ERROR,
      ]);
      if (result.type === CloudDrive.actionTypes.FETCH_PHI_SET_ERROR) {
        yield put(actions.syncError(CloudDrive.actionTypes.FETCH_PHI_SET_ERROR));
        return;
      }
    } else {
      yield put(actions.syncError(new LocalError({ code: 'EmptyAccessToken' })));
      return;
    }

    yield put(actions.externalDataSourcesMigration());
    yield put(actions.migrationSharingRequest());

    const connectedDataSources = yield select(DataSources.selectors.connectedDataSources);

    const externalDataSourcesIds = connectedDataSources.map((connectedDataSource) => connectedDataSource.externalDataSourceId);
    const accountCCAccessToken = yield select(Account.selectors.accountCCAccessToken);
    const externalDataSourcesTokens = connectedDataSources.map((externalDataSource) => {
      if (externalDataSource.dataSourceProvider === DataSources.constants.DATA_SOURCES_TYPE_NAME.CONTOUR_CLOUD) {
        return {
          ...externalDataSource,
          accessToken: accountCCAccessToken.accessToken,
        };
      }
      return externalDataSource;
    });
    let phiSet = yield select(selectors.phiSet);
    let phiSetDocumentId = yield select(selectors.phiSetDocumentId);
    const { storageProvider, phiSetReferenceKey } = patientProfile;
    const countrySettings = yield select(App.selectors.countrySettings);
    const standards = getStandards(null, countrySettings);
    const payloadDataSources = {
      externalDataSourcesIds,
      phiSet,
      phiSetDocumentId,
      accessToken,
      storageProvider,
      phiSetReferenceKey,
      activePatient           : patientProfile,
      passphrase,
      standards,
      externalDataSourcesTokens,
      successAction           : actions.setImportedReadings,
      successRelatedDataAction: actions.setImportedRelatedData,
    };
    yield put(DataSources.actions.sync(payloadDataSources));
    yield take([
      DataSources.actionTypes.SYNC_SUCCESS,
      DataSources.actionTypes.SYNC_ERROR,
    ]);

    phiSet = yield select(selectors.phiSet);
    phiSetDocumentId = yield select(selectors.phiSetDocumentId);

    yield put(ContourCloud.actions.getMe());
    const contourCloudAction = yield take([
      ContourCloud.actionTypes.CONTOUR_CLOUD_ME_SUCCESS, ContourCloud.actionTypes.CONTOUR_CLOUD_ME_ERROR,
    ]);

    if (contourCloudAction.type === ContourCloud.actionTypes.CONTOUR_CLOUD_ME_SUCCESS) {
      const patientCC = yield select(ContourCloud.selectors.profile);
      // const account = yield select(Account.selectors.account);

      const differentInHealthData = returnDifferentInHealthData(phiSet, patientCC);
      // const differentInProfile = returnDifferentInProfile(patientProfile, patientCC);
      // const differentInAccount = returnDifferentInAccount(account, patientCC);

      const elementsToWait = [];

      if (!isEmpty(differentInHealthData)) {
        yield put(CloudDrive.actions.updatePhiSet(
          differentInHealthData,
          phiSet,
          phiSetDocumentId,
          { phiSetReferenceKey, storageProvider, accessToken },
          actions.setPhiSet,
        ));
        elementsToWait.push(
          take([CloudDrive.actionTypes.UPDATE_PHI_SET_SUCCESS, CloudDrive.actionTypes.UPDATE_PHI_SET_ERROR]),
        );
      }


      // if (!isEmpty(differentInProfile)) {
      //   yield put(Account.actions.updatePatientProfile({ ...patientProfile, ...differentInProfile }));
      //   elementsToWait.push(
      //     take([Account.actionTypes.UPDATE_PATIENT_PROFILE_SUCCESS, Account.actionTypes.UPDATE_PATIENT_PROFILE_ERROR])
      //   );
      // }

      // if (!isEmpty(differentInAccount)) {
      //   yield put(Account.actions.updateAccount(differentInAccount));
      //   elementsToWait.push(
      //     take([Account.actionTypes.UPDATE_ACCOUNT_SUCCESS, Account.actionTypes.UPDATE_ACCOUNT_ERROR])
      //   );
      // }

      yield all(elementsToWait);
    }

    yield put(actions.syncSuccess());
  } catch (err) {
    yield put(actions.syncError(err));
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* storeNote({ payload }) {
  try {
    const { notes, phiSet, phiSetDocumentId, phisetVisitId, patientProfile, sharingRequest, visitMetadata } = payload;

    yield put(CloudDrive.actions.storeNotes(
      notes,
      phiSet,
      phiSetDocumentId,
      phisetVisitId,
      patientProfile,
      actions.setNotes,
    ));
    yield take([CloudDrive.actionTypes.STORE_NOTES_SUCCESS, CloudDrive.actionTypes.STORE_NOTES_ERROR]);
    if (sharingRequest && phisetVisitId) {
      const {
        firstName: patientFirstName,
        lastName : patientLastName,
      } = yield select(Information.selectors.information);
      const { sharingRequestId, encryptedLocalPatientProfileId: encryptedClinicPatientProfileId } = sharingRequest;
      const { id: clinicId, name: clinicName } = sharingRequest.clinic;
      const hcpProfileId = get(visitMetadata, 'createdBy.hcpProfileId');
      yield call(NotificationsService.sendNoteWrittenByPwdNotification, {
        clinicId,
        clinicName,
        patientFirstName,
        patientLastName,
        encryptedClinicPatientProfileId,
        sharingRequestId,
        hcpProfileId,
      });
    }
    yield put(actions.storeNotesSuccess());
  } catch (err) {
    yield put(actions.storeNotesError(err));
  }
}

//----------------------------------------------------------------------------------------------------------------------

// @TODO: Refactor with Account
function* fetchAccessToken(encryptedExchangeToken, prvKeyObj) {
  const exchangeToken = hybridDecrypt(encryptedExchangeToken, prvKeyObj);
  const accessToken = yield call(StorageExchangeTokenService.fetchAccessToken, exchangeToken);
  return { accessToken, exchangeToken };
}

// @TODO: Refactor with Account
function* exchangeTokenWorker(activeFamilyLink, expiresIn, exchangeToken) {
  if (!expiresIn) {
    return;
  }
  const interval = (expiresIn - (expiresIn / 4)) * 1000;
  while (true) {
    yield delay(interval);
    const accessToken = yield call(StorageExchangeTokenService.fetchAccessToken, exchangeToken);
    yield put(actions.activateFamilyMemberSuccess({ ...activeFamilyLink, accessToken }));
  }
}


function* activateFamilyMember({ payload }) {
  try {
    const { familyLink } = payload;
    const { encryptedExchangeToken, encryptedPhiSetReferenceKey } = familyLink;
    let err;
    if (!encryptedPhiSetReferenceKey) {
      err = new LocalError({ code: 'NoPhiSetReferenceKey' });
    } else if (!encryptedExchangeToken) {
      err = new LocalError({ code: 'NoStorageRefreshToken' });
    }
    if (err) {
      yield put(actions.activateFamilyMemberError(err));
      yield call(App.dispatchError, err, messages);
      return;
    }

    const passphrase = yield select(Account.selectors.passphrase);
    const keyPair = yield select(Account.selectors.keyPair);
    const prvKeyObj = getKeyFromPem(keyPair.prvKeyPem, passphrase);

    const activeFamilyLink = {
      ...pick(familyLink, ['familyLinkId', 'invitationCode', 'status', 'storageProvider']),
      ...pick(familyLink.invitee, ['firstName', 'lastName', 'avatar', 'dateOfBirth']),
    };

    activeFamilyLink.phiSetReferenceKey = decrypt(encryptedPhiSetReferenceKey, prvKeyObj);
    const { accessToken, exchangeToken } = yield call(fetchAccessToken, encryptedExchangeToken, prvKeyObj);
    const { expiresIn } = accessToken;
    activeFamilyLink.accessToken = accessToken;
    activeFamilyLink.exchangeToken = exchangeToken;

    yield put(actions.activateFamilyMemberSuccess(activeFamilyLink));

    yield race([
      call(exchangeTokenWorker, activeFamilyLink, expiresIn, exchangeToken),
      take(actionTypes.ACTIVATE_FAMILY_MEMBER),
      take(actionTypes.DEACTIVATE_FAMILY_MEMBER),
      take(Account.actionTypes.SIGN_OUT),
    ]);
  } catch (err) {
    yield put(actions.activateFamilyMemberError(err));
    yield call(App.dispatchError, err, messages);
  }
}


function* onStoreReadingsSuccess({ payload }) {
  const { updatedPhiSet, newPhiSetDocumentId, phiSetReferenceKey, readings, importData, importId } = payload;
  yield put(actions.setImportedReadings(updatedPhiSet, newPhiSetDocumentId, phiSetReferenceKey, readings));
  if (importId) {
    const { accessToken } = yield select(Account.selectors.accountCCAccessToken);
    yield put(DataSources.actions.storeReadings({ importData, importId, accessToken }));
  }
}


function* onStoreMeasurementsSuccess({ payload }) {
  const { phiSet, phiSetDocumentId } = payload;
  const oldPhiSet = yield select(selectors.phiSet);
  yield put(actions.setPhiSet(phiSet, phiSetDocumentId));
  if (!oldPhiSet || (phiSet.treatmentType !== oldPhiSet.treatmentType || phiSet.diabetesType !== oldPhiSet.diabetesType)) {
    const { accessToken } = yield select(Account.selectors.accountCCAccessToken);
    yield put(DataSources.actions.storeHealthData({ ...phiSet, accessToken }));
  }
}


function* setDataSourceExchangeToken(payload) {
  yield put(DataSources.actions.setDataSourceExchangeToken(
    payload.externalDataSourceId, payload.encryptedDataSourceExchangeToken,
  ));
  const setDataSourceExchangeTokenResult = yield take([
    DataSources.actionTypes.SET_DATA_SOURCE_EXCHANGE_TOKEN_SUCCESS,
    DataSources.actionTypes.SET_DATA_SOURCE_EXCHANGE_TOKEN_ERROR,
  ]);
  if (setDataSourceExchangeTokenResult.type === DataSources.actionTypes.SET_DATA_SOURCE_EXCHANGE_TOKEN_ERROR) {
    throw setDataSourceExchangeTokenResult.error;
  }

  return setDataSourceExchangeTokenResult;
}


function* assignTokenExternalDataSource(payload) {
  yield put(DataSources.actions.assignToken(
    payload.dataSourceExchangeToken, payload.controlId, payload.scope,
  ));
  const assignTokenResult = yield take([
    DataSources.actionTypes.ASSIGN_TOKEN_SUCCESS,
    DataSources.actionTypes.ASSIGN_TOKEN_ERROR,
  ]);
  if (assignTokenResult.type === DataSources.actionTypes.SET_DATA_SOURCE_EXCHANGE_TOKEN_ERROR) {
    throw assignTokenResult.error;
  }

  return assignTokenResult;
}


function* setDataSourceExchangeTokensSharingRequest({
  sharingRequestId,
  payload,
}) {
  try {
    const requestUrl = `/api/SharingRequest/patient/setDataSourceExchangeTokens/${sharingRequestId}`;
    return yield call(ApiService.regionalRequest, requestUrl, {
      method: 'POST',
      body  : payload,
    });
  } catch (err) {
    return err;
  }
}


function* externalDataSourcesMigration() {
  try {
    const passphrase = yield select(Account.selectors.passphrase);
    const keyPair = yield select(Account.selectors.keyPair);
    const prvKeyObj = getKeyFromPem(keyPair.prvKeyPem, passphrase);
    const pubKeyObjSelf = getKeyFromPem(keyPair.pubKeyPem);
    const connectedDataSources = yield select(DataSources.selectors.connectedDataSources);
    if (connectedDataSources.some((dataSource) => !dataSource.encryptedDataSourceExchangeToken)) {

      yield put(DataSources.actions.fetchVault());
      const fetchVaultResult = yield take([
        DataSources.actionTypes.FETCH_VAULT_SUCCESS,
        DataSources.actionTypes.FETCH_VAULT_ERROR,
      ]);
      if (fetchVaultResult.type === DataSources.actionTypes.FETCH_VAULT_ERROR) {
        return;
      }

      const { vault } = fetchVaultResult.payload;

      const payloadToStoreTokens = [];

      connectedDataSources.forEach((dataSource) => {
        if (!dataSource.encryptedDataSourceExchangeToken) {
          switch (dataSource.dataSourceProvider) {
            case DataSources.constants.DATA_SOURCES_TYPE_NAME.CONTOUR_CLOUD:
              payloadToStoreTokens.push({
                externalDataSourceId: parseInt(dataSource.externalDataSourceId, 10),
                dataSourceProvider  : dataSource.dataSourceProvider,
                secret              : vault[dataSource.externalDataSourceId]
                  ? decrypt(vault[dataSource.externalDataSourceId].lastUsedEncryptedPassword, prvKeyObj)
                  : null,
                controlId: dataSource.accountExternalDataSourceId,
                scope    : 'Self',
              });
              break;
            default:
              if (vault[dataSource.externalDataSourceId]) {
                payloadToStoreTokens.push({
                  externalDataSourceId: parseInt(dataSource.externalDataSourceId, 10),
                  dataSourceProvider  : dataSource.dataSourceProvider,
                  secret              : decrypt(vault[dataSource.externalDataSourceId].encryptedRefreshToken, prvKeyObj),
                  controlId           : dataSource.accountExternalDataSourceId,
                  scope               : 'Self',
                });
              }
              break;
          }
        }
      });

      const callsStoreExternalDataSourceToken = yield payloadToStoreTokens.map(
        (payloadToStoreToken) => call(storeExternalDataSourceToken, payloadToStoreToken),
      );

      const storeTokensResults = yield all(callsStoreExternalDataSourceToken);

      const callsSetDataSourceExchangeToken = storeTokensResults.map((tokenResult, index) => {
        if (tokenResult.type === DataSources.actionTypes.STORE_TOKEN_SUCCESS) {
          const encryptedDataSourceExchangeToken = hybridEncrypt(
            tokenResult.payload.response.dataSourceExchangeToken, pubKeyObjSelf,
          );
          const connectedDataSourceIndex = connectedDataSources.findIndex(
            (dataSource) => dataSource.externalDataSourceId === payloadToStoreTokens[index].externalDataSourceId,
          );

          connectedDataSources[connectedDataSourceIndex].encryptedDataSourceExchangeToken = encryptedDataSourceExchangeToken;

          return call(setDataSourceExchangeToken, {
            externalDataSourceId: payloadToStoreTokens[index].externalDataSourceId,
            encryptedDataSourceExchangeToken,
          });
        }
        return null;
      }).filter(((tokenResult) => tokenResult));

      yield all(callsSetDataSourceExchangeToken);
    }

    let sharingRequests = yield select(selectors.sharingRequests);

    if (isEmpty(sharingRequests)) {
      yield call(fetchSharingRequests);
      sharingRequests = yield select(selectors.sharingRequests);
    }

    const approvedSharingRequestsWithoutDataSources = sharingRequests
      .filter((sharingRequest) => sharingRequest !== 'Approved')
      .filter((sharingRequest) => isEmpty(sharingRequest.externalDataSources));

    if (!isEmpty(approvedSharingRequestsWithoutDataSources)) {
      const assignTokenResults = [];
      for (
        let sharingRequestIndex = 0;
        sharingRequestIndex < approvedSharingRequestsWithoutDataSources.length;
        sharingRequestIndex++
      ) {
        const sharingRequest = approvedSharingRequestsWithoutDataSources[sharingRequestIndex];
        for (let dataSourceIndex = 0; dataSourceIndex < connectedDataSources.length; dataSourceIndex++) {
          const dataSource = connectedDataSources[dataSourceIndex];
          const result = yield call(assignTokenExternalDataSource, {
            dataSourceExchangeToken: hybridDecrypt(dataSource.encryptedDataSourceExchangeToken, prvKeyObj),
            controlId              : sharingRequest.sharingRequestId,
            scope                  : 'Sharing',
          });
          assignTokenResults.push({
            ...result,
            sharingRequestId    : sharingRequest.sharingRequestId,
            externalDataSourceId: dataSource.externalDataSourceId,
            pubKeyObj           : getKeyFromPem(get(sharingRequest, 'clinic.publicKey')),
          });
        }
      }

      const connectedData = assignTokenResults.map((tokenResult) => {
        if (tokenResult.type === DataSources.actionTypes.ASSIGN_TOKEN_SUCCESS) {
          return {
            externalDataSourceId            : tokenResult.externalDataSourceId,
            sharingRequestId                : tokenResult.sharingRequestId,
            encryptedDataSourceExchangeToken: hybridEncrypt(
              tokenResult.payload.response.dataSourceExchangeToken,
              tokenResult.pubKeyObj,
            ),
            encryptedDataSourceExchangeTokenSelf: hybridEncrypt(
              tokenResult.payload.response.dataSourceExchangeToken,
              pubKeyObjSelf,
            ),
          };
        }
        return null;
      }).filter(((tokenResult) => tokenResult));

      const callsSetDataSourceExchangeToken = approvedSharingRequestsWithoutDataSources.map((sharingRequest) => {
        const externalDataSources = connectedData.filter((data) => data.sharingRequestId === sharingRequest.sharingRequestId);
        if (isEmpty(externalDataSources)) {
          return null;
        }
        return call(setDataSourceExchangeTokensSharingRequest, {
          sharingRequestId: sharingRequest.sharingRequestId,
          payload         : {
            externalDataSources,
          },
        });
      }).filter((callSetDataSourceExchangeToken) => callSetDataSourceExchangeToken);

      yield all(callsSetDataSourceExchangeToken);
    }
  } catch (err) {
    console.error(err);
  }
}


function* reauthContourCloud() {
  try {

    let sharingRequests = yield select(selectors.sharingRequests);

    if (isEmpty(sharingRequests)) {
      yield call(fetchSharingRequests);
      sharingRequests = yield select(selectors.sharingRequests);
    }

    const keyPair = yield select(Account.selectors.keyPair);
    const pubKeyObjSelf = getKeyFromPem(keyPair.pubKeyPem);

    const connectedDataSources = yield select(DataSources.selectors.connectedDataSources);
    const dataSourceContourCloud = connectedDataSources.find(
      (dataSource) => dataSource.dataSourceProvider === 'ContourCloud',
    );
    let bodyPayload;

    if (!dataSourceContourCloud.encryptedDataSourceExchangeToken) {
      yield put(DataSources.actions.fetchVault());
      const fetchVaultResult = yield take([
        DataSources.actionTypes.FETCH_VAULT_SUCCESS,
        DataSources.actionTypes.FETCH_VAULT_ERROR,
      ]);
      if (fetchVaultResult.type === DataSources.actionTypes.FETCH_VAULT_ERROR) {
        yield put(actions.reauthContourCloudError(fetchVaultResult.error));
        return;
      }
      const { vault } = fetchVaultResult.payload;
      if (vault[connectedDataSources.externalDataSourceId]) {
        bodyPayload = vault[connectedDataSources.externalDataSourceId].lastUsedEncryptedPassword;
      }
    }

    const body = {
      ...dataSourceContourCloud,
      payload: bodyPayload,
      scope  : 'Self',
    };

    yield put(DataSources.actions.getPernamentRefreshToken(body));
    const pernamentRefreshTokenResult = yield take([
      DataSources.actionTypes.GET_PERNAMENT_REFRESH_TOKEN_SUCCESS,
      DataSources.actionTypes.GET_PERNAMENT_REFRESH_TOKEN_ERROR,
    ]);

    if (pernamentRefreshTokenResult.type === DataSources.actionTypes.GET_PERNAMENT_REFRESH_TOKEN_ERROR) {
      yield put(actions.reauthContourCloudError(pernamentRefreshTokenResult.error));
    }
    const { response: { dataSourceExchangeToken } } = pernamentRefreshTokenResult.payload;

    const encryptedDataSourceExchangeToken = hybridEncrypt(dataSourceExchangeToken, pubKeyObjSelf);

    yield call(setDataSourceExchangeToken, {
      externalDataSourceId: dataSourceContourCloud.externalDataSourceId,
      encryptedDataSourceExchangeToken,
    });

    const approvedSharingRequests = sharingRequests
      .map((sharingRequest) => ({
        ...sharingRequest,
        pubKeyObj: getKeyFromPem(get(sharingRequest, 'clinic.publicKey')),
      }))
      .filter((sharingRequest) => sharingRequest.sharingStatus === 'Approved');

    if (!isEmpty(approvedSharingRequests)) {
      const assignTokenResults = [];
      for (
        let sharingRequestIndex = 0;
        sharingRequestIndex < approvedSharingRequests.length;
        sharingRequestIndex++
      ) {
        const sharingRequest = approvedSharingRequests[sharingRequestIndex];
        const result = yield call(assignTokenExternalDataSource, {
          dataSourceExchangeToken,
          controlId: sharingRequest.sharingRequestId,
          scope    : 'Sharing',
        });
        assignTokenResults.push({
          ...result,
          sharingRequestId    : sharingRequest.sharingRequestId,
          externalDataSourceId: dataSourceContourCloud.externalDataSourceId,
          pubKeyObj           : getKeyFromPem(get(sharingRequest, 'clinic.publicKey')),
        });
      }

      const connectedData = assignTokenResults.map((tokenResult) => {
        if (tokenResult.type === DataSources.actionTypes.ASSIGN_TOKEN_SUCCESS) {
          return {
            externalDataSourceId            : tokenResult.externalDataSourceId,
            sharingRequestId                : tokenResult.sharingRequestId,
            encryptedDataSourceExchangeToken: hybridEncrypt(
              tokenResult.payload.response.dataSourceExchangeToken,
              tokenResult.pubKeyObj,
            ),
            encryptedDataSourceExchangeTokenSelf: hybridEncrypt(
              tokenResult.payload.response.dataSourceExchangeToken,
              pubKeyObjSelf,
            ),
          };
        }
        return null;
      }).filter(((tokenResult) => tokenResult));

      const callsSetDataSourceExchangeToken = approvedSharingRequests.map((sharingRequest) => {
        const externalDataSources = connectedData.filter(
          (data) => data.sharingRequestId === sharingRequest.sharingRequestId,
        );
        if (isEmpty(externalDataSources)) {
          return null;
        }
        return call(setDataSourceExchangeTokensSharingRequest, {
          sharingRequestId: sharingRequest.sharingRequestId,
          payload         : {
            externalDataSources,
          },
        });
      }).filter((callSetDataSourceExchangeToken) => callSetDataSourceExchangeToken);

      yield all(callsSetDataSourceExchangeToken);
    }
    yield put(actions.reauthContourCloudSuccess());
  } catch (err) {
    yield put(actions.reauthContourCloudError(err));
    yield call(App.dispatchError, err, messages);
  }
}


function* updateSharingRequest({ sharingRequestId, payload }) {
  const requestUrl = `/api/SharingRequest/patient/${sharingRequestId}`;
  yield call(ApiService.regionalRequest, requestUrl, {
    method: 'PUT',
    body  : payload,
  });
}


function* migrationSharingRequest() {
  try {
    const keyPair = yield select(Account.selectors.keyPair);
    const passphrase = yield select(Account.selectors.passphrase);
    const prvKeyObj = getKeyFromPem(keyPair.prvKeyPem, passphrase);
    const phiSet = yield select(selectors.phiSet);

    const sharingRequests = yield select(selectors.sharingRequests);
    const approvedSharingRequestsStatisticalPersonalityId = sharingRequests
      .filter((sharingRequest) => sharingRequest.sharingStatus === 'Approved')
      .filter((sharingRequest) => isEmpty(sharingRequest.encryptedPwdStatisticalPersonalityId));


    const callsSetDataSourceExchangeToken = approvedSharingRequestsStatisticalPersonalityId.map(
      (sharingRequest) => call(updateSharingRequest, {
        sharingRequestId: sharingRequest.sharingRequestId,
        payload         : {
          pwdStatisticalPersonalityId: decrypt(phiSet.encryptedStatisticalPersonalityId, prvKeyObj),
        },
      }),
    );

    yield all(callsSetDataSourceExchangeToken);

    yield put(actions.migrationSharingRequestSuccess());
  } catch (err) {
    yield put(actions.migrationSharingRequestError(err));
  }
}


function* sagas() {
  yield takeLatest(actionTypes.FETCH_SHARING_REQUESTS, fetchSharingRequests);
  yield takeLatest(actionTypes.ENROLL_IN_CLINIC, enrollToClinic);
  yield takeLatest(actionTypes.APPROVE_SHARING_REQUEST, approveSharingRequest);
  yield takeLatest(actionTypes.REVOKE_SHARING_REQUEST, revokeSharingRequest);
  yield takeLatest(actionTypes.INVITE_FAMILY_MEMBERS, inviteFamilyMembers);
  yield takeLatest(actionTypes.FETCH_FAMILY_SHARING_LINKS, fetchFamilyLinks);
  yield takeLatest(actionTypes.FETCH_FAMILY_SHARING_LINK_REQUESTS, fetchFamilyLinkRequests);
  yield takeLatest(actionTypes.REVOKE_FAMILY_ACCESS, revokeFamilyAccess);
  yield takeLatest(actionTypes.ACTIVATE_FAMILY_MEMBER, activateFamilyMember);
  yield takeLatest(actionTypes.SYNC, sync);
  yield takeEvery(actionTypes.STORE_NOTES, storeNote);
  yield takeLatest(actionTypes.ON_STORE_READINGS_SUCCESS, onStoreReadingsSuccess);
  yield takeLatest(actionTypes.ON_STORE_MEASUREMENT_SUCCESS, onStoreMeasurementsSuccess);
  yield takeLatest(actionTypes.EXTERNAL_DATA_SOURCES_MIGRATION, externalDataSourcesMigration);
  yield takeLatest(actionTypes.REAUTH_CONTOUR_CLOUD, reauthContourCloud);
  yield takeLatest(actionTypes.MIGRATION_SHARING_REQUEST, migrationSharingRequest);
}

export default [
  sagas,
];
