import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import Button from 'components/Form/Button';
import ArrowDownNegative from 'svg/arrow-down-negative.svg';
import App from 'modules/App';
import Account from 'modules/Account';
import * as constants from '../../constants';
import messages from '../../messages';
import styles from './DownloadDataFromMeterBtn.pcss';


class DownloadDataFromMeterBtn extends React.PureComponent {

  static propTypes = {
    // Explicit props
    styleModifier         : PropTypes.string,
    className             : PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isReadOnly            : PropTypes.bool,
    isDisabled            : PropTypes.bool,
    // Implicit props
    devices               : PropTypes.arrayOf(App.shapes.device),
    activeProfileType     : Account.shapes.profileType,
    activeClinicMembership: Account.shapes.clinicMembership,
    // Implicit actions
    onOpenModal           : PropTypes.func,
  };


  static defaultProps = {
    styleModifier: 'primary',
    className    : 'btn--block btn--filled m-0',
    isReadOnly   : false,
  };


  get isDisabled() {
    const { devices, isReadOnly, isDisabled } = this.props;
    return isReadOnly || isDisabled || !devices || !devices.length;
  }


  renderButtonContent() {
    return (
      <div className={cn(styles.content, 'row')}>
        <div className="col-auto pr-0">
          <div className={cn(styles.icon__wrapper)}>
            <div className={cn(styles.icon, { [styles['icon--notActive']]: this.props.isReadOnly })}>
              <ArrowDownNegative className={styles.icon__arrow} />
            </div>
          </div>
        </div>
        <div className="col w-100">
          <p className={cn(styles.label, styles['label--lead'])}>
            <FormattedMessage {...messages.buttons.downloadData} />
          </p>
          <p className={cn(styles.label, styles['label--secondary'])}>
            <FormattedMessage {...messages.buttons.fromMeter} />
          </p>
        </div>
      </div>
    );
  }


  renderApprovalButtonPlaceholder() {
    return (
      <Button
        className={cn(styles.root, styles['root--notActive'], this.props.className)}
        isDisabled
      >
        <div className="d-flex align-items-center" style={{ flexWrap: 'nowrap' }}>
          <div className="col-auto pr-0">
            <div className={cn(styles.icon, styles['icon--notActive'])}>
              <ArrowDownNegative className={styles.icon__arrow} />
            </div>
          </div>
          <div className="col">
            <p className={cn(styles.label, styles['label--lead'], styles['label--notActive'])}>
              <FormattedMessage {...messages.buttons.waitForApproval} />
            </p>
          </div>
        </div>
      </Button>
    );
  }


  renderButton() {
    const { isReadOnly, styleModifier, className } = this.props;
    return (
      <Button
        id="meterDownloadData"
        styleModifier={styleModifier}
        className={cn(styles.root, { [styles['root--notActive']]: isReadOnly }, className)}
        isDisabled={this.isDisabled}
        onClick={this.props.onOpenModal}
      >
        { this.renderButtonContent() }
      </Button>
    );
  }


  render() {
    const { activeProfileType, activeClinicMembership } = this.props;
    if (
      activeProfileType === Account.constants.PROFILE_TYPES.HCP
      && (
        !activeClinicMembership
          || activeClinicMembership.membershipStatus === 'Pending'
          || activeClinicMembership.clinic.clinicStatus === 'Pending'
      )
    ) {
      return this.renderApprovalButtonPlaceholder();
    }
    return this.renderButton();
  }

}


const mapStateToProps = (state) => ({
  devices               : App.selectors.devices(state),
  activeProfileType     : Account.selectors.activeProfileType(state),
  activeClinicMembership: Account.selectors.activeClinicMembership(state),
});


const mapDispatchToProps = (dispatch) => ({
  onOpenModal: () => dispatch(App.actions.openModal(constants.DOWNLOAD_DATA_MODAL)),
});


const ConnectedDownloadDataFromMeterBtn = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DownloadDataFromMeterBtn);


export default withStyles(styles)(ConnectedDownloadDataFromMeterBtn);
