import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { AppContext } from 'context';
import Button from 'components/Form/Button';
import Account from 'modules/Account';
import Hcp from 'modules/Hcp';
import messages from '../../messages';
import styles from './DownloadDataModal.pcss';


class ChoosePatient extends React.PureComponent {

  static contextType = AppContext;


  static propTypes = {
    // Explicit props
    activeClinicMembership: Account.shapes.clinicMembership,
    // Explicit actions
    onSetComponent        : PropTypes.func,
    onCancel              : PropTypes.func,
  };

  renderPatientsHead() {
    return (
      <div className={styles.patients__head}>
        <div className="row align-items-center">
          <div className="col">
            <h3 className="modal__subheader "><FormattedMessage {...messages.choosePatient.listHeader} /></h3>
          </div>
          <div className="col text--right">
            <Button
              styleModifier="transparent"
              labelMessage={messages.buttons.addPatient}
              className="btn--no-size text--primary m-0"
              onClick={() => this.props.onSetComponent('AddPatient')}
            />
          </div>
        </div>
      </div>
    );
  }


  renderPatientsBody() {
    return (
      <div>
        <div className={styles.patients__searchContainer}>
          <Hcp.partials.SearchPatients forId="meterList" activeClinicMembership={this.props.activeClinicMembership} />
        </div>
        <div className={styles.patients__listContainer}>
          <Hcp.partials.ActivePatients
            id="meterList"
            activeClinicMembership={this.props.activeClinicMembership}
            styleModifier="halfSpace"
            isWithoutLoad
            onClick={() => this.props.onSetComponent('Results')}
          />
        </div>
      </div>
    );
  }

  renderButtons() {
    return (
      <Button
        styleModifier="primary"
        labelMessage={messages.buttons.cancelImport}
        className="btn--block mb-0"
        onClick={this.props.onCancel}
      />
    );
  }

  renderPatients() {
    return (
      <div className={styles.patients}>
        { this.renderPatientsHead() }
        { this.renderPatientsBody()}
      </div>
    );
  }


  render() {
    return (
      <div>
        <p className="modal__info"><FormattedMessage {...messages.choosePatient.info} /></p>
        { this.renderPatients() }
        {this.renderButtons()}
      </div>
    );
  }

}


export default ChoosePatient;
