import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import get from 'lodash/get';
import { AppContext } from 'context';
import copyTextToClipboardFallback from 'helpers/copyTextToClipboardFallback';
import Button from 'components/Form/Button';
import CopyIcon from 'svg/copy.svg';
import App from 'modules/App';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import messages from '../../messages';
import styles from './SccInit.pcss';


class InstallDownloader extends React.PureComponent {

  static contextType = AppContext;

  static propTypes = {
    // Explicit props
    activeClinicMembership: PropTypes.object,
    // Explicit actions
    onSetComponent        : PropTypes.func,
    // Implicit props
    machineId             : PropTypes.string,
    pairingCode           : PropTypes.string,
    locale                : PropTypes.string,
    // Implicit actions
    onPair                : PropTypes.func,
    onInstallDownloader   : PropTypes.func,
    onPairingCancel       : PropTypes.func,
  };


  constructor(props) {
    super(props);
    const organizationUID = get(props, 'activeClinicMembership.clinic.organizationUID');
    props.onPair(organizationUID);
  }


  componentWillUnmount() {
    this.props.onPairingCancel();
  }


  onCopyPairingCodeToClipboard() {
    const { pairingCode } = this.props;
    if (!navigator.clipboard) {
      copyTextToClipboardFallback(pairingCode);
      return;
    }
    navigator.clipboard.writeText(pairingCode);
  }


  onInstall() {
    // this.props.onSetComponent('InstallingDownloader');
    // this.props.onInstallDownloader();
  }


  get pairingCode() {
    const { machineId, pairingCode } = this.props;
    if (machineId) {
      return <FormattedMessage {...messages.labels.paired} />;
    }
    return `${pairingCode.slice(0, 3)} ${pairingCode.slice(3)}`;
  }


  renderSectionStep(stepNo) {
    return <h4 className="text--bold"><FormattedMessage {...App.messages.labels.step} /> { stepNo }</h4>;
  }


  renderDownloadStep() {
    const { locale } = this.props;
    const language = locale.slice(0, 2);
    const urlLocale = get(constants.SINOVO_LANGUAGES, language, 'EN');
    return (
      <div className="modal__section">
        { this.renderSectionStep(1) }
        <p className="modal__info"><FormattedMessage {...messages.sccInstall.downloadInfo} /></p>
        <Button
          type="link"
          to={`${this.context.apiUrl}/scc/SINOVOConnectionCenter-GCO-Setup-${urlLocale}.exe`}
          styleModifier="primary"
          labelMessage={messages.buttons.installScc}
          className="btn--block mb-0"
          data={{ download: true }}
          onClick={() => this.onInstall()}
        />
      </div>
    );
  }


  renderCopyPairingCodeToClipboard() {
    const { machineId } = this.props;
    if (machineId) {
      return (
        <span className="text--normal text--light text--small d-block py-3">
          <FormattedMessage {...messages.labels.continueToNextStep} />
        </span>
      );
    }
    return (
      <Button
        styleModifier="transparent"
        className="btn--sm"
        onClick={() => this.onCopyPairingCodeToClipboard()}
      >
        <span className="text--normal text--light text--small">
          <FormattedMessage {...App.messages.buttons.clickToCopy} />
        </span>
        <CopyIcon className={styles.copyIcon} />
      </Button>
    );
  }


  renderPairingCode() {
    const { machineId, pairingCode } = this.props;
    if (!machineId && !pairingCode) {
      return null;
    }
    return (
      <div className={styles.pairingCodeContainer}>
        <div className={styles.pairingCodeContainerInner}>
          <div>
            <p className={styles.pairingCode}>{ this.pairingCode }</p>
            <div>
              { this.renderCopyPairingCodeToClipboard() }
            </div>
          </div>
        </div>
      </div>
    );
  }


  renderPairingStep() {
    return (
      <div className="modal__section">
        { this.renderSectionStep(2) }
        <p className="modal__info">
          <FormattedMessage {...messages.sccInstall.pairingInfo} values={{ b: (chunk) => <b>{chunk}</b> }} />
        </p>
        <div className="row mt-6">
          <div className="col-6">
            { this.renderPairingCode() }
          </div>
          <div className="col-6">
            <img src="/assets/svg/sinovo-otp.svg" alt="" />
          </div>
        </div>
      </div>
    );
  }


  renderConnectionStep() {
    const { machineId } = this.props;
    const btnLabelMessage = messages.buttons[machineId ? 'downloadData' : 'waitingForConnection'];
    return (
      <div className="modal__section">
        { this.renderSectionStep(3) }
        <p className="modal__info"><FormattedMessage {...messages.sccInstall.connectionInfo} /></p>
        <Button
          styleModifier="primary"
          labelMessage={btnLabelMessage}
          className="btn--block btn--filled mb-0"
          isDisabled={!machineId}
          onClick={() => this.props.onSetComponent('SccWaiting')}
        />
      </div>
    );
  }


  render() {
    return (
      <div className={styles.modal__content}>
        <h3 className="modal__subheader "><FormattedMessage {...messages.sccInstall.header} /></h3>
        <div className="mt-6">
          { this.renderDownloadStep() }
          { this.renderPairingStep() }
          { this.renderConnectionStep() }
        </div>
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  machineId  : selectors.sccMachineId(state),
  pairingCode: selectors.sccPairingCode(state),
  locale     : App.selectors.locale(state),
});

const mapDispatchToProps = (dispatch) => ({
  onPair             : (organizationUID) => dispatch(actions.pairScc(organizationUID)),
  onInstallDownloader: () => dispatch(actions.installDownloader()),
  onPairingCancel    : () => dispatch(actions.pairSccCancel()),
});

const ConnectedInstallDownloader = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InstallDownloader);


export default withStyles(styles)(ConnectedInstallDownloader);
