import { defineMessages } from 'react-intl';


export default defineMessages({
  errors: {
    businessErrors: {
      UserOrPasswordIncorrect: { id: 'account.errors.businessErrors.UserOrPasswordIncorrect' },
      ContourCloudNotRespond : { id: 'account.errors.businessErrors.ContourCloudNotRespond' },
    },
  },
});
