import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import cn from 'classnames';
import Button from 'components/Form/Button';
import App from 'modules/App';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import * as shapes from '../../shapes';
import messages from '../../messages';
import styles from './DownloadDataModal.pcss';


class ConnectUsbCable extends React.PureComponent {


  static propTypes = {
    // Explicit props
    devicesByChannel         : shapes.devicesByChannel,
    caseTypes                : PropTypes.arrayOf(App.shapes.caseType),
    // Explicit actions
    onSetComponent           : PropTypes.func,
    // Implicit props
    connectionId             : PropTypes.string,
    // Implicit actions
    onConnect                : PropTypes.func,
    onStartCheckingConnection: PropTypes.func,
  };


  componentDidUpdate(prevProps) {
    const { connectionId } = this.props;
    if (prevProps.connectionId !== connectionId && connectionId) {
      this.props.onStartCheckingConnection(connectionId);
      this.props.onSetComponent('Downloading');
    }
  }


  renderDeviceIcon(caseType) {
    return (
      <li key={caseType.caseTypeId}>
        <figure
          className={styles.devices__icon}
          dangerouslySetInnerHTML={{ __html: caseType.icon }} // eslint-disable-line react/no-danger
        />
      </li>
    );
  }


  renderDevices() {
    const microUsbDevices = get(this.props.devicesByChannel, 'MicroUsb', []);
    const caseTypeIds = uniq(map(microUsbDevices, (device) => device.caseTypeId));
    const caseTypes = map(caseTypeIds, (caseTypeId) => find(this.props.caseTypes, { caseTypeId }));
    return (
      <ul className={styles.devices}>
        { map(caseTypes, (caseType) => this.renderDeviceIcon(caseType)) }
      </ul>
    );
  }


  render() {
    return (
      <div className={styles.modal__content}>
        <h3 className="modal__subheader "><FormattedMessage {...messages.headers.howToStartConnection} /></h3>
        <p className="modal__info"><FormattedMessage {...messages.connectUsbCable.info} /></p>
        { this.renderDevices() }
        <img src="/assets/svg/micro-usb.svg" className={styles.connectionIcon} alt="" />
        <img src="/assets/svg/laptop.svg" className={cn(styles.laptopIcon, 'mb-3')} alt="" />
        <div>
          <Button
            styleModifier="primary"
            labelMessage={messages.buttons.next}
            className="btn--block btn--filled mb-0"
            onClick={this.props.onConnect}
          />
        </div>
        <div className={styles.divider}>
          <span className={styles.divider__text}><FormattedMessage {...messages.infos.or} /></span>
        </div>
        <Button
          styleModifier="transparent"
          labelMessage={messages.buttons.changeDeviceType}
          className={cn('btn--no-size text--primary m-0', styles.button__changeDevice)}
          onClick={() => this.props.onSetComponent('ChooseConnection')}
        />
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  connectionId: selectors.connectionId(state),
});

const mapDispatchToProps = (dispatch) => ({
  onConnect                : () => dispatch(actions.connectUsb()),
  onStartCheckingConnection: (connectionId) => dispatch(actions.startCheckingConnection(connectionId)),
});

const ConnectedConnectUsbCable = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectUsbCable);


export default ConnectedConnectUsbCable;
