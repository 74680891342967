import { MODULE_ID } from './constants';


export const CHECK_STATUS = `${MODULE_ID}/CHECK_STATUS`;
export const CHECK_STATUS_SUCCESS = `${MODULE_ID}/CHECK_STATUS_SUCCESS`;
export const CHECK_STATUS_ERROR = `${MODULE_ID}/CHECK_STATUS_ERROR`;

export const INSTALL_DOWNLOADER = `${MODULE_ID}/INSTALL_DOWNLOADER`;
export const INSTALL_DOWNLOADER_SUCCESS = `${MODULE_ID}/INSTALL_DOWNLOADER_SUCCESS`;
export const INSTALL_DOWNLOADER_TIMEOUT = `${MODULE_ID}/INSTALL_DOWNLOADER_TIMEOUT`;
export const INSTALL_DOWNLOADER_CANCEL = `${MODULE_ID}/INSTALL_DOWNLOADER_CANCEL`;

export const UPDATE_DOWNLOADER = `${MODULE_ID}/UPDATE_DOWNLOADER`;
export const UPDATE_DOWNLOADER_SUCCESS = `${MODULE_ID}/UPDATE_DOWNLOADER_SUCCESS`;
export const UPDATE_DOWNLOADER_ERROR = `${MODULE_ID}/UPDATE_DOWNLOADER_ERROR`;
export const UPDATE_DOWNLOADER_ENDPOINT_NOT_FOUND_ERROR = `${MODULE_ID}/UPDATE_DOWNLOADER_ENDPOINT_NOT_FOUND_ERROR`;

export const CHECKING_CONNECTION_START = `${MODULE_ID}/CHECKING_CONNECTION_START`;
export const CHECKING_CONNECTION_SET = `${MODULE_ID}/CHECKING_CONNECTION_SET`;
export const CHECKING_CONNECTION_STOP = `${MODULE_ID}/CHECKING_CONNECTION_STOP`;
export const CHECKING_CONNECTION_ERROR = `${MODULE_ID}/CHECKING_CONNECTION_ERROR`;

export const GET_DEVICE_DATA = `${MODULE_ID}/GET_DEVICE_DATA`;
export const GET_DEVICE_DATA_SUCCESS = `${MODULE_ID}/GET_DEVICE_DATA_SUCCESS`;
export const GET_DEVICE_DATA_ERROR = `${MODULE_ID}/GET_DEVICE_DATA_ERROR`;
export const SET_DEVICE_DATA_FOR_PREVIEW = `${MODULE_ID}/SET_DEVICE_DATA_FOR_PREVIEW`;

export const CHECK_BLUE_CABLE_DRIVER = `${MODULE_ID}/CHECK_BLUE_CABLE_DRIVER`;
export const CHECK_BLUE_CABLE_DRIVER_SUCCESS = `${MODULE_ID}/CHECK_BLUE_CABLE_DRIVER_SUCCESS`;
export const CHECK_BLUE_CABLE_DRIVER_ERROR = `${MODULE_ID}/CHECK_BLUE_CABLE_DRIVER_ERROR`;

export const INSTALL_BLUE_CABLE_DRIVER = `${MODULE_ID}/INSTALL_BLUE_CABLE_DRIVER`;
export const INSTALL_BLUE_CABLE_DRIVER_STOP = `${MODULE_ID}/INSTALL_BLUE_CABLE_DRIVER_STOP`;

export const LISTENING_BLUETOOTH_START = `${MODULE_ID}/LISTENING_BLUETOOTH_START`;
export const LISTENING_BLUETOOTH_STOP = `${MODULE_ID}/LISTENING_BLUETOOTH_STOP`;
export const LISTENING_BLUETOOTH_ERROR = `${MODULE_ID}/LISTENING_BLUETOOTH_ERROR`;

export const SET_AVAILABLE_DEVICES = `${MODULE_ID}/SET_AVAILABLE_DEVICES`;

export const SEND_BLUETOOTH_PIN = `${MODULE_ID}/SEND_BLUETOOTH_PIN`;
export const SEND_BLUETOOTH_PIN_SUCCESS = `${MODULE_ID}/SEND_BLUETOOTH_PIN_SUCCESS`;
export const SEND_BLUETOOTH_PIN_ERROR = `${MODULE_ID}/SEND_BLUETOOTH_PIN_ERROR`;

// generic type for reducer actions' register, don't use it for action dispatch
export const CONNECT = `${MODULE_ID}/CONNECT`;
export const CONNECT_BLUE_CABLE = `${MODULE_ID}/CONNECT_BLUE_CABLE`;
export const CONNECT_BLUETOOTH = `${MODULE_ID}/CONNECT_BLUETOOTH`;
export const CONNECT_USB = `${MODULE_ID}/CONNECT_USB`;
export const CONNECT_USB_C = `${MODULE_ID}/CONNECT_USB_C`;
export const CONNECT_SCC = `${MODULE_ID}/CONNECT_SCC`;

export const CONNECT_SUCCESS = `${MODULE_ID}/CONNECT_SUCCESS`;
export const CONNECT_ERROR = `${MODULE_ID}/CONNECT_ERROR`;

// SCC -----------------------------------------------------------------------------------------------------------------

export const CHECK_SCC_STATUS = `${MODULE_ID}/CHECK_SCC_STATUS`;
export const CHECK_SCC_STATUS_SUCCESS = `${MODULE_ID}/CHECK_SCC_STATUS_SUCCESS`;
export const CHECK_SCC_STATUS_ERROR = `${MODULE_ID}/CHECK_SCC_STATUS_ERROR`;

export const PAIR_SCC = `${MODULE_ID}/PAIR_SCC`;
export const PAIR_SCC_SET_CODE = `${MODULE_ID}/PAIR_SCC_SET_CODE`;
export const PAIR_SCC_SUCCESS = `${MODULE_ID}/PAIR_SCC_SUCCESS`;
export const PAIR_SCC_CANCEL = `${MODULE_ID}/PAIR_SCC_CANCEL`;
export const PAIR_SCC_ERROR = `${MODULE_ID}/PAIR_SCC_ERROR`;

export const RESET_SCC = `${MODULE_ID}/RESET_SCC`;

//----------------------------------------------------------------------------------------------------------------------

export const GET_SERIAL_NUMBER_TOKEN = `${MODULE_ID}/GET_SERIAL_NUMBER_TOKEN`;
export const GET_SERIAL_NUMBER_TOKEN_SUCCESS = `${MODULE_ID}/GET_SERIAL_NUMBER_TOKEN_SUCCESS`;
export const GET_SERIAL_NUMBER_TOKEN_ERROR = `${MODULE_ID}/GET_SERIAL_NUMBER_TOKEN_ERROR`;

export const LOG_EVENT = `${MODULE_ID}/LOG_EVENT`;
