import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import differenceBy from 'lodash/differenceBy';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import Button from 'components/Form/Button';
import App from 'modules/App';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import * as shapes from '../../shapes';
import messages from '../../messages';
import styles from './DownloadDataModal.pcss';


class ConnectBluetooth extends React.PureComponent {

  static getDerivedStateFromProps(props, state) {
    const { devices } = props.downloader;
    if (state.discoveredDevices !== devices) {
      if (devices.length) {
        let { activeInstruction } = state;
        const newDiscoveredDevices = differenceBy(devices, state.discoveredDevices, 'name');
        const newDiscoveredDevice = head(newDiscoveredDevices);
        if (newDiscoveredDevice) {
          const { sku } = newDiscoveredDevice;
          const bluetoothDevices = get(props.devicesByChannel, 'Bluetooth', []);
          const device = find(bluetoothDevices, { sku });
          if (!device) return null;
          const { caseTypeId } = device;
          const caseType = find(props.caseTypes, { caseTypeId });
          if (!caseType) return null;
          switch (caseType.name) {
            case 'Round3BtnOKBt':
            case 'Round3BtnOkBtLight': {
              activeInstruction = 1;
              break;
            }
            default: {
              activeInstruction = 2;
            }
          }
          return {
            discoveredDevices: devices,
            activeInstruction,
          };
        }
      } else if (state.discoveredDevices.length) {
        return {
          discoveredDevices: [],
        };
      }
    }
    return null;
  }


  static propTypes = {
    // Explicit props
    downloader               : shapes.downloader,
    devicesByChannel         : shapes.devicesByChannel,
    caseTypes                : PropTypes.arrayOf(App.shapes.caseType),
    // Explicit actions
    onSetComponent           : PropTypes.func,
    // Implicit props
    connectionId             : PropTypes.string,
    // Implicit actions
    onConnect                : PropTypes.func,
    onStartListeningBluetooth: PropTypes.func,
    onStopListeningBluetooth : PropTypes.func,
    onStartCheckingConnection: PropTypes.func,
    onSetFormValues          : PropTypes.func,
    onSetFormContext         : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.state = {
      discoveredDevices: [],
      activeInstruction: 1,
    };
  }


  componentDidMount() {
    this.props.onStartListeningBluetooth();
  }


  componentDidUpdate(prevProps) {
    const { connectionId } = this.props;
    if (prevProps.connectionId !== connectionId && connectionId) {
      this.props.onStartCheckingConnection(connectionId);
      this.props.onSetComponent('Downloading');
    }
  }


  componentWillUnmount() {
    this.setState({
      discoveredDevices: [],
      activeInstruction: 1,
    });
  }


  onDownload(device) {
    if (!device.isPaired && device.requiresPin) {
      this.props.onSetFormContext(constants.BLUETOOTH_PIN_FORM, { device });
      this.props.onSetFormValues(constants.BLUETOOTH_PIN_FORM, { deviceId: device.id });
      this.props.onSetComponent('BluetoothPairingInstruction');
      return;
    }
    this.props.onConnect(device);
  }


  onSetChooseConnection() {
    this.props.onStopListeningBluetooth();
    this.props.onSetComponent('ChooseConnection');
  }


  getCaseType(device) {
    if (!device) {
      return {};
    }
    const { caseTypeId } = device;
    return find(this.props.caseTypes, { caseTypeId });
  }


  renderInstructionsButtons() {
    return (
      <ul className={`list--unstyled ${styles.instructionsButtons}`}>
        <li className={styles.instructionButton}>
          <button
            type="button"
            className={styles.instructionButton__btn}
            onClick={() => this.setState({ activeInstruction: 1 })}
          >
            <span
              className={
                cn(
                  styles.instructionButton__imageContainer,
                  { [styles['instructionButton__imageContainer--active']]: this.state.activeInstruction === 1 },
                )
              }
            >
              <img src="/assets/svg/type1.svg" alt="" />
            </span>
          </button>
        </li>
        <li className={styles.instructionButton}>
          <button
            type="button"
            className={styles.instructionButton__btn}
            onClick={() => this.setState({ activeInstruction: 2 })}
          >
            <span
              className={
                cn(
                  styles.instructionButton__imageContainer,
                  { [styles['instructionButton__imageContainer--active']]: this.state.activeInstruction === 2 },
                )
              }
            >
              <img src="/assets/svg/type2.svg" alt="" />
            </span>
          </button>
        </li>
      </ul>
    );
  }


  renderInstructionRound3BtnOKBt() {
    return (
      <div>
        <ol className={`${styles.howTo__list} row`}>
          <li className={`${styles.howTo__list__item} col-6`}>
            <div className={styles.howTo__list__item__content}>
              <p><FormattedMessage {...messages.connectBluetooth.round3BtnOKBt.first} /></p>
              <img src="/assets/svg/Round3BtnOKBt-ok.svg" className={styles.instruction__image} alt="" />
            </div>
          </li>
          <li className={`${styles.howTo__list__item} col-6`}>
            <div className={styles.howTo__list__item__content}>
              <p><FormattedMessage {...messages.connectBluetooth.round3BtnOKBt.second} /></p>
              <img src="/assets/svg/Round3BtnOKBt-up.svg" className={styles.instruction__image} alt="" />
            </div>
          </li>
          <li className={`${styles.howTo__list__item} col-6`}>
            <div className={styles.howTo__list__item__content}>
              <p><FormattedMessage {...messages.connectBluetooth.round3BtnOKBt.third} /></p>
            </div>
          </li>
        </ol>
      </div>
    );
  }


  renderInstructionLongLight() {
    return (
      <div>
        <ol className={`${styles.howTo__list} row`}>
          <li className={`${styles.howTo__list__item} col-6`}>
            <div className={styles.howTo__list__item__content}>
              <p><FormattedMessage {...messages.connectBluetooth.longLight.first} /></p>
              <img src="/assets/svg/LongLight-off.svg" className={styles.instruction__image} alt="" />
            </div>
          </li>
          <li className={`${styles.howTo__list__item} col-6`}>
            <div className={styles.howTo__list__item__content}>
              <p><FormattedMessage {...messages.connectBluetooth.longLight.second} /></p>
              <img src="/assets/svg/LongLight-on.svg" className={styles.instruction__image} alt="" />
            </div>
          </li>
          <li className={`${styles.howTo__list__item} col-6`}>
            <div className={styles.howTo__list__item__content}>
              <p><FormattedMessage {...messages.connectBluetooth.longLight.third} /></p>
            </div>
          </li>
        </ol>
      </div>
    );
  }


  renderInstructions() {
    let instruction;
    switch (this.state.activeInstruction) {
      case 1: {
        instruction = this.renderInstructionRound3BtnOKBt();
        break;
      }
      case 2: {
        instruction = this.renderInstructionLongLight();
        break;
      }
      default: {
        instruction = null;
      }
    }

    return (
      <div className="pr-4">
        <p className="modal__info"><FormattedMessage {...messages.connectBluetooth.showInstructionsFor} /></p>
        { this.renderInstructionsButtons() }
        <h3 className="modal__subheader mb-3">
          <FormattedMessage {...messages.connectBluetooth.howToStartConnection} />
        </h3>
        { instruction }
      </div>
    );
  }


  renderMeter(discoveredDevice) {
    const { sku, isPaired } = discoveredDevice;
    const bluetoothDevices = get(this.props.devicesByChannel, 'Bluetooth', []);
    const device = find(bluetoothDevices, { sku });
    const caseType = this.getCaseType(device);
    const { icon } = caseType;
    const pairedTagMessage = isPaired
      ? messages.connectBluetooth.tags.paired
      : messages.connectBluetooth.tags.unpaired;
    const buttonMessage = isPaired
      ? messages.buttons.download
      : messages.buttons.pairAndDownload;
    return (
      <li key={discoveredDevice.id} className={styles.meters__list__item}>
        <figure
          className={`${styles.meters__list__item__fig} col-2`}
          dangerouslySetInnerHTML={{ __html: icon }} // eslint-disable-line react/no-danger
        />
        <div className={`col ${styles.meters__list__item__name}`}>
          { discoveredDevice.name }
          <div>
            <span className={cn('tag mt-2', { 'tag--success': isPaired })}>
              <FormattedMessage {...pairedTagMessage} />
            </span>
          </div>
        </div>
        <div className="col-auto pr-0">
          <Button
            styleModifier="primary"
            labelMessage={buttonMessage}
            className="btn--sm"
            onClick={() => this.onDownload(discoveredDevice)}
          />
        </div>
      </li>
    );
  }


  renderMetersList() {
    const { discoveredDevices } = this.state;
    if (isEmpty(discoveredDevices)) {
      return (
        <div className={styles.meters__noMeters}>
          <p className={styles.meters__noMeters__info}><FormattedMessage {...messages.connectBluetooth.noMeters} /></p>
        </div>
      );
    }

    return (
      <ul className={styles.meters__list}>
        { map(discoveredDevices, (device) => this.renderMeter(device)) }
      </ul>
    );
  }


  renderMeters() {
    return (
      <div className="h-100">
        <h3 className="modal__info "><FormattedMessage {...messages.connectBluetooth.metersWithBluetooth} /></h3>
        <div className={styles.meters}>
          { this.renderMetersList() }
        </div>
      </div>
    );
  }


  render() {
    return (
      <div>
        <div className="row">
          <div className="col-7">
            { this.renderInstructions() }
          </div>
          <div className="col-5">
            { this.renderMeters() }
          </div>
        </div>
        <div className={styles.divider}>
          <span className={styles.divider__text}><FormattedMessage {...messages.infos.or} /></span>
        </div>
        <div className="text--center">
          <Button
            styleModifier="transparent"
            labelMessage={messages.buttons.changeDeviceType}
            className="btn--no-size text--primary m-0"
            onClick={() => this.onSetChooseConnection()}
          />
        </div>
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  connectionId: selectors.connectionId(state),
});

const mapDispatchToProps = (dispatch) => ({
  onConnect                : (device) => dispatch(actions.connectBluetooth(device)),
  onStartListeningBluetooth: () => dispatch(actions.startListeningBluetooth()),
  onStopListeningBluetooth : () => dispatch(actions.stopListeningBluetooth()),
  onStartCheckingConnection: (connectionId) => dispatch(actions.startCheckingConnection(connectionId)),
  onSetFormValues          : (formName, values) => dispatch(App.actions.setFormValues(formName, values)),
  onSetFormContext         : (formName, contextData) => dispatch(App.actions.setFormContext(formName, contextData)),
});

const ConnectedConnectBluetooth = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectBluetooth);


export default ConnectedConnectBluetooth;
