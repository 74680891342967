import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import moment from 'moment';
import CheckboxRadio from 'components/Form/CheckboxRadio';
import Chevron from 'svg/chevron-right.svg';
import messages from '../../../messages';
import styles from './ResultsContentCgm.pcss';


class Transmitter extends React.PureComponent {

  constructor() {
    super();
    this.state = {
      expand: false,
    };
  }


  static propTypes = {
    // Explicit props
    transmitter      : PropTypes.object,
    active           : PropTypes.bool,
    // Explicit actions
    toggleTransmitter: PropTypes.func,
  };


  onExpand(e) {
    e.stopPropagation();
    this.setState((state) => ({ expand: !state.expand }));
  }


  render() {
    const { transmitter } = this.props;
    return (
      <div
        className={styles.transmitter}
        onClick={() => this.props.toggleTransmitter(transmitter.serialNumber)}
        role="presentation"
      >
        <div className="transmitter__row">
          <div className="transmitter__checkbox">
            <CheckboxRadio
              inputValue="true"
              value={this.props.active ? 'true' : 'false'}
              onChange={() => this.props.toggleTransmitter(transmitter.serialNumber)}
            />
          </div>
          <div className="transmitter__image">
            <img alt="" src="/assets/svg/cgm-transmitter.svg" className="transmitter__infoIcon" />
          </div>
          <div className="transmitter__serial">
            {transmitter.serialNumber}
          </div>
          <p className="transmitter__range">
            {moment.unix(transmitter.summary.minTimestamp).format('Mo MMM')}
            {' - '}
            {moment.unix(transmitter.summary.maxTimestamp).format('Mo MMM')}
          </p>
          <motion.button
            type="button"
            className="transmitter__expand"
            onClick={(e) => this.onExpand(e)}
            animate={
              this.state.expand
                ? {
                  rotate: '180deg',
                } : {
                  rotate: '0deg',
                }
            }
          >
            <Chevron />
          </motion.button>
        </div>
        <motion.div
          className="transmitter__row"
          animate={
            this.state.expand
              ? {
                height : 'auto',
                opacity: 1,
              } : {
                height : '0',
                opacity: 0,
              }
          }
        >
          <div className="transmitter__measurements">
            <p className="text--small text--light mt-3 mb-2 featureTitle">
              <FormattedMessage {...messages.labels.measurements} />
            </p>
            <p className="feature__countItems">
              <span className="feature__countItem"><i className="bg--gray feature__countDisc" />{ 0 }</span>
              <span className="feature__countItem"><i className="bg--warning feature__countDisc" />{ transmitter.summary.lowCount }</span>
              <span className="feature__countItem"><i className="bg--success feature__countDisc" />{ transmitter.summary.targetCount }</span>
              <span className="feature__countItem"><i className="bg--error feature__countDisc" />{ transmitter.summary.highCount }</span>
            </p>
          </div>
          <div className="transmitter__type">
            <p className="text--small text--light mt-3 mb-2 featureTitle">
              <FormattedMessage {...messages.labels.measurementsType} />
            </p>
            <p className="d-flex align-items-center text--large mb-3">
              -
            </p>
          </div>
        </motion.div>
      </div>
    );
  }

}

export default withStyles(styles)(Transmitter);
