export const detectBrowser = () => {
  if (!process.env.BROWSER) return 'Server';

  const agent = window.navigator.userAgent.toLowerCase();
  switch (true) {
    case agent.indexOf('edge') > -1: return 'Edge';
    case agent.indexOf('edg/') > -1: return 'EdgeChromium';
    case agent.indexOf('opr') > -1 && !!window.opr: return 'Opera';
    case agent.indexOf('chrome') > -1 && !!window.chrome: return 'Chrome';
    case agent.indexOf('trident') > -1: return 'IE';
    case agent.indexOf('firefox') > -1: return 'Firefox';
    case agent.indexOf('safari') > -1: return 'Safari';
    default: return 'Unknown';
  }
};
