import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Form/Button';
import messages from '../../messages';
import styles from './DownloadDataModal.pcss';


class InstalledBlueCableDrivers extends React.PureComponent {


  static propTypes = {
    // Explicit actions
    onSetComponent: PropTypes.func,
  };


  render() {
    return (
      <div className={styles.modal__content}>
        <h3 className="modal__subheader "><FormattedMessage {...messages.installedBlueCableDrivers.header} /></h3>
        <div className={styles.illustration}>
          <img src="/assets/svg/tick.svg" alt="" />
        </div>
        <div className="modal__actions">
          <Button
            styleModifier="primary"
            labelMessage={messages.buttons.continueToDownload}
            className="btn--block btn--filled mb-0"
            onClick={() => this.props.onSetComponent('ConnectBlueCable')}
          />
        </div>
      </div>
    );
  }

}


export default InstalledBlueCableDrivers;
