import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { AppContext } from 'context';
import Button from 'components/Form/Button';
import App from 'modules/App';
import * as actions from '../../actions';
import * as constants from '../../constants';
import messages from '../../messages';
import styles from '../../components/DownloadDataModal/DownloadDataModal.pcss';


class InstallDownloader extends React.PureComponent {

  static contextType = AppContext;

  static propTypes = {
    // Explicit actions
    onSetComponent     : PropTypes.func,
    // Implicit props
    openModalId        : PropTypes.string,
    // Implicit actions
    onLogEvent         : PropTypes.func,
    onInstallDownloader: PropTypes.func,
  };


  componentDidMount() {
    if (this.isOpen) {
      this.props.onLogEvent();
    }
  }


  onInstall() {
    this.props.onSetComponent('InstallingDownloader');
    this.props.onInstallDownloader();
  }


  get isOpen() {
    return this.props.openModalId === constants.DOWNLOAD_DATA_MODAL;
  }


  render() {
    return (
      <div className={styles.modal__content}>
        <h3 className="modal__subheader "><FormattedMessage {...messages.installDownloader.header} /></h3>
        <p className="modal__info"><FormattedMessage {...messages.installDownloader.info} /></p>
        <div className={styles.illustration}>
          <img src="/assets/img/tray.png" alt="" />
        </div>
        <Button
          type="link"
          to={this.context.downloader.installUrl}
          styleModifier="primary"
          labelMessage={messages.buttons.installDownloader}
          className="btn--block btn--filled mb-0"
          onClick={() => this.onInstall()}
        />
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  openModalId: App.selectors.modal(state),
});

const mapDispatchToProps = (dispatch) => ({
  onLogEvent         : () => dispatch(actions.logEvent('InstallationPromptDisplayed')),
  onInstallDownloader: () => dispatch(actions.installDownloader()),
});

const ConnectedInstallDownloader = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InstallDownloader);


export default ConnectedInstallDownloader;
