import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import * as shapes from '../../shapes';
import messages from '../../messages';
import styles from './DownloadDataModal.pcss';


class SccWaiting extends React.PureComponent {

  static propTypes = {
    // Explicit props
    activeClinicMembership   : PropTypes.object,
    // Explicit actions
    onCancel                 : PropTypes.func,
    onSetComponent           : PropTypes.func,
    // Implicit props
    connectionId             : PropTypes.string,
    connectionStatus         : shapes.connectionStatus,
    // Implicit actions
    onConnect                : PropTypes.func,
    onStartCheckingConnection: PropTypes.func,
  };


  componentDidMount() {
    const organizationUID = get(this.props, 'activeClinicMembership.clinic.organizationUID');
    this.props.onConnect(organizationUID);
  }


  componentDidUpdate(prevProps) {
    const { connectionId, connectionStatus } = this.props;
    if (prevProps.connectionId !== connectionId || prevProps.connectionStatus !== connectionStatus) {
      if (connectionStatus === constants.CONNECTION_STATUSES.INITIALIZED) {
        this.props.onStartCheckingConnection(connectionId);
        return;
      }
      if (connectionStatus === constants.CONNECTION_STATUSES.CANCELED) {
        this.props.onCancel();
        return;
      }
      if (connectionStatus === constants.CONNECTION_STATUSES.TIMEOUT) {
        this.props.onSetComponent('Timeout');
        return;
      }
      if (
        connectionStatus === constants.CONNECTION_STATUSES.IN_PROGRESS
        || connectionStatus === constants.CONNECTION_STATUSES.SUCCESS
      ) {
        this.props.onSetComponent('Downloading');
      }
    }
  }


  render() {
    return (
      <div className={styles.modal__content}>
        <h3 className="modal__subheader "><FormattedMessage {...messages.sccDownload.header} /></h3>
        <div className={styles.illustration}>
          <img className={styles.centralImage} src="/assets/svg/data.svg" alt="" />
        </div>
        <p className="modal__info text--center mb-5"><FormattedMessage {...messages.sccDownload.waitingInfo} /></p>
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  connectionId    : selectors.connectionId(state),
  connectionStatus: selectors.connectionStatus(state),
});


const mapDispatchToProps = (dispatch) => ({
  onConnect                : (organizationUID) => dispatch(actions.connectScc(organizationUID)),
  onStartCheckingConnection: (connectionId) => dispatch(
    actions.startCheckingConnection(connectionId, constants.CONNECTOR_TYPES.SCC),
  ),
});


const ConnectedSccWaiting = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SccWaiting);


export default ConnectedSccWaiting;
