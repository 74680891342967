import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Form/Button';
import * as constants from '../../constants';
import * as shapes from '../../shapes';
import messages from '../../messages';
import styles from './DownloadDataModal.pcss';


class Timeout extends React.PureComponent {


  static propTypes = {
    // Explicit props
    status        : shapes.status,
    downloader    : shapes.downloader,
    connectorType : shapes.connectorType,
    // Explicit actions
    onSetComponent: PropTypes.func,
  };


  render() {
    const { status, downloader, connectorType } = this.props;
    let toComponent = 'ChooseConnection';
    if (connectorType === constants.CONNECTOR_TYPES.SCC) {
      toComponent = 'Initializing';
    } else if (status !== constants.DOWNLOADER_STATUSES.READY) {
      if (downloader.mdaVersion) {
        toComponent = 'NoConnection';
      } else {
        toComponent = 'InstallDownloader';
      }
    }

    return (
      <div className={styles.modal__content}>
        <h3 className="modal__subheader "><FormattedMessage {...messages.timeout.header} /></h3>
        <div className={styles.illustration}>
          <img src="/assets/svg/timeout.svg" alt="" />
        </div>
        <div className="modal__actions">
          <Button
            styleModifier="primary"
            labelMessage={messages.buttons.tryAgain}
            className="btn--block btn--filled"
            onClick={() => this.props.onSetComponent(toComponent)}
          />
        </div>
      </div>
    );
  }

}


export default Timeout;
