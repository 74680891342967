import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ActiveNotingLabel from 'components/ActiveNotingLabel';
import messages from '../../../messages';
import styles from './Cells.pcss';


class SummaryCell extends React.PureComponent {

    static propTypes = {
      // Explicit props
      readingsCount      : PropTypes.number,
      daysWithReadings   : PropTypes.number,
      daysWithoutReadings: PropTypes.number,
      // Explicit actions
      onAddNote          : PropTypes.func,
    };


    onClick(evt, noteType, value, titleMessage) {
      evt.preventDefault();
      evt.stopPropagation();
      if (!this.props.onAddNote) {
        return;
      }
      const status = this.getStatus(noteType, value);
      this.props.onAddNote(noteType, { value, status, titleMessage });
    }


    get daysWithoutReadingsClass() {
      const { daysWithoutReadings } = this.props;

      if (daysWithoutReadings === 0) return styles.kpiCell__valuePositive;
      return styles.kpiCell__valueNegative;
    }


    getStatus(noteType, value) {
      if (noteType === 'DaysWithoutReading') {
        return value ? 'low' : 'target';
      }
      return undefined;
    }


    renderActiveNotingLabel() {
      if (!this.props.onAddNote) {
        return null;
      }
      return <ActiveNotingLabel labelMessage={messages.noting.clickItem} />;
    }


    render() {
      const { readingsCount, daysWithReadings, daysWithoutReadings } = this.props;
      const avgReadings = (daysWithReadings + daysWithoutReadings) === 0
        ? 0
        : (readingsCount / (daysWithReadings + daysWithoutReadings)).toFixed(2);

      return (
        <div className={cn(styles.kpiCell, styles.kpiCellSummary)}>
          { this.renderActiveNotingLabel() }
          <a
            href=""
            className={cn(styles.kpiCellSummary__noting, { activeNoting: this.props.onAddNote })}
            onClick={(evt) => this.onClick(evt, 'TotalReadings', readingsCount, messages.dailyReport.readings)}
          >
            <div className={styles.kpiCellSummary__row}>
              <span className={styles.kpiCellSummary__title}>
                <FormattedMessage {...messages.dailyReport.readings} />
              </span>
              <span className={styles.kpiCellSummary__value}>{readingsCount}</span>
            </div>
          </a>
          <a
            href=""
            className={cn(styles.kpiCellSummary__noting, { activeNoting: this.props.onAddNote })}
            onClick={(evt) => this.onClick(evt, 'AvgReadings', avgReadings, messages.dailyReport.averageDay)}
          >
            <div className={styles.kpiCellSummary__row}>
              <span className={styles.kpiCellSummary__title}>
                <FormattedMessage {...messages.dailyReport.averageDay} />
              </span>
              <span className={styles.kpiCellSummary__value}>{avgReadings}</span>
            </div>
          </a>
          <a
            href=""
            className={cn(styles.kpiCellSummary__noting, { activeNoting: this.props.onAddNote })}
            onClick={(evt) => this.onClick(evt, 'DaysWithoutReading', daysWithoutReadings, messages.dailyReport.daysWithoutReading)}
          >
            <div className={styles.kpiCellSummary__row}>
              <span className={styles.kpiCellSummary__title}>
                <FormattedMessage {...messages.dailyReport.daysWithoutReading} />
              </span>
              <span className={styles.kpiCellSummary__value}>
                <span className={this.daysWithoutReadingsClass}>
                  {daysWithoutReadings}
                </span>
              </span>
            </div>
          </a>
        </div>
      );
    }

}

export default withStyles(styles)(SummaryCell);
