import get from 'lodash/get';
import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';
import App from 'modules/App';


export function getTargetRangeForPhi(targetRanges) {

  if (!targetRanges) {
    return {};
  }

  return {
    preMeal: {
      lowThreshold : targetRanges.glucosePreMealLow,
      highThreshold: targetRanges.glucosePreMealHigh,
    },
    postMeal: {
      lowThreshold : targetRanges.glucosePostMealLow,
      highThreshold: targetRanges.glucosePostMealHigh,
    },
    critical: {
      lowThreshold : targetRanges.glucoseCriticalLow,
      highThreshold: targetRanges.glucoseCriticalHigh,
    },
  };
}


function removeEmptyRange(standard, range) {
  if (standard && standard[range]) {
    Object.keys(standard[range]).forEach(
      (k) => (!standard[range][k] && standard[range][k] !== undefined) && delete standard[range][k]
    );
  }
}


function standardMerge(newStandards, baseStandards) {
  let baseCopy = cloneDeep(baseStandards);
  const newCopyCopy = cloneDeep(newStandards);
  baseCopy = merge(newCopyCopy, baseCopy);
  removeEmptyRange(baseCopy, 'preMeal');
  removeEmptyRange(baseCopy, 'postMeal');
  removeEmptyRange(baseCopy, 'critical');
  baseCopy.preMeal = merge(newCopyCopy.preMeal, baseCopy.preMeal);
  baseCopy.postMeal = merge(newCopyCopy.postMeal, baseCopy.postMeal);
  baseCopy.critical = merge(newCopyCopy.critical, baseCopy.critical);
  return baseCopy;
}


export function getStandards(phiSet, countrySettings, clinicSettings) {
  let standards = {};

  if (phiSet && phiSet.glucoseLevelTargets) {
    standards = phiSet.glucoseLevelTargets;
  }

  const diabetesType = get(phiSet, 'diabetesType');

  if (clinicSettings && clinicSettings.glucoseRanges && clinicSettings.glucoseRanges[diabetesType]) {
    standards = standardMerge(clinicSettings.glucoseRanges[diabetesType], standards);
  }
  if (countrySettings && countrySettings.glucoseRanges && countrySettings.glucoseRanges[diabetesType]) {
    standards = standardMerge(countrySettings.glucoseRanges[diabetesType], standards);
  }

  if (clinicSettings && clinicSettings.glucoseRanges && clinicSettings.glucoseRanges.Default) {
    standards = standardMerge(clinicSettings.glucoseRanges.Default, standards);
  }
  if (countrySettings && countrySettings.glucoseRanges && countrySettings.glucoseRanges.Default) {
    standards = standardMerge(countrySettings.glucoseRanges.Default, standards);
  }

  if (clinicSettings) {
    standards = standardMerge(getTargetRangeForPhi(clinicSettings), standards);
  }

  if (countrySettings) {
    standards = standardMerge(getTargetRangeForPhi(countrySettings), standards);
  }

  standards = standardMerge(App.constants.METRICS_STANDARDS.bloodGlucoseConcentration, standards);

  return standards;
}


export function getKpiSettingsByType(type, phiSet, clinicSettings) {
  let kpi = {};

  if (phiSet && phiSet.kpi && phiSet.kpi[type]) {
    kpi = phiSet.kpi[type];
  }

  const diabetesType = get(phiSet, 'diabetesType');

  if (clinicSettings && clinicSettings.kpi && clinicSettings.kpi[type] && clinicSettings.kpi[type][diabetesType]) {
    kpi = merge(cloneDeep(clinicSettings.kpi[type][diabetesType]), kpi);
  }

  if (clinicSettings && clinicSettings.kpi && clinicSettings.kpi[type]) {
    kpi = merge(cloneDeep(clinicSettings.kpi[type]), kpi);
  }

  return kpi;
}
