import * as actions from './actions';
import * as constants from './constants';
import * as selectors from './selectors';
import * as shapes from './shapes';
import reducer from './reducer';
import sagas from './sagas';
import messages from './messages';

import DownloadDataModal from './components/DownloadDataModal';

import DownloadDataFromMeterBtn from './partials/DownloadDataFromMeterBtn';
import LaunchDownloaderInstaller from './partials/LaunchDownloaderInstaller';
import MdaInit from './partials/MdaInit';


const components = {
  DownloadDataModal,
};


const partials = {
  DownloadDataFromMeterBtn,
  LaunchDownloaderInstaller,
  MdaInit,
};


export default {
  actions,
  components,
  constants,
  partials,
  reducer,
  sagas,
  selectors,
  shapes,
  messages,
};
