import queryString from 'query-string';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import pick from 'lodash/pick';
import isUndefined from 'lodash/isUndefined';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import { eventChannel } from 'redux-saga';
import { all, call, delay, put, race, select, take, takeEvery, takeLatest, getContext } from 'redux-saga/effects';
import moment from 'moment';
import { DEFAULT_LOCALE, APP_LOCALES, APP_LOCALE_LANGUAGES_MAP } from 'localeConstants';
import LocalStorage from 'libs/LocalStorage';
import appInsights from 'helpers/appInsights';
import { ApiError, LocalError } from 'helpers/errorTypes';
import gtm from 'helpers/gtm';
import hotjar from 'helpers/hotjar';
// import userback from 'helpers/userback';
import AlertService from 'services/AlertService';
import SignalRService from 'services/SignalRService';
import ApiService from 'services/ApiService';
import { signOut } from '../Account/actions';
import {
  account as accountSelector,
  settings as settingsSelector,
  activeProfileType as activeProfileTypeSelector,
} from '../Account/selectors';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import * as constants from './constants';
import * as selectors from './selectors';
import messages from './messages';


export function* dispatchError(error, moduleMessages = {}, alertDispatcher = null, isSilent = false) {
  const { status } = error;

  if (status === 401) {
    if (!isSilent) {
      yield put(signOut());
    }
    return;
  }

  if (status === 403) {
    if (!isSilent) {
      yield put(actions.goToAid('app-catalog', null));
    }
    return;
  }

  const isApiError = error instanceof ApiError;
  const businessError = error.getBusinessError && error.getBusinessError();

  const errorAppInsightsProperties = {
    businessError,
    ...pick(error, ['status', 'options', 'url', 'validationErrors']),
  };

  appInsights.trackException(error, errorAppInsightsProperties);

  if (isSilent) {
    return;
  }

  const alert = yield (alertDispatcher || call(AlertService.createAlertDispatcher));
  let message = messages.alerts.genericError;
  let messageValues = { email: constants.CONTACT_EMAIL };

  if (status === 500 || (isApiError && !businessError && !error.validationErrors)) {
    if (error.requestId) {
      message = messages.alerts.error5xxWithRequestId;
      messageValues.requestId = error.requestId;
    } else {
      message = messages.alerts.error5xx;
    }
    yield alert.error(message, messageValues);
    return;
  }

  if (businessError) {
    const { code, params, actions: errorActions } = businessError;
    // Error intl message path convention must be implemented in every module
    const messagePath = ['errors', 'businessErrors', code];
    const moduleMessage = get(moduleMessages, messagePath) || get(messages, messagePath);
    if (moduleMessage) {
      message = moduleMessage;
      messageValues = {
        ...messageValues,
        ...params,
      };
    }
    yield alert.error(message, messageValues, errorActions);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* findRegionByName(name) {
  const regions = yield select(selectors.regions);
  const region = find(regions, { name });
  if (!region) {
    throw new LocalError({ code: 'InvalidRegion' });
  }
  return region;
}


function* setRegion({ payload }) {
  try {
    const { regionName } = payload;
    yield call(findRegionByName, regionName);
    yield put(actions.setRegionSuccess(regionName));
    if (process.env.BROWSER) {
      const cookies = yield getContext('cookies');
      cookies.set('region', regionName, { path: '/', expires: new Date('2040-12-31') });
    }
  } catch (err) {
    yield put(actions.setRegionError(err));
    yield call(dispatchError, err, messages);
  }
}


function* setLocale({ payload }) {
  try {
    const { locale } = payload;
    if (locale === 'en-hk' && moment.locales().findIndex((l) => l === 'en-hk') < 0) {
      moment.defineLocale(locale, {
        parentLocale: 'en-gb',
      });
    }
    const accountSettings = yield select(settingsSelector);
    if (accountSettings) {
      moment.defineLocale('en--account', {
        parentLocale: 'en',
        week        : {
          dow: accountSettings.firstDayOfWeek,
        },
      });
      if (locale !== 'en') {
        moment.defineLocale(`${locale}--account`, {
          parentLocale: locale,
          week        : {
            dow: accountSettings.firstDayOfWeek,
          },
        });
        moment.locale(`${locale}--account`);
      } else {
        moment.locale('en--account');
      }
    } else if (moment.locale() !== locale) {
      moment.locale(locale);
    }
    if (!process.env.BROWSER) {
      return;
    }
    const cookies = yield getContext('cookies');
    const langId = cookies.get('langId');
    if (langId === locale) {
      return;
    }
    cookies.set('langId', locale, { path: '/', expires: new Date('2040-12-31') });
    const { language } = yield select(accountSelector) || {};
    if (language && language !== locale) {
      const settingsRequestUrl = '/api/Account/me/settings';
      yield call(ApiService.regionalRequest, settingsRequestUrl, {
        method: 'PUT',
        body  : { language: locale },
      });
    }
  } catch (err) {
    // background operation
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* fetchRegions() {
  try {
    const requestUrl = '/api/Localization/regions';
    const regions = yield call(ApiService.originalRequest, requestUrl);
    yield put(actions.fetchRegionsSuccess(regions));
  } catch (err) {
    yield put(actions.fetchRegionsError(err));
    yield call(dispatchError, err, messages);
  }
}


function* fetchCountries() {
  try {
    const requestUrl = '/api/Localization/countries';
    const countries = yield call(ApiService.originalRequest, requestUrl);
    yield put(actions.fetchCountriesSuccess(countries));
  } catch (err) {
    yield put(actions.fetchCountriesError(err));
    yield call(dispatchError, err, messages);
  }
}


function* fetchCountrySettings({ payload }) {
  try {
    const { countryId } = payload;
    const requestUrl = `/api/Localization/country/${countryId}/defaultSettings`;
    const countrySettings = yield call(ApiService.originalRequest, requestUrl);
    if (countrySettings) {
      countrySettings.countryId = countryId;
    }
    yield put(actions.fetchCountrySettingsSuccess(
      countrySettings,
    ));
  } catch (err) {
    yield put(actions.fetchCountrySettingsError(err));
    yield call(dispatchError, err, messages);
  }
}


function* fetchDevices() {
  try {
    const [devices, caseTypes] = yield all([
      call(ApiService.originalRequest, '/api/Devices'),
      call(ApiService.originalRequest, '/api/Devices/CaseTypes'),
    ]);
    yield put(actions.fetchDevicesSuccess(devices, caseTypes));
  } catch (err) {
    yield put(actions.fetchDevicesError(err));
    yield call(dispatchError, err, messages);
  }
}


function* fetchLanguages() {
  try {
    const requestUrl = '/api/Localization/languages';
    const languages = yield call(ApiService.originalRequest, requestUrl);
    yield put(actions.fetchLanguagesSuccess(languages));
  } catch (err) {
    yield put(actions.fetchLanguagesError(err));
    yield call(dispatchError, err, messages);
  }
}

// TRANSLATIONS --------------------------------------------------------------------------------------------------------

function importTranslationsFile(dirname, langCode = DEFAULT_LOCALE) {
  return import(`../../${dirname}/translations/${langCode}.json`);
}


function* importTranslations(dirname, langCode) {
  const defaultTranslations = yield call(importTranslationsFile, dirname);
  if (langCode === DEFAULT_LOCALE) {
    return defaultTranslations.default;
  }
  let translations = null;
  try {
    translations = yield call(importTranslationsFile, dirname, langCode);
  } catch (err) {
    // Background fallback
  }
  return { ...defaultTranslations.default, ...(translations && translations.default) };
}


function* loadTranslations(langCode) {
  if (
    !APP_LOCALES.includes(langCode)
    && !Object.values(APP_LOCALE_LANGUAGES_MAP).includes(langCode)
  ) {
    throw new LocalError({ code: 'InvalidLanguage' });
  }
  const translationsArray = yield all(constants.TRANSLATIONS_LOCATIONS.map(
    (dirname) => call(importTranslations, dirname, langCode),
  ));
  const translations = translationsArray.reduce((result, current) => Object.assign(result, current), {});
  yield put(actions.setTranslations(translations));
}


function* fetchLocalizationResources() {
  try {
    const langCode = yield select(selectors.langCode);
    const requestUrl = `/api/Localization/language/${langCode}/resources`;
    const [localizationResourcesArray] = yield all([
      call(ApiService.originalRequest, requestUrl),
      call(loadTranslations, langCode),
    ]);
    const localizationResources = {};
    forEach(localizationResourcesArray, (lr) => {
      localizationResources[lr.resourceKey] = lr;
    });
    yield put(actions.fetchLocalizationResourcesSuccess(localizationResources));
  } catch (err) {
    yield put(actions.fetchLocalizationResourcesError(err));
  }
}


function* addPayerProposal({ payload }) {
  try {
    const { countryId, payer } = payload;
    const requestUrl = `/api/Localization/country/${countryId}/payers`;
    yield call(ApiService.originalRequest, requestUrl, {
      method: 'POST',
      body  : { payer },
    });
    yield put(actions.addPayerProposalSuccess());
  } catch (err) {
    yield put(actions.addPayerProposalsError(err));
  }
}


function* fetchSystemAlertsSettings() {
  try {
    const requestUrl = '/api/Alerts/Settings';
    const response = yield call(ApiService.regionalRequest, requestUrl, {
      method: 'GET',
    });
    yield put(actions.fetchSystemAlertsSettingsSuccess(response));
  } catch (err) {
    yield put(actions.fetchSystemAlertsSettingsError(err));
  }
}


function* fetchSystemAlerts() {
  try {
    const requestUrl = '/api/Alerts';
    const response = yield call(ApiService.regionalRequest, requestUrl, {
      method: 'GET',
    });
    yield put(actions.fetchSystemAlertsSuccess(response));
  } catch (err) {
    yield put(actions.fetchSystemAlertsError(err));
  }
}

// EVENT LOG -----------------------------------------------------------------------------------------------------------

function* logEvent({ payload }) {
  try {
    const { event } = payload;
    const requestUrl = '/api/EventLog';
    yield call(ApiService.regionalRequest, requestUrl, {
      method: 'POST',
      body  : event,
    });
    yield put(actions.logEventSuccess());
  } catch (err) {
    yield call(dispatchError, err, {}, null, true);
    yield put(actions.logEventError(err));
  }
}

// AID -----------------------------------------------------------------------------------------------------------------

function* goToAid({ payload }) {
  try {
    const { addressSegment } = payload;
    let { route } = payload;
    const domain = yield getContext('domain');
    const externalRedirect = yield getContext('externalRedirect');
    const getUrl = yield getContext('getUrl');
    if (isUndefined(route)) {
      route = yield select(selectors.route);
    }
    const blsReturnUrl = route && encodeURIComponent(`${domain}${getUrl(route.name, route.params)}`);
    const blsReturnUrlQuery = blsReturnUrl ? `?blsReturnUrl=${blsReturnUrl}` : '';
    const redirectUrl = `${domain}/aid/${addressSegment}${blsReturnUrlQuery}`;
    externalRedirect(redirectUrl);
  } catch (err) {
    yield put(actions.goToAidError(err));
    yield call(dispatchError, err, messages);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* downloadFile(action) {
  const { id, url } = action.payload;
  const downloadFileChannel = yield getContext('downloadFileChannel');
  const channel = yield call(downloadFileChannel, url);
  while (true) {
    const { success, response, error } = yield take(channel);
    if (error) {
      yield put(actions.downloadError(id, get(response, 'error', null)));
      return;
    }
    if (success) {
      yield put(actions.downloadSuccess(id));
      return;
    }
  }
}


/**
 * Upload the specified file
 *
 * @param file {File} uploading file
 * @param type {string} application internal type of file
 * @param meta {Object} file meta data
 */
function* uploadFile(file, type, meta) {
  const data = new FormData();
  data.append('fileToUpload', file);

  const uploadFileChannel = yield getContext('uploadFileChannel');
  const channel = yield call(uploadFileChannel, `/api/file/upload/${type}`, data);
  while (true) {
    const { progress = 0, success, response, error } = yield take(channel);
    if (error) {
      yield put(actions.uploadError(get(response, 'validation_errors', null), meta));
      return;
    }
    if (success) {
      yield put(actions.uploadSuccess(response.data, meta));
      return;
    }
    yield put(actions.uploadProgress(progress, meta));
  }
}

// SIGNALR -------------------------------------------------------------------------------------------------------------


function* signalRExternalListener(hubChannel) {
  while (true) {
    const action = yield take(hubChannel);
    // console.info('signalRExternalListener', actions);
    yield put(action);
  }
}


function signalRCreateHubChannel(connection) {
  return eventChannel((emitter) => {

    const startConnection = () => {
      connection.start()
        .then(() => {
          emitter(actions.setSignalRConnected());
        })
        .catch(() => {
          setTimeout(() => startConnection(), 5000); // try to reconnect after 5 seconds
        });
    };

    connection.on(constants.SIGNALR_NOTIFICATION_RECEIVE_MSG, (data) => {
      emitter({
        type   : `${constants.SIGNALR_MODULE_ID}/${data.type}`,
        payload: data.payload, // eventually payload will be in event data
      });
    });

    connection.onclose((error) => {
      if (error) {
        emitter(actions.setSignalRError());
        // console.info('Disconnected');
      } else {
        emitter(actions.setSignalRDisconnected());
      }
    });

    startConnection();

    return () => {
      connection.stop();
      // console.info('Connection stopped');
      emitter(actions.setSignalRDisconnected());
    };
  });
}


function* signalRCreateHub() {
  const signalRError = new LocalError({ code: 'SignalRError' });
  try {
    const isSignalRConnected = yield select(selectors.isSignalRConnected);
    if (isSignalRConnected) {
      return;
    }
    const connection = yield call(SignalRService.getHubConnection);
    const hubChannel = yield call(signalRCreateHubChannel, connection);
    while (true) {
      const { cancel, error } = yield race({
        task  : call(signalRExternalListener, hubChannel),
        cancel: take(actionTypes.SIGNALR_SET_DISCONNECTED),
        error : take(actionTypes.SIGNALR_ERROR),
      });
      if (cancel) {
        SignalRService.removeHubConnection();
        hubChannel.close();
      }
      if (error) {
        yield call(dispatchError, signalRError, messages);
      }
    }
  } catch (err) {
    console.error(err);
    yield call(dispatchError, signalRError, messages);
  }

}


//----------------------------------------------------------------------------------------------------------------------


function connectWebsocket(url) {
  const websocket = new WebSocket(url);
  return new Promise((resolve) => {
    websocket.onopen = () => {
      resolve(websocket);
    };
  });
}


function createWebsocketChannel(websocket) {

  return eventChannel((emitter) => {

    websocket.onmessage = (evt) => {
      // const msg = JSON.parse(evt.data);
      const { data } = evt;
      // console.log('received:', evt);
      const actionType = 'TEST_ACTION'; // eventually actionType will be in event data
      emitter({
        type   : `ws/${actionType}`,
        payload: data, // eventually payload will be in event data
      });
    };

    websocket.onerror = () => {
      // console.error('WebSocket error:', evt);
      emitter(actions.websocketStoreState(websocket.readyState));
    };

    return () => {
      // console.log('socket close');
      websocket.close();
      emitter(actions.websocketStoreState(websocket.readyState));
    };
  });

}


function* internalListener(socket) {
  while (true) {
    const action = yield take(actionTypes.WEBSOCKET_SEND);
    socket.send(action.payload);
  }
}


function* externalListener(socketChannel) {
  while (true) {
    const action = yield take(socketChannel);
    yield put(action);
  }
}


function* createWebsocket() {
  try {
    const websocketState = yield select(selectors.websocketStateSelector());
    if (websocketState <= 1) {
      return;
    }
    const wsApiUrl = yield getContext('wsApiUrl');
    const { timeout, websocket } = yield race({
      websocket: call(connectWebsocket, wsApiUrl),
      timeout  : delay(2000),
    });
    if (timeout) {
      throw new LocalError({ code: 'WebsocketError' });
    }
    const websocketChannel = yield call(createWebsocketChannel, websocket);
    yield put(actions.websocketStoreState(websocket.readyState));
    while (true) {
      const { cancel } = yield race({
        task  : all([call(externalListener, websocketChannel), call(internalListener, websocket)]),
        cancel: take(actionTypes.WEBSOCKET_STOP),
      });
      if (cancel) {
        websocketChannel.close();
      }
      yield put(actions.websocketStoreState(websocket.readyState));
    }
  } catch (err) {
    yield call(dispatchError, err, messages);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* loadGTM() {
  try {
    const apps = yield getContext('apps');
    yield call(gtm.loadGTM, apps.gtm);
    yield put(actions.loadGTMSuccess());
  } catch (err) {
    yield put(actions.loadGTMFailed(err));
  }
}


function* loadHotjar() {
  try {
    const apps = yield getContext('apps');
    yield call(hotjar.load, apps.hotjar, 6);
    yield put(actions.loadHotjarSuccess());
  } catch (err) {
    yield put(actions.loadHotjarFailed(err));
  }
}


// function* loadUserback() {
//   try {
//     const apps = yield getContext('apps');
//     const locale = yield select(selectors.locale);
//     const direction = yield select(selectors.direction);
//     yield call(userback.load, apps.userback, locale, direction);
//     yield put(actions.loadUserbackSuccess());
//   } catch (err) {
//     yield put(actions.loadUserbackError(err));
//     yield call(dispatchError, err, messages);
//   }
// }
//
// function* setUserbackLocale({ payload }) {
//   try {
//     if (!process.env.BROWSER) {
//       return;
//     }
//     const { locale, direction } = payload;
//     const apps = yield getContext('apps');
//     yield call(userback.load, apps.userback, locale, direction);
//     yield put(actions.loadUserbackSuccess());
//   } catch (err) {
//     yield put(actions.loadUserbackError(err));
//     yield call(dispatchError, err, messages);
//   }
// }


//----------------------------------------------------------------------------------------------------------------------

function* pushVirtualPageview({ payload }) {
  if (!process.env.BROWSER || __DEV__) {
    return;
  }
  try {
    const { pathname, search, hash } = payload;
    yield call(gtm.vpPush, pathname, search, hash);
  } catch (err) {
    // We don't need any action because we're doing it in background
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* cacheFeatureToggles() {
  if (!process.env.BROWSER) {
    return;
  }
  const cookies = yield getContext('cookies');
  const featureToggles = yield select(selectors.featureToggles);
  cookies.set('featureToggles', JSON.stringify(featureToggles), { path: '/', expires: new Date('2040-12-31') });
}


function* restoreFeatureToggles() {
  const cookies = yield getContext('cookies');
  let featureToggles = cookies.get('featureToggles') || [];
  try {
    featureToggles = featureToggles.filter((name) => constants.FEATURE_TOGGLES[name]);
    yield all(featureToggles.map((name) => put(actions.setFeatureToggle({ name, value: true }))));
  } catch (err) {
    yield call(dispatchError, err, messages);
  }
}


function* updateFeatureToggles() {
  const search = get(window, 'location.search');
  const params = queryString.parse(search);
  const paramsEntries = Object.entries(params);
  const paramEntry = paramsEntries.find(([name]) => constants.FEATURE_TOGGLES[name]);
  if (!paramEntry) {
    return;
  }
  const [name, strValue] = paramEntry;
  if (strValue === 'on') {
    yield put(actions.setFeatureToggle({ name, value: true }));
  } else if (strValue === 'off') {
    yield put(actions.setFeatureToggle({ name, value: false }));
  }
}


function* handleFeatureToggles() {
  if (!process.env.BROWSER) {
    return;
  }
  yield call(restoreFeatureToggles);
  yield call(updateFeatureToggles);
}

//----------------------------------------------------------------------------------------------------------------------

function* dumpState() {
  const rawDumpedState = { ...yield select((state) => state) };
  const dumpedState = cloneDeep(pick(rawDumpedState, constants.REQUIRED_REPORT_STATE_PATHS));
  forEach(
    Object.entries(constants.EXCLUDED_REPORT_STATE_PATHS_WITH_DEFAULTS),
    ([key, value]) => set(dumpedState, key, value),
  );
  LocalStorage.setItem('dumpedState', JSON.stringify(dumpedState));
}


function* goToReportsView() {
  yield call(dumpState);
  window.open('/reports', '_blank');
}

//----------------------------------------------------------------------------------------------------------------------

function* filterNewConfigurationVersion(configuration) {

  const { countryCode } = yield select(selectors.countrySettings);
  const localLanguage = yield select(selectors.locale);
  const profile = yield select(activeProfileTypeSelector);

  let supportedDevices = get(configuration, `deviceCountries.${countryCode}`, null);
  if (!supportedDevices || !supportedDevices.length) {
    supportedDevices = yield select(selectors.devices);
  }
  const { account, clinicPatient } = get(configuration, 'identifiers.fieldDefinitions');
  const countries = get(configuration, 'countries');
  const countrySettings = get(configuration, `defaultSettings.${countryCode}`);
  countrySettings.countryId = find(countries, (country) => country.alpha2Code === countryCode).countryId;
  const localizationResourcesArray = get(configuration, `terms.${localLanguage}`);
  const localizationResources = {};
  forEach(localizationResourcesArray, (lr) => {
    localizationResources[lr.resourceKey] = lr;
  });
  return {
    countries,
    regions              : get(configuration, 'regions'),
    countrySettings,
    // ok
    payers               : get(configuration, `payers.${countryCode}`, []), // Account.payers, hcp.payers
    supportedDevices,
    localizationResources,
    clinicPatientTemplate: get(
      find(clinicPatient, (clinicPatientByCountry) => clinicPatientByCountry.countryCode === countryCode),
      'fieldDefinitions',
    ),
    profileTemplate: find(
      account,
      (accountByCountry) => accountByCountry.countryCode === countryCode && accountByCountry.scope === profile,
    ),
  };
}


function* fetchNewConfigurationVersion({ payload }) {
  if (!process.env.BROWSER) {
    return;
  }
  const { component, configurationFileName } = payload;
  if (component !== constants.META_COMPONENT_NAME) {
    return;
  }
  try {
    const fetch = yield getContext('fetch');
    const apiUrl = yield getContext('apiUrl');
    const regionName = yield select(selectors.regionName);
    const configurationUrl = `${apiUrl}/storage/${regionName}/configuration/${configurationFileName}`;

    const response = yield call(fetch, configurationUrl);
    const data = yield response.text().then((text) => (text ? JSON.parse(text) : {}));
    const componentConfiguration = yield call(filterNewConfigurationVersion, data);
    yield put(actions.setNewConfigurationVersion(componentConfiguration));
  } catch (err) {

    yield call(dispatchError, err, messages);
  }
}


function* setAllowCookies() {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + 365);
  const cookies = yield getContext('cookies');
  cookies.set('ALLOW_COOKIES', 1, { path: '/', expires: expirationDate });
}


//----------------------------------------------------------------------------------------------------------------------

function* sagas() {
  yield takeLatest(actionTypes.SET_REGION, setRegion);
  yield takeLatest(actionTypes.SET_LOCALE, setLocale);
  yield takeLatest(actionTypes.FETCH_REGIONS, fetchRegions);
  yield takeLatest(actionTypes.FETCH_COUNTRIES, fetchCountries);
  yield takeLatest(actionTypes.FETCH_COUNTRY_SETTINGS, fetchCountrySettings);
  yield takeLatest(actionTypes.FETCH_DEVICES, fetchDevices);
  yield takeLatest(actionTypes.FETCH_LANGUAGES, fetchLanguages);
  yield takeLatest(actionTypes.FETCH_LOCALIZATION_RESOURCES, fetchLocalizationResources);
  yield takeLatest(actionTypes.ADD_PAYER_PROPOSAL, addPayerProposal);
  yield takeEvery(actionTypes.LOG_EVENT, logEvent);
  yield takeLatest(actionTypes.GO_TO_AID, goToAid);
  yield takeEvery(actionTypes.DOWNLOAD, downloadFile);
  yield takeEvery(actionTypes.UPLOAD, function* (action) {
    const { meta, payload } = action;
    const { file, type } = payload;
    yield call(uploadFile, file, type, meta);
  });
  yield takeEvery(actionTypes.SIGNALR_CREATE_HUB, signalRCreateHub);
  yield takeEvery(actionTypes.WEBSOCKET_START, createWebsocket);
  yield takeLatest(actionTypes.LOAD_GTM, loadGTM);
  yield takeLatest(actionTypes.LOAD_HOTJAR, loadHotjar);
  // yield takeLatest(actionTypes.LOAD_USERBACK, loadUserback);
  // yield takeLatest(actionTypes.SET_USERBACK_LOCALE, setUserbackLocale);
  yield takeLatest(actionTypes.PUSH_VIRTUAL_PAGEVIEW, pushVirtualPageview);
  yield takeLatest(actionTypes.SET_CLIENT_IS_INITIALIZED, handleFeatureToggles);
  yield takeEvery(actionTypes.SET_FEATURE_TOGGLE, cacheFeatureToggles);
  yield takeLatest(actionTypes.FETCH_SYSTEM_ALERTS_SETTINGS, fetchSystemAlertsSettings);
  yield takeLatest(actionTypes.FETCH_SYSTEM_ALERTS, fetchSystemAlerts);
  yield takeLatest(actionTypes.GO_TO_REPORTS_VIEW, goToReportsView);
  yield takeEvery(actionTypes.NEW_CONFIGURATION_VERSION, fetchNewConfigurationVersion);
  yield takeLatest(actionTypes.SET_ALLOW_COOKIES, setAllowCookies);
}

export default [
  sagas,
];
