import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useAction } from 'hooks';
import Button from 'components/Form/Button';
import App from 'modules/App';
import Information from 'modules/Information';
import messages from '../../messages';


const Actions = ({ connectorConsentTemplate, onSetComponent, onCancel }) => {
  const legalConsentApprovals = connectorConsentTemplate.map((cct) => ({
    legalConsentId: cct.legalConsentId,
    decision      : true,
  }));
  const isInProgress = useSelector(Information.selectors.isUpdateInformationInProgress);
  const prevIsInProgressRef = useRef(isInProgress);
  const hasErrors = useSelector(Information.selectors.hasUpdateInformationErrors);
  const isLocked = isInProgress || (!isInProgress && !hasErrors && prevIsInProgressRef.current);
  const onSubmit = useAction(Information.actions.updateInformation, { legalConsentApprovals });

  useEffect(() => {
    if (prevIsInProgressRef.current !== isInProgress) {
      if (!isInProgress && !hasErrors) {
        onSetComponent('Initializing');
      }
      prevIsInProgressRef.current = isInProgress;
    }
  }, [isInProgress]);

  return (
    <div className="modal__actions row">
      <div className="col-6">
        <Button
          styleModifier="primary"
          labelMessage={App.messages.buttons.cancel}
          className="btn--block"
          isDisabled={isLocked}
          onClick={onCancel}
        />
      </div>
      <div className="col-6">
        <Button
          styleModifier="primary"
          labelMessage={messages.buttons.download}
          className="btn--block btn--filled"
          isInProgress={isLocked}
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};


Actions.propTypes = {
  connectorConsentTemplate: PropTypes.arrayOf(Information.shapes.consentTemplate),
  onSetComponent          : PropTypes.func,
  onCancel                : PropTypes.func,
};


const Consent = ({ consentTemplate }) => {
  const localizationResources = useSelector(App.selectors.localizationResources);
  const consentText = get(localizationResources, [consentTemplate.consentPromptKey, 'value']);
  return <p className="brand__paragraph brand__fontSecondary">{ consentText }</p>;
};


Consent.propTypes = {
  consentTemplate: Information.shapes.consentTemplate,
};


const Consents = ({ connectorConsentTemplate, onSetComponent, onCancel }) => {
  if (isEmpty(connectorConsentTemplate)) {
    onSetComponent('Initializing');
    return null;
  }

  return (
    <div>
      { connectorConsentTemplate.map((cct) => <Consent key={cct.legalConsentId} consentTemplate={cct} />) }
      <Actions {...{ connectorConsentTemplate, onSetComponent, onCancel }} />
    </div>
  );
};


Consents.propTypes = {
  connectorConsentTemplate: PropTypes.arrayOf(Information.shapes.consentTemplate),
  onSetComponent          : PropTypes.func,
  onCancel                : PropTypes.func,
};


export default Consents;
