import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import { AppContext } from 'context';
import Validator from 'libs/Validator';
import App from 'modules/App';
import ContourCloud from 'modules/ContourCloud';
import Form from 'components/Form';
import FormGroup from 'components/Form/FormGroup';
import Input from 'components/Form/Input';
import Button from 'components/Form/Button';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import * as shapes from '../../shapes';
import messages from '../../messages';
import validatorRules from './validatorRules.json';


class ReauthToCCModal extends React.PureComponent {

  static contextType = AppContext;

  static propTypes = {
    // Explicit props
    onSuccess       : PropTypes.func,
    // Implicit props
    openModalId     : PropTypes.string,
    formValues      : PropTypes.object,
    isInProgress    : PropTypes.bool,
    hasErrors       : PropTypes.bool,
    account         : shapes.account,
    // Implicit actions
    onReauth        : PropTypes.func,
    onSetFormValue  : PropTypes.func,
    onFormErrors    : PropTypes.func,
    onFormProcessing: PropTypes.func,
    onClearForm     : PropTypes.func,
  };


  componentDidMount() {
    this.onPopulate();
  }


  componentDidUpdate(prevProps) {
    const { isInProgress, hasErrors } = this.props;
    if (isInProgress !== prevProps.isInProgress && !isInProgress && !hasErrors) {
      this.props.onSuccess();
    }
  }


  onPopulate() {
    if (this.props.account && this.props.account.contourCloudEmail) {
      this.props.onSetFormValue({ id: 'email', value: this.props.account.contourCloudEmail });
    }
  }


  onSubmit() {
    this.props.onFormProcessing();
    const values = get(this.props.formValues, 'values', {});
    const { validatedValues, errors } = Validator.run(values, validatorRules);
    if (errors) {
      this.props.onFormErrors(errors);
      return;
    }
    const { email, password } = validatedValues;
    this.props.onReauth(email, password);
    this.props.onClearForm();
  }

  render() {
    return (
      <div>
        <App.components.AlertsBus className="mb-4" />
        <div className="row mb-4">
          <div className="col-auto">
            <img src="/assets/svg/contour-logo.svg" alt="Contour Cloud" />
          </div>
          <div className="col text--large text-lh--133">
            <FormattedMessage {...messages.infos.reauthToCC} />
          </div>
        </div>
        <Form onSubmit={() => this.onSubmit()}>
          <FormGroup
            id="email"
            labelMessage={messages.labels.email}
            formValues={this.props.formValues}
          >
            <Input
              type="text"
              disabled={this.props.isInProgress}
              placeholder={messages.placeholders.email}
              onChange={this.props.onSetFormValue}
              attributes={{ autoFocus: true }}
            />
          </FormGroup>
          <FormGroup
            id="password"
            labelMessage={messages.labels.password}
            formValues={this.props.formValues}
          >
            <Input
              type="password"
              disabled={this.props.isInProgress}
              placeholder={messages.placeholders.password}
              onChange={this.props.onSetFormValue}
            />
          </FormGroup>
          <Button
            type="submit"
            isInProgress={this.props.isInProgress}
            styleModifier="primary"
            labelMessage={messages.buttons.signIn}
            className="btn--block btn--filled"
          />
        </Form>
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  account     : selectors.account(state),
  openModalId : App.selectors.modal(state),
  formValues  : App.selectors.formSelector(constants.REAUTH_TO_CC_FORM)(state),
  isInProgress: ContourCloud.selectors.isReauthInProgress(state),
  hasErrors   : ContourCloud.selectors.hasReauthErrors(state),
});

const mapDispatchToProps = (dispatch) => {
  const formName = constants.REAUTH_TO_CC_FORM;

  return {
    onReauth        : (email, password) => dispatch(ContourCloud.actions.reauth(email, password)),
    onSetFormValue  : (input) => dispatch(App.actions.setFormValue(formName, input)),
    onFormErrors    : (errors) => dispatch(App.actions.setFormErrors(formName, errors)),
    onFormProcessing: () => dispatch(App.actions.startFormProcessing(formName)),
    onClearForm     : () => dispatch(App.actions.clearForm(formName)),
  };
};

const ConnectedReauthToCCModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReauthToCCModal);


export default ConnectedReauthToCCModal;
