import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Form/Button';
import messages from '../../messages';
import styles from '../../components/DownloadDataModal/DownloadDataModal.pcss';


const NoConnection = ({ onSetComponent }) => {
  const tryAgainLabel = <FormattedMessage {...messages.buttons.tryAgain} />;
  const goToInitializing = () => onSetComponent('Initializing');
  return (
    <div className={styles.modal__content}>
      <h3 className="modal__subheader "><FormattedMessage {...messages.sccNoConnection.header} /></h3>
      <p className="modal__info">
        <FormattedMessage {...messages.sccNoConnection.info} values={{ tryAgainLabel }} />
      </p>
      <div className={styles.illustration}>
        <img src="/assets/svg/no-connection.svg" className="w-100" alt="" />
      </div>
      <div className="modal__actions">
        <Button
          styleModifier="primary"
          labelMessage={messages.buttons.tryAgain}
          className="btn--block btn--filled"
          onClick={goToInitializing}
        />
      </div>
    </div>
  );
};


NoConnection.propTypes = {
  onSetComponent: PropTypes.func,
};


export default NoConnection;
