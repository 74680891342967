import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import * as actions from '../../actions';
import * as shapes from '../../shapes';
import messages from '../../messages';
import styles from './DownloadDataModal.pcss';


class InstallingBlueCableDrivers extends React.PureComponent {


  static propTypes = {
    // Explicit props
    downloader              : shapes.downloader,
    // Explicit actions
    onSetComponent          : PropTypes.func,
    // Implicit actions
    onInstallBlueCableDriver: PropTypes.func,
  };


  componentDidMount() {
    const installationState = get(this.props.downloader, 'driver.installationState', 'NotStarted');
    this.props.onInstallBlueCableDriver(installationState);
  }


  componentDidUpdate(prevProps) {
    const { driver } = this.props.downloader;
    if (prevProps.downloader.driver !== driver) {
      const { isDriverInstalled, installationState } = driver;
      if (isDriverInstalled) {
        this.props.onSetComponent('InstalledBlueCableDrivers');
      }

      if (installationState === 'CompletedWithError') {
        this.props.onSetComponent('NoConnection');
      }
    }
  }


  render() {
    return (
      <div className={styles.modal__content}>
        <h3 className="modal__subheader "><FormattedMessage {...messages.installingBlueCableDrivers.header} /></h3>
        <p className="modal__info"><FormattedMessage {...messages.installingBlueCableDrivers.info} /></p>
        <div className={styles.illustration}>
          <img src="/assets/svg/loader.svg" className="rotatingLoader" alt="" />
        </div>
        <p className="modal__info text--center mb-5"><FormattedMessage {...messages.installingBlueCableDrivers.waiting} /></p>
      </div>
    );
  }

}


const mapDispatchToProps = (dispatch) => ({
  onInstallBlueCableDriver: (installationState) => dispatch(actions.installBlueCableDriver(installationState)),
});

const ConnectedInstallingBlueCableDrivers = connect(
  null,
  mapDispatchToProps,
)(InstallingBlueCableDrivers);


export default ConnectedInstallingBlueCableDrivers;
