export const hasMismatchBetweenSummaryAndStandards = (summary, standards) => {
  const { preMealHighNorm, preMealLowNorm, highNorm, lowNorm } = summary;
  const { preMeal, postMeal } = standards;

  if (preMealHighNorm && preMealHighNorm !== preMeal.highThreshold) {
    return true;
  }

  if (preMealLowNorm && preMealLowNorm !== preMeal.lowThreshold) {
    return true;
  }

  if (highNorm && highNorm !== postMeal.highThreshold) {
    return true;
  }

  if (lowNorm && lowNorm !== postMeal.lowThreshold) {
    return true;
  }

  return false;
};

export const hasMismatchBetweenTimes = (timeFromDevice, timeFromSummary) =>
  timeFromDevice.diff(timeFromSummary, 'minutes') > 15;
