import React, { createRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import ContentEditable from 'react-contenteditable';
import { v4 as uuid } from 'uuid';
import get from 'lodash/get';
import includes from 'lodash/includes';
import moment from 'moment';
import { motion, AnimatePresence } from 'framer-motion';
import pick from 'lodash/pick';
import { encrypt, getKeyFromPem } from 'helpers/crypto';
import Avatar from 'components/Avatar';
import Link from 'components/Link';
import Button from 'components/Form/Button';
import Sync from 'svg/sync.svg';
import CheckNegative from 'svg/check-negative.svg';
import EditIcon from 'svg/edit.svg';
import CheckIcon from 'svg/check.svg';
import EnvelopeIcon from 'svg/envelope.svg';
import XNegativeIcon from 'svg/x-negative.svg';
import Dots from 'svg/dots.svg';
import XIcon from 'svg/x.svg';
import Star from 'svg/star-filled.svg';
import App from 'modules/App';
import Account from 'modules/Account';
import CloudDrive from 'modules/CloudDrive';
import Hcp from 'modules/Hcp';
import Visit from 'modules/Visit';
import { getCaretPosition, setCaretPosition, trim } from 'helpers/contenteditable';
import * as constants from '../../constants';
import messages from '../../messages';
import styles from '../Results/Results.pcss';
import AdditionalMeasurementsBtn from '../../partials/AdditionalMeasurementsBtn';


class PatientHeader extends React.PureComponent {

  static getDerivedStateFromProps(props, state) {
    const { activePatient } = props;

    const shouldUpdate = activePatient && (
      (state.activePatient && activePatient.id !== state.activePatient.id)
        || !state.activePatient
        || state.activePatient.firstName !== activePatient.firstName
        || state.activePatient.lastName !== activePatient.lastName
        || state.activePatient.isFavorite !== activePatient.isFavorite);

    if (shouldUpdate) {
      return {
        activePatient       : { ...activePatient },
        firstName           : activePatient.firstName,
        lastName            : activePatient.lastName,
        isOnNameEditMode    : false,
        isDisabledChangeName: false,
      };
    }

    return null;
  }


  static propTypes = {
    // Explicit props
    isPhiInProgress: PropTypes.bool,
    // Explicit actions
    onSync         : PropTypes.func,
    // Implicit props
    activePatient  : PropTypes.shape({
      id             : PropTypes.string,
      invitationCode : PropTypes.string,
      avatar         : PropTypes.string,
      email          : PropTypes.string,
      firstName      : PropTypes.string,
      lastName       : PropTypes.string,
      storageProvider: PropTypes.string,
      isFavorite     : PropTypes.bool,
    }),
    activeClinicMembership           : Account.shapes.clinicMembership,
    sharingRequest                   : Hcp.shapes.sharingRequest,
    syncPatientId                    : PropTypes.string,
    isSyncInProgress                 : PropTypes.bool,
    hasSyncErrors                    : PropTypes.bool,
    isSharingRequestInProgress       : PropTypes.bool,
    hasSharingRequestErrors          : PropTypes.bool,
    isUpdatePatientInProgress        : PropTypes.bool,
    openDropdownId                   : PropTypes.string,
    phiSet                           : PropTypes.object, // @TODO: shape
    phiSetDocumentId                 : PropTypes.string,
    isAddPatientToFavoritesInProgress: PropTypes.bool,
    // Implicit actions
    onUpdateProfile                  : PropTypes.func,
    onCreateSharingRequest           : PropTypes.func,
    onOpenModal                      : PropTypes.func,
    onCloseDropdown                  : PropTypes.func,
    onOpenDropdown                   : PropTypes.func,
    onAddPatientToFavorite           : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.invitationCode = null;
    this.refFirstNameLabel = createRef();
    this.refLastNameLabel = createRef();
    this.state = {
      isOnNameEditMode    : false,
      isDisabledChangeName: false,
      activePatient       : null,
      firstName           : '',
      lastName            : '',
    };
  }


  componentDidUpdate(prevProps) {
    const {
      activePatient, activeClinicMembership,
      isSharingRequestInProgress, hasSharingRequestErrors,
    } = this.props;
    const { invitationCode } = this;

    if (prevProps.activePatient !== activePatient) {
      this.invitationCode = null;
    }

    if (
      prevProps.isSharingRequestInProgress !== isSharingRequestInProgress
      && !isSharingRequestInProgress
      && !hasSharingRequestErrors
      && invitationCode
    ) {
      this.props.onUpdateProfile(activePatient, { invitationCode }, activeClinicMembership);
      this.invitationCode = null;
    }
  }


  async onOpenMenu(evt) {
    evt.preventDefault();
    const isOpenMenu = this.props.openDropdownId === constants.PROFILE_DROPDOWN;
    await this.props.onCloseDropdown();
    if (!isOpenMenu) {
      this.props.onOpenDropdown(constants.PROFILE_DROPDOWN);
    }
  }


  onInviteWithoutEmail(evt) {
    evt.preventDefault();
    this.props.onOpenModal(Hcp.constants.SET_EMAIL_AND_SEND_SHARING_REQUEST_MODAL);
  }


  onInviteWithEmail(evt) {
    evt.preventDefault();
    this.invitationCode = uuid();
    const { id, email } = this.props.activePatient;
    const { clinicHcpMembershipId } = this.props.activeClinicMembership;
    const pubKey = get(this.props.activeClinicMembership, 'clinic.publicKey');
    const pubKeyObj = getKeyFromPem(pubKey);
    const encryptedClinicPatientProfileId = encrypt(id, pubKeyObj);

    const clinicPatientHealthData = pick(this.props.phiSet, ['diabetesType', 'treatmentType']);
    clinicPatientHealthData.weight = get(this.props.phiSet, 'summaryData.lastWeight');
    clinicPatientHealthData.height = get(this.props.phiSet, 'summaryData.lastHeight');

    this.props.onCreateSharingRequest(
      clinicHcpMembershipId,
      this.invitationCode,
      email,
      encryptedClinicPatientProfileId,
      this.props.activePatient,
      clinicPatientHealthData,
    );
  }


  onUpdateName() {
    const { firstName: firstNameProps, lastName: lastNameProps } = this.props.activePatient || {};
    const { firstName, lastName } = this.state;
    const { isDisabledChangeName } = this.state;
    if ((firstName !== firstNameProps || lastName !== lastNameProps) && !isDisabledChangeName) {
      this.props.onUpdateProfile(
        this.props.activePatient,
        { firstName: trim(firstName), lastName: trim(lastName) },
        this.props.activeClinicMembership,
      );
      this.setState({ isOnNameEditMode: false, firstName: trim(firstName), lastName: trim(lastName) });
    } else if (!isDisabledChangeName) {
      this.setState({ isOnNameEditMode: false });
    }
  }


  onRejectName() {
    const { activePatient } = this.props;
    this.setState({
      firstName       : activePatient.firstName,
      lastName        : activePatient.lastName,
      isOnNameEditMode: false,
    });
  }


  onHandleChange(evt, type) {
    this.setState({ [type]: evt.currentTarget.textContent }, () => {
      const { isDisabledChangeName, firstName, lastName } = this.state;
      if (trim(firstName) !== '' && trim(lastName) !== '' && isDisabledChangeName) {
        this.setState({ isDisabledChangeName: false });
      } else if ((trim(firstName) === '' || trim(lastName) === '') && !isDisabledChangeName) {
        this.setState({ isDisabledChangeName: true });
      }
    });
  }


  onEditNameEditLabelKeyDown(evt) {
    switch (evt.keyCode) {
      // If ESC
      case 27:
        this.onRejectName();
        break;
      // If Enter
      case 13:
        this.onUpdateName();
        evt.preventDefault();
        break;
      // If Right arrow
      case 39: {
        const shouldChangeInput = evt.target.textContent.length === getCaretPosition(evt.target)
          && evt.target === this.refFirstNameLabel.current;
        if (shouldChangeInput) {
          this.refLastNameLabel.current.focus();
          evt.preventDefault();
        }
      }
        break;
      // If Left arrow
      case 37: {
        const shouldChangeInput = getCaretPosition(evt.target) === 0
          && evt.target === this.refLastNameLabel.current;
        if (shouldChangeInput) {
          // this.refFirstNameLabel.current.focus();
          setCaretPosition(this.refFirstNameLabel.current, this.refFirstNameLabel.current.textContent.length);
          evt.preventDefault();
        }
      }
        break;
      default:
        break;
    }
  }


  onOpenResendInvitationModal() {
    this.props.onOpenModal(Hcp.constants.RESEND_INVITATION_PATIENT_MODAL);
  }


  /**
   * Sync statuses:
   * 0 - unsynchronized
   * 1 - synchronizing in progress
   * 2 - sync success
   * 3 - sync error
   */
  get syncStatus() {
    const { activePatient, sharingRequest, syncPatientId, isSyncInProgress, hasSyncErrors } = this.props;
    if (!sharingRequest || !includes(['Approved', 'Enrolling'], sharingRequest.sharingStatus)) return 0;
    if (isSyncInProgress || sharingRequest.sharingStatus === 'Enrolling') return 1;
    if (hasSyncErrors) return 3;
    if (activePatient && activePatient.id === syncPatientId) return 2;
    return 0;
  }


  get ageValue() {
    const { activePatient } = this.props;
    const dateOfBirth = get(activePatient, 'dateOfBirth');
    if (!dateOfBirth) return null;
    const patientYears = moment().diff(dateOfBirth, 'years');
    return (
      <>
        { `${patientYears} ` }
        <FormattedMessage {...messages.labels.yearsOld} />
      </>
    );
  }


  get diabetesTypeValue() {
    const { phiSet } = this.props;
    const diabetesType = get(phiSet, 'diabetesType');
    if (!diabetesType || !App.messages.diabetesTypes[diabetesType]) return null;
    return <FormattedMessage {...App.messages.diabetesTypes[diabetesType]} />;
  }


  get treatmentTypeValue() {
    const { phiSet } = this.props;
    const treatmentType = get(phiSet, 'treatmentType');
    if (!treatmentType || !App.messages.treatmentTypes[treatmentType]) return null;
    return <FormattedMessage {...App.messages.treatmentTypes[treatmentType]} />;
  }


  renderMenu() {
    const { onAddPatientToFavorite, activePatient, activeClinicMembership } = this.props;

    if (this.props.openDropdownId !== constants.PROFILE_DROPDOWN) {
      return null;
    }

    return (
      <motion.div
        initial={{ height: 0 }}
        animate={{ height: 'auto' }}
        exit={{ height: 0 }}
        transition={{ ease: 'easeOut', duration: 0.15 }}
        className="dropdownMenu__container"
      >
        <ul className="dropdownMenu__actions">
          <li className="dropdownMenu__action">
            <Button
              styleModifier="transparent"
              type="button"
              onClick={() => this.props.onOpenModal(constants.PROFILE_EDIT_MODAL)}
              labelMessage={messages.buttons.editPatient}
            />
          </li>
          {
            activePatient.storageProvider && (
              <li className="dropdownMenu__action">
                <Button
                  styleModifier="transparent"
                  type="button"
                  isDisabled={this.props.isAddPatientToFavoritesInProgress}
                  onClick={() => onAddPatientToFavorite(activePatient, activeClinicMembership)}
                  labelMessage={
                    !activePatient.isFavorite
                      ? messages.buttons.addPatientToFavorite
                      : messages.buttons.removePatientFromFavorite
                  }
                />
              </li>
            )
          }
          <li className="dropdownMenu__action">
            <Button
              styleModifier="transparent"
              type="button"
              onClick={() => this.props.onOpenModal(Visit.constants.VISIT_HISTORY_MODAL)}
              labelMessage={messages.buttons.visitHistory}
            />
          </li>
          {
            this.syncStatus === 2 && (
              <li className="dropdownMenu__action">
                <Button
                  styleModifier="transparent"
                  type="button"
                  onClick={() => this.props.onSync()}
                  labelMessage={messages.buttons.refresh}
                />
              </li>
            )
          }
        </ul>
      </motion.div>
    );
  }


  renderAvatar() {
    if (!this.props.activePatient) {
      return <div className={styles.patient__avatar} />;
    }

    const { avatar, firstName, lastName } = this.props.activePatient;

    return (
      <Avatar
        avatarImg={avatar}
        name={[firstName, lastName]}
        className={styles.patient__avatar}
        imgClassName={styles.patient__avatar__img}
        initialsClassName={styles.patient__avatar__initials}
      />
    );
  }


  renderResendButton() {
    return (
      <Button
        id="SendEmailButton"
        styleModifier="transparent-primary"
        onClick={() => this.onOpenResendInvitationModal()}
      >
        <EnvelopeIcon className="btn__icon mr-4" />
        <FormattedMessage {...messages.buttons.resendInvitation} />
      </Button>
    );
  }


  renderSync() {
    if (!this.props.activePatient) {
      return <span className={`${styles.sync} ${styles['sync--placeholder']}`} />;
    }
    switch (this.syncStatus) {
      case 1: {
        return (
          <span className={styles.sync}>
            <Sync className={`${styles.sync__icon} rotating`} />
            <FormattedMessage {...messages.labels.synchronizing} />
          </span>
        );
      }
      case 2: {
        return (
          <span className={cn(styles.sync, styles['sync--synchronized'])}>
            <CheckNegative className={`${styles.sync__icon} ${styles['sync__icon--check']}`} />
            <FormattedMessage {...messages.labels.synchronized} />
          </span>
        );
      }
      case 3: {
        return (
          <span className={cn(styles.sync, styles['sync--unsynchronized'])}>
            <XNegativeIcon className={`${styles.sync__icon} ${styles['sync__icon--check']}`} />
            <FormattedMessage {...messages.labels.unsynchronized} />
          </span>
        );
      }
      default: {
        return null;
      }
    }
  }


  renderVisitSection(activePatient) {
    if (!activePatient) return null;
    return (
      <div className="col-auto">
        <Hcp.partials.StartStopVisitBtn
          activePatient={activePatient}
          phiSet={this.props.phiSet}
          phiSetDocumentId={this.props.phiSetDocumentId}
        />
      </div>
    );
  }


  renderPatientInformation(label, value, withoutMargin) {
    return (
      <>
        <p className={styles.patientAdditionalInfoLabel}>
          <FormattedMessage {...label} />:
        </p>
        <p className={
          cn(styles.patientAdditionalInfoValue, {
            [styles['patientAdditionalInfoValue--noMargin']]: withoutMargin,
          })
        }
        >
          { value || '-' }
        </p>
      </>
    );
  }


  renderAdditionalPatientInformation() {
    return (
      <div className={cn(styles.patientName__wrapper, styles.additionalPatientInfo)}>
        { this.renderPatientInformation(messages.labels.age, this.ageValue) }
        { this.renderPatientInformation(messages.labels.diabetesType, this.diabetesTypeValue) }
        { this.renderPatientInformation(messages.labels.treatmentType, this.treatmentTypeValue, true) }
      </div>
    );
  }


  renderSendEmailButton() {
    const { activePatient, sharingRequest } = this.props;
    if (sharingRequest && sharingRequest.sharingStatus === 'Pending') {
      return this.renderResendButton();
    }

    const shouldRenderSendEmailButton = ((
      sharingRequest
      && (
        sharingRequest.sharingStatus === 'Rejected'
        || sharingRequest.sharingStatus === 'Revoked'
      )
    ) || !activePatient.invitationCode || !sharingRequest);

    if (shouldRenderSendEmailButton) {
      const onClick = activePatient.email && sharingRequest
        ? (evt) => this.onInviteWithEmail(evt)
        : (evt) => this.onInviteWithoutEmail(evt);

      return (
        <Button
          id="SendEmailButton"
          styleModifier="transparent-primary"
          onClick={onClick}
        >
          <EnvelopeIcon className="btn__icon mr-4" />
          <FormattedMessage {...messages.buttons.invite} />
        </Button>
      );
    }
    return null;
  }


  renderPatientHeader(activePatient) {
    if (!activePatient) return null;
    return (
      <div className="col">
        <div className={cn('row align-items-center', styles.patientWrapper)}>
          <AnimatePresence>{ this.renderMenu() }</AnimatePresence>
          <div className={cn(styles.profileSettings, 'col-auto')}>
            <Link to="" onClick={(evt) => this.onOpenMenu(evt)}>
              <Dots />
            </Link>
          </div>
          <div className="col-auto">{ this.renderAvatar() }</div>
          <div className="col">
            <div className="row">
              <div>
                <div className={cn(styles.patientName__wrapper)}>
                  <div className={cn(styles.patient__name)}>
                    { this.renderPatientName() }
                  </div>
                  { this.renderSync() }
                </div>
              </div>
            </div>
            <div className="row">
              <div className="d-flex justify-content-center align-items-center">
                { this.renderAdditionalPatientInformation() }
                { this.renderSendEmailButton() }
                <AdditionalMeasurementsBtn />
                <Hcp.partials.VisitsNotesBtn phiSet={this.props.phiSet} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }


  renderPatientName() {
    const { isOnNameEditMode, isDisabledChangeName, firstName, lastName, activePatient } = this.state;
    if (!activePatient) {
      return (
        <div className={styles.patientName__wrapper}>
          <div className={`${styles.patientNameWrapper} ${styles['patientNameWrapper--placeholder']}`} />
        </div>
      );
    }

    return (
      <div className={styles.patientName__wrapper}>
        <div data-hj-suppress className={styles.patientNameWrapper}>
          {
            this.props.activePatient.storageProvider && (
              <Star
                className={
                  cn(styles.star, {
                    [styles.star_active]      : this.props.activePatient.isFavorite,
                    [styles['star--disabled']]: this.props.isAddPatientToFavoritesInProgress,
                  })
                }
                onClick={() => this.props.onAddPatientToFavorite(activePatient, this.props.activeClinicMembership)}
              />
            )
          }
          <FormattedMessage {...messages.labels.firstName}>
            {
              (msg) => (
                <ContentEditable
                  className={cn(styles.patientName, isOnNameEditMode ? styles.patientNameSelected : null)}
                  disabled={!isOnNameEditMode}
                  innerRef={this.refFirstNameLabel}
                  html={firstName}
                  onKeyDown={(evt) => this.onEditNameEditLabelKeyDown(evt)}
                  onChange={(evt) => this.onHandleChange(evt, 'firstName')}
                  placeholder={msg}
                />
              )
            }
          </FormattedMessage>
          <FormattedMessage {...messages.labels.lastName}>
            {
              (msg) => (
                <ContentEditable
                  className={cn(styles.patientName, isOnNameEditMode ? styles.patientNameSelected : null)}
                  disabled={!isOnNameEditMode}
                  innerRef={this.refLastNameLabel}
                  html={lastName}
                  onKeyDown={(evt) => this.onEditNameEditLabelKeyDown(evt)}
                  onChange={(evt) => this.onHandleChange(evt, 'lastName')}
                  placeholder={msg}
                />
              )
            }
          </FormattedMessage>
        </div>
        {
          isOnNameEditMode
            ? (
              <>
                <Button
                  type="button"
                  styleModifier="quaternary"
                  className={cn('btn--filled', styles.patientName__editButton)}
                  onClick={() => this.onUpdateName()}
                  isDisabled={isDisabledChangeName}
                >
                  <CheckIcon />
                </Button>
                <Button
                  type="button"
                  styleModifier="quaternary"
                  className={cn('btn--filled', styles.patientName__editButton)}
                  onClick={() => this.onRejectName()}
                >
                  <XIcon />
                </Button>
              </>
            )
            : (
              <Button
                styleModifier="transparent"
                className={cn(styles.patient__edit__icon, styles.patient__feature__action)}
                onClick={
                  () => {
                    this.setState({ isOnNameEditMode: true }, () => {
                      this.refFirstNameLabel.current.focus();
                    });
                  }
                }
              >
                <EditIcon className={styles.patient__feature__icon} />
              </Button>
            )
        }
      </div>
    );
  }


  render() {
    // @TODO: Skeleton loading
    const { activePatient } = this.props;
    return (
      <div
        className={
          cn('col', {
            [styles['patient--in-progress']]: !activePatient,
            fadingLoader                    : !activePatient,
          })
        }
      >
        <div className="row align-items-center">
          { this.renderPatientHeader(activePatient) }
          { this.renderVisitSection(activePatient) }
        </div>
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  activeClinicMembership           : Account.selectors.activeClinicMembership(state),
  syncPatientId                    : CloudDrive.selectors.syncPatientId(state),
  hasSyncErrors                    : CloudDrive.selectors.hasSyncErrors(state),
  activePatient                    : Hcp.selectors.activePatient(state),
  phiSet                           : Hcp.selectors.phiSet(state),
  phiSetDocumentId                 : Hcp.selectors.phiSetDocumentId(state),
  sharingRequest                   : Hcp.selectors.sharingRequest(state),
  isUpdatePatientInProgress        : Hcp.selectors.isUpdatePatientInProgress(state),
  isSyncInProgress                 : Hcp.selectors.isSyncInProgress(state),
  openDropdownId                   : App.selectors.dropdown(state),
  isSharingRequestInProgress       : Hcp.selectors.isCreateSharingRequestInProgress(state),
  hasSharingRequestErrors          : Hcp.selectors.hasCreateSharingRequestErrors(state),
  isAddPatientToFavoritesInProgress: Hcp.selectors.isAddPatientToFavoritesInProgess(state),
});


const mapDispatchToProps = (dispatch) => ({
  onUpdateProfile: (patient, newPatientValues, clinicMembership) => dispatch(
    Hcp.actions.updatePatient(patient, newPatientValues, clinicMembership),
  ),
  onAddPatientToFavorite: (patient, clinicMembership) => dispatch(
    Hcp.actions.addPatientToFavorite(patient, clinicMembership),
  ),
  onCreateSharingRequest: (
    clinicHcpMembershipId,
    invitationCode,
    patientEmailAddress,
    encryptedClinicPatientProfileId,
    clinicPatient,
    clinicPatientHealthData,
  ) => dispatch(
    Hcp.actions.createSharingRequest(
      clinicHcpMembershipId,
      invitationCode,
      patientEmailAddress,
      encryptedClinicPatientProfileId,
      clinicPatient,
      clinicPatientHealthData,
    ),
  ),
  onOpenModal    : (modalId) => dispatch(App.actions.openModal(modalId)),
  onOpenDropdown : (dropdownId) => dispatch(App.actions.openDropdown(dropdownId)),
  onCloseDropdown: () => dispatch(App.actions.closeDropdown()),
});


const ConnectedPatientHeader = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PatientHeader);


export default ConnectedPatientHeader;
