import * as actionTypes from './actionTypes';


//----------------------------------------------------------------------------------------------------------------------

export const fetchDataSources = () => ({
  type: actionTypes.FETCH_DATA_SOURCES,
});

export const fetchDataSourcesSuccess = (dataSources) => ({
  type   : actionTypes.FETCH_DATA_SOURCES_SUCCESS,
  payload: {
    dataSources,
  },
});

export const fetchDataSourcesError = (error) => ({
  type: actionTypes.FETCH_DATA_SOURCES_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const fetchConnectedDataSources = () => ({
  type: actionTypes.FETCH_CONNECTED_DATA_SOURCES,
});

export const fetchConnectedDataSourcesSuccess = (connectedDataSources) => ({
  type   : actionTypes.FETCH_CONNECTED_DATA_SOURCES_SUCCESS,
  payload: {
    connectedDataSources,
  },
});

export const fetchConnectedDataSourcesError = (error) => ({
  type: actionTypes.FETCH_CONNECTED_DATA_SOURCES_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const authorize = (provider, authType) => ({
  type   : actionTypes.AUTHORIZE,
  payload: {
    provider,
    authType,
  },
});

export const authorizeError = (error) => ({
  type: actionTypes.AUTHORIZE_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const connect = (authorizationCode, referenceId, dataSourceProvider) => ({
  type   : actionTypes.CONNECT,
  payload: {
    authorizationCode,
    referenceId,
    dataSourceProvider,
  },
});

export const connectSuccess = (connectedDataSource) => ({
  type   : actionTypes.CONNECT_SUCCESS,
  payload: {
    connectedDataSource,
  },
});

export const connectError = (error) => ({
  type: actionTypes.CONNECT_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const disconnect = (accountExternalDataSourceId) => ({
  type   : actionTypes.DISCONNECT,
  payload: {
    accountExternalDataSourceId,
  },
});

export const disconnectSuccess = (accountExternalDataSourceId) => ({
  type   : actionTypes.DISCONNECT_SUCCESS,
  payload: {
    accountExternalDataSourceId,
  },
});

export const disconnectError = (error) => ({
  type: actionTypes.DISCONNECT_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const sync = ({
  phiSet,
  phiSetDocumentId,
  accessToken,
  storageProvider,
  phiSetReferenceKey,
  successAction,
  successRelatedDataAction,
  externalDataSourcesIds,
  sharingRequestId,
  patientProfile,
  activeClinicMembership,
  standards,
  passphrase,
  activePatient,
  externalDataSourcesTokens,
  showErrorAlert,
}) => ({
  type   : actionTypes.SYNC,
  payload: {
    phiSet,
    phiSetDocumentId,
    accessToken,
    storageProvider,
    phiSetReferenceKey,
    successAction,
    successRelatedDataAction,
    externalDataSourcesIds,
    sharingRequestId,
    patientProfile,
    activeClinicMembership,
    standards,
    passphrase,
    activePatient,
    externalDataSourcesTokens,
    showErrorAlert,
  },
});

export const syncSuccess = (updatedPhiSet) => ({
  type   : actionTypes.SYNC_SUCCESS,
  payload: {
    updatedPhiSet,
  },
});

export const syncError = (error, errorResults, errorResultsRelated) => ({
  type: actionTypes.SYNC_ERROR,
  error,
  errorResults,
  errorResultsRelated,
});


//----------------------------------------------------------------------------------------------------------------------

export const storeReadings = ({
  importData, importId, accessToken,
}) => ({
  type   : actionTypes.STORE_READINGS,
  payload: {
    importData,
    importId,
    accessToken,
  },
});


export const storeReadingsSuccess = () => ({
  type: actionTypes.STORE_READINGS_SUCCESS,
});

export const storeReadingsError = (error) => ({
  type: actionTypes.STORE_READINGS_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const storeHealthData = ({
  treatmentType, diabetesType, accessToken,
}) => ({
  type   : actionTypes.STORE_HEALTH_DATA,
  payload: {
    treatmentType,
    diabetesType,
    accessToken,
  },
});

export const storeHealthDataSuccess = () => ({
  type: actionTypes.STORE_HEALTH_DATA_SUCCESS,
});

export const storeHealthDataError = (error) => ({
  type: actionTypes.STORE_HEALTH_DATA_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const fetchVault = () => ({
  type: actionTypes.FETCH_VAULT,
});

export const fetchVaultSuccess = (vault) => ({
  type   : actionTypes.FETCH_VAULT_SUCCESS,
  payload: {
    vault,
  },
});

export const fetchVaultError = (error) => ({
  type: actionTypes.FETCH_VAULT_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const storeToken = (externalDataSourceId, secret, controlId, scope) => ({
  type   : actionTypes.STORE_TOKEN,
  payload: {
    externalDataSourceId,
    secret,
    controlId,
    scope,
  },
});

export const storeTokenSuccess = (response) => ({
  type   : actionTypes.STORE_TOKEN_SUCCESS,
  payload: {
    response,
  },
});

export const storeTokenError = (error) => ({
  type: actionTypes.STORE_TOKEN_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const getAccessToken = (encryptedDataSourceExchangeToken, prvKeyObj) => ({
  type   : actionTypes.GET_ACCESS_TOKEN,
  payload: {
    encryptedDataSourceExchangeToken,
    prvKeyObj,
  },
});

export const getAccessTokenSuccess = (response) => ({
  type   : actionTypes.GET_ACCESS_TOKEN_SUCCESS,
  payload: {
    response,
  },
});

export const getAccessTokenError = (error) => ({
  type: actionTypes.GET_ACCESS_TOKEN_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const getPernamentRefreshToken = ({ externalDataSourceId, accountExternalDataSourceId }) => ({
  type   : actionTypes.GET_PERNAMENT_REFRESH_TOKEN,
  payload: {
    externalDataSourceId,
    accountExternalDataSourceId,
  },
});

export const getPernamentRefreshTokenSuccess = (response) => ({
  type   : actionTypes.GET_PERNAMENT_REFRESH_TOKEN_SUCCESS,
  payload: {
    response,
  },
});

export const getPernamentRefreshTokenError = (error) => ({
  type: actionTypes.GET_PERNAMENT_REFRESH_TOKEN_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const assignToken = (dataSourceExchangeToken, controlId, scope = 'Sharing') => ({
  type   : actionTypes.ASSIGN_TOKEN,
  payload: {
    dataSourceExchangeToken,
    controlId,
    scope,
  },
});

export const assignTokenSuccess = (response) => ({
  type   : actionTypes.ASSIGN_TOKEN_SUCCESS,
  payload: {
    response,
  },
});

export const assignTokenError = (error) => ({
  type: actionTypes.ASSIGN_TOKEN_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const setDataSourceExchangeToken = (externalDataSourceId, encryptedDataSourceExchangeToken) => ({
  type   : actionTypes.SET_DATA_SOURCE_EXCHANGE_TOKEN,
  payload: {
    externalDataSourceId,
    encryptedDataSourceExchangeToken,
  },
});

export const setDataSourceExchangeTokenSuccess = (externalDataSourceId, encryptedDataSourceExchangeToken) => ({
  type   : actionTypes.SET_DATA_SOURCE_EXCHANGE_TOKEN_SUCCESS,
  payload: {
    externalDataSourceId,
    encryptedDataSourceExchangeToken,
  },
});

export const setDataSourceExchangeTokenError = (error) => ({
  type: actionTypes.SET_DATA_SOURCE_EXCHANGE_TOKEN_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const reAuthorize = (provider, sharingRequests) => ({
  type   : actionTypes.REAUTHORIZE,
  payload: {
    provider,
    sharingRequests,
  },
});

export const reAuthorizeError = (error) => ({
  type: actionTypes.REAUTHORIZE_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------
