import { defineMessages } from 'react-intl';


export default defineMessages({

  scopes: {
    ClinicStaff : { id: 'account.scopes.ClinicStaff' },
    Professional: { id: 'account.scopes.Professional' },
    Personal    : { id: 'account.scopes.Personal' },
    Caregiver   : { id: 'account.scopes.Caregiver' },
  },

  headers: {
    acceptHcpInvitation  : { id: 'account.headers.acceptHcpInvitation' },
    cloudDrive           : { id: 'account.headers.cloudDrive' },
    deleteAccount        : { id: 'account.headers.deleteAccount' },
    deletePatientProfile : { id: 'account.headers.deletePatientProfile' },
    clinicsMemberships   : { id: 'account.headers.clinicsMemberships' },
    account              : { id: 'account.headers.account' },
    accountSettings      : { id: 'account.headers.accountSettings' },
    profileSettings      : { id: 'account.headers.profileSettings' },
    configuringClinic    : { id: 'account.headers.configuringClinic' },
    configuringProfile   : { id: 'account.headers.configuringProfile' },
    exportData           : { id: 'account.headers.exportData' },
    fillPersonalData     : { id: 'account.headers.fillPersonalData' },
    fillHealthInformation: { id: 'account.headers.fillHealthInformation' },
    hcpProfile           : { id: 'account.headers.hcpProfile' },
    patientProfile       : { id: 'account.headers.patientProfile' },
    cgProfile            : { id: 'account.headers.cgProfile' },
    rejectHcpInvitation  : { id: 'account.headers.rejectHcpInvitation' },
    resetPasswordSent    : { id: 'account.headers.resetPasswordSent' },
    resetPassword        : { id: 'account.headers.resetPassword' },
    setCloudDrive        : { id: 'account.headers.setCloudDrive' },
    setCgProfile         : { id: 'account.headers.setCgProfile' },
    setHcpProfile        : { id: 'account.headers.setHcpProfile' },
    setNewPassword       : { id: 'account.headers.setNewPassword' },
    settings             : { id: 'account.headers.settings' },
    signIn               : { id: 'account.headers.signIn' },
    signUp               : { id: 'account.headers.signUp' },
    confirmEmail         : { id: 'account.headers.confirmEmail' },
    confirmEmailSuccess  : { id: 'account.headers.confirmEmailSuccess' },
    confirmEmailFailed   : { id: 'account.headers.confirmEmailFailed' },
    confirmEmailExpired  : { id: 'account.headers.confirmEmailExpired' },
    legalConsent         : { id: 'account.headers.legalConsent' },
    connectToCCSetup     : { id: 'account.headers.connectToCCSetup' },
  },

  intros: {
    acceptHcpInvitation: { id: 'account.intros.acceptHcpInvitation' },
    deleteAccount      : { id: 'account.intros.deleteAccount' },
    exportData         : { id: 'account.intros.exportData' },
    resetPassword      : { id: 'account.intros.resetPassword' },
    resetPasswordSent  : { id: 'account.intros.resetPasswordSent' },
    setCloudDrive      : { id: 'account.intros.setCloudDrive' },
    legalConsent       : { id: 'account.intros.legalConsent' },
  },

  buttons: {
    accept                     : { id: 'account.buttons.accept' },
    addProfile                 : { id: 'account.buttons.addProfile' },
    back                       : { id: 'account.buttons.back' },
    cancelRecovery             : { id: 'account.buttons.cancelRecovery' },
    changePassword             : { id: 'account.buttons.changePassword' },
    changeAccountInformation   : { id: 'account.buttons.changeAccountInformation' },
    changeHcpProfile           : { id: 'account.buttons.changeHcpProfile' },
    changePatientProfile       : { id: 'account.buttons.changePatientProfile' },
    changeCgProfile            : { id: 'account.buttons.changeCgProfile' },
    cancel                     : { id: 'account.buttons.cancel' },
    discard                    : { id: 'account.buttons.discard' },
    close                      : { id: 'account.buttons.close' },
    confirm                    : { id: 'account.buttons.confirm' },
    collectData                : { id: 'account.buttons.collectData' },
    delete                     : { id: 'account.buttons.delete' },
    deleteAccount              : { id: 'account.buttons.deleteAccount' },
    deletePatientProfile       : { id: 'account.buttons.deletePatientProfile' },
    exportData                 : { id: 'account.buttons.exportData' },
    myResults                  : { id: 'account.buttons.myResults' },
    reauthorizeCloudDrive      : { id: 'account.buttons.reauthorizeCloudDrive' },
    resetPassword              : { id: 'account.buttons.resetPassword' },
    setNewPassword             : { id: 'account.buttons.setNewPassword' },
    reject                     : { id: 'account.buttons.reject' },
    signIn                     : { id: 'account.buttons.signIn' },
    signOut                    : { id: 'account.buttons.signOut' },
    logout                     : { id: 'account.buttons.logout' },
    signUp                     : { id: 'account.buttons.signUp' },
    save                       : { id: 'account.buttons.save' },
    submit                     : { id: 'account.buttons.submit' },
    submitDelete               : { id: 'account.buttons.submitDelete' },
    confirmEmailSuccessContinue: { id: 'account.buttons.confirmEmailSuccessContinue' },
    confirmEmailFailedResend   : { id: 'account.buttons.confirmEmailFailedResend' },
    confirmEmailFailedSupport  : { id: 'account.buttons.confirmEmailFailedSupport' },
    confirmEmail               : { id: 'account.buttons.confirmEmail' },
    confirmEmailExpiredResend  : { id: 'account.buttons.confirmEmailExpiredResend' },
    backToHcpProfile           : { id: 'account.buttons.backToHcpProfile' },
    reauthContourCloud         : { id: 'account.buttons.reauthContourCloud' },
  },

  labels: {
    accountType           : { id: 'account.labels.accountType' },
    areYouDiabetic        : { id: 'account.labels.areYouDiabetic' },
    city                  : { id: 'account.labels.city' },
    clinic                : { id: 'account.labels.clinic' },
    confirmPassword       : { id: 'account.labels.confirmPassword' },
    country               : { id: 'account.labels.country' },
    lastSuccessfulLogin   : { id: 'account.labels.lastSuccessfulLogin' },
    lastLoginAttempt      : { id: 'account.labels.lastLoginAttempt' },
    diabetesType          : { id: 'account.labels.diabetesType' },
    email                 : { id: 'account.labels.email' },
    firstDayOfWeek        : { id: 'account.labels.firstDayOfWeek' },
    firstName             : { id: 'account.labels.firstName' },
    lastName              : { id: 'account.labels.lastName' },
    gender                : { id: 'account.labels.gender' },
    female                : { id: 'account.labels.female' },
    male                  : { id: 'account.labels.male' },
    identityCard          : { id: 'account.labels.identityCard' },
    identityCardFront     : { id: 'account.labels.identityCardFront' },
    identityCardBack      : { id: 'account.labels.identityCardBack' },
    language              : { id: 'account.labels.language' },
    height                : { id: 'account.labels.height' },
    weight                : { id: 'account.labels.weight' },
    hcpNationalId         : { id: 'account.labels.hcpNationalId' },
    isAdmin               : { id: 'account.labels.isAdmin' },
    leave                 : { id: 'account.labels.leave' },
    nationalId            : { id: 'account.labels.nationalId' },
    payer                 : { id: 'account.labels.payer' },
    password              : { id: 'account.labels.password' },
    oldPassword           : { id: 'account.labels.oldPassword' },
    newPassword           : { id: 'account.labels.newPassword' },
    recoveryCode          : { id: 'account.labels.recoveryCode' },
    rememberMe            : { id: 'account.labels.rememberMe' },
    settings              : { id: 'account.labels.settings' },
    status                : { id: 'account.labels.status' },
    storageProvider       : { id: 'account.labels.storageProvider' },
    termsAgreeToAscensia  : { id: 'account.labels.termsAgreeToAscensia' },
    termsOfService        : { id: 'account.labels.termsOfService' },
    treatmentType         : { id: 'account.labels.treatmentType' },
    hcp                   : { id: 'account.labels.hcp' },
    pwd                   : { id: 'account.labels.pwd' },
    cg                    : { id: 'account.labels.cg' },
    contourCloud          : { id: 'account.labels.contourCloud' },
    loginByContourCloud   : { id: 'account.labels.loginByContourCloud' },
    useContourCloudApp    : { id: 'account.labels.useContourCloudApp' },
    changeContourCloudPass: { id: 'account.labels.changeContourCloudPass' },
    warningInfoCCTitle    : { id: 'account.labels.warningInfoCCTitle' },
    warningInfoCCSubTitle : { id: 'account.labels.warningInfoCCSubTitle' },
    registrationPrivacy   : { id: 'account.labels.registrationPrivacy' },
    marketingConsents     : { id: 'account.labels.marketingConsents' },
    marketingConsentsInfo : { id: 'account.labels.marketingConsentsInfo' },
    sensitiveDataProfiling: { id: 'account.labels.sensitiveDataProfiling' },
    consetPhone           : { id: 'account.labels.consetPhone' },
    consetMail            : { id: 'account.labels.consetMail' },
    consetEmail           : { id: 'account.labels.consetEmail' },
    consetSms             : { id: 'account.labels.consetSms' },
    consetSensitive       : { id: 'account.labels.consetSensitive' },
    units                 : { id: 'account.labels.units' },
  },

  placeholders: {
    city           : { id: 'account.placeholders.city' },
    confirmPassword: { id: 'account.placeholders.confirmPassword' },
    country        : { id: 'account.placeholders.country' },
    diabetesType   : { id: 'account.placeholders.diabetesType' },
    email          : { id: 'account.placeholders.email' },
    firstDayOfWeek : { id: 'account.placeholders.firstDayOfWeek' },
    firstName      : { id: 'account.placeholders.firstName' },
    lastName       : { id: 'account.placeholders.lastName' },
    language       : { id: 'account.placeholders.language' },
    height         : { id: 'account.placeholders.height' },
    weight         : { id: 'account.placeholders.weight' },
    nationalId     : { id: 'account.placeholders.nationalId' },
    payer          : { id: 'account.placeholders.payer' },
    password       : { id: 'account.placeholders.password' },
    treatmentType  : { id: 'account.placeholders.treatmentType' },
  },

  fieldsInfos: {
    password: { id: 'account.fieldsInfos.password' },
  },

  infos: {
    hcp                                : { id: 'account.infos.hcp' },
    pwd                                : { id: 'account.infos.pwd' },
    cg                                 : { id: 'account.infos.cg' },
    backTo                             : { id: 'account.infos.backTo' },
    collectData                        : { id: 'account.infos.collectData' },
    waitingForDataCollected            : { id: 'account.infos.waitingForDataCollected' },
    dataCollected                      : { id: 'account.infos.dataCollected' },
    dataSaved                          : { id: 'account.infos.dataSaved' },
    hcpInvitation                      : { id: 'account.infos.hcpInvitation' },
    haveAccount                        : { id: 'account.infos.haveAccount' },
    noAccount                          : { id: 'account.infos.noAccount' },
    enterPasswordToAllow               : { id: 'account.infos.enterPasswordToAllow' },
    enterPasswordToDeleteAccount       : { id: 'account.infos.enterPasswordToDeleteAccount' },
    enterPasswordToDeletePatientProfile: { id: 'account.intros.enterPasswordToDeletePatientProfile' },
    confirmEmail                       : { id: 'account.infos.confirmEmail' },
    confirmEmailPwd                    : { id: 'account.infos.confirmEmailPwd' },
    confirmEmailSuccess                : { id: 'account.infos.confirmEmailSuccess' },
    confirmEmailFailed                 : { id: 'account.infos.confirmEmailFailed' },
    confirmEmailExpired                : { id: 'account.infos.confirmEmailExpired' },
    connectToCC                        : { id: 'account.infos.connectToCC' },
    reauthToCC                         : { id: 'account.infos.reauthToCC' },
  },

  nav: {
    clinics       : { id: 'account.nav.clinics' },
    dataSources   : { id: 'account.nav.dataSources' },
    patientProfile: { id: 'account.nav.patientProfile' },
    settings      : { id: 'account.nav.settings' },
  },

  alerts: {
    cloudDriveReauthorized: { id: 'account.alerts.cloudDriveReauthorized' },
  },

  errors: {
    businessErrors: {
      CannotDeleteLastAdmin                   : { id: 'account.errors.businessErrors.CannotDeleteLastAdmin' },
      HcpHasPatients                          : { id: 'account.errors.businessErrors.HcpHasPatients' },
      InvalidPassphrase                       : { id: 'account.errors.businessErrors.InvalidPassphrase' },
      InvalidRecoveryCode                     : { id: 'account.errors.businessErrors.InvalidRecoveryCode' },
      UserOrPasswordIncorrect                 : { id: 'account.errors.businessErrors.UserOrPasswordIncorrect' },
      UserNotAgreedToTermsOfService           : { id: 'account.errors.businessErrors.UserNotAgreedToTermsOfService' },
      MailAlreadyExist                        : { id: 'account.errors.businessErrors.MailAlreadyExist' },
      AuthorizationCodeHasExpired             : { id: 'account.errors.businessErrors.AuthorizationCodeHasExpired' },
      AuthorizationCodeNotFound               : { id: 'account.errors.businessErrors.AuthorizationCodeNotFound' },
      ClinicCloudDriveConnectionFailed        : { id: 'account.errors.businessErrors.ClinicCloudDriveConnectionFailed' },
      ClinicCloudDriveConnectionFailedForAdmin: { id: 'account.errors.businessErrors.ClinicCloudDriveConnectionFailedForAdmin' },
      PatientCloudDriveConnectionFailed       : { id: 'account.errors.businessErrors.PatientCloudDriveConnectionFailed' },
      GoogleApiError                          : { id: 'account.errors.businessErrors.GoogleApiError' },
      RefreshTokenNotFound                    : { id: 'account.errors.businessErrors.RefreshTokenNotFound' },
      TokenWrongFormat                        : { id: 'account.errors.businessErrors.TokenWrongFormat' },
      TokenExpired                            : { id: 'account.errors.businessErrors.TokenExpired' },
      InvitationNotFound                      : { id: 'account.errors.businessErrors.InvitationNotFound' },
      InvitationAlreadyReceivedOrCancelled    : { id: 'account.errors.businessErrors.InvitationAlreadyReceivedOrCancelled' },
      AccountNotFound                         : { id: 'account.errors.businessErrors.AccountNotFound' },
      AccountNotHasHcpProfile                 : { id: 'account.errors.businessErrors.AccountNotHasHcpProfile' },
      AccountDoesntHaveHcpType                : { id: 'account.errors.businessErrors.AccountDoesntHaveHcpType' },
      InvitationNotInReceivedState            : { id: 'account.errors.businessErrors.InvitationNotInReceivedState' },
      WrongPassword                           : { id: 'account.errors.businessErrors.WrongPassword' },
      CCAccountNotActivated                   : { id: 'account.errors.businessErrors.CCAccountNotActivated' },
      CCUserAlreadyExists                     : { id: 'account.errors.businessErrors.CCUserAlreadyExists' },
      UserHasToChangePassword                 : { id: 'account.errors.businessErrors.UserHasToChangePassword' },
      ContourCloudNotRespond                  : { id: 'account.errors.businessErrors.ContourCloudNotRespond' },
      MembershipAlreadyExists                 : { id: 'account.errors.businessErrors.MembershipAlreadyExists' },
      NoProfilesReferenceKey                  : { id: 'account.errors.businessErrors.NoProfilesReferenceKey' },
      NoStorageProvider                       : { id: 'account.errors.businessErrors.NoStorageProvider' },
    },
  },

  statuses: {
    Pending: { id: 'account.statuses.Pending' },
    Active : { id: 'account.statuses.Active' },
    Revoked: { id: 'account.statuses.Revoked' },
  },
});
