import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import Account from 'modules/Account';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import * as shapes from '../../shapes';
import messages from '../../messages';
import { hasMismatchBetweenSummaryAndStandards, hasMismatchBetweenTimes } from './helpers';
import styles from './DownloadDataModal.pcss';


class Downloading extends React.PureComponent {


  static propTypes = {
    // Explicit props
    connectorType    : shapes.connectorType,
    phiSet           : PropTypes.object,
    activeVisit      : PropTypes.shape({ phisetVisitId: PropTypes.string.isRequired }),
    standards        : PropTypes.object,
    // Explicit actions
    onCancel         : PropTypes.func,
    onSetComponent   : PropTypes.func,
    onAddWarning     : PropTypes.func,
    // Implicit props
    deviceData       : PropTypes.object,
    connectionId     : PropTypes.string,
    connectionStatus : shapes.connectionStatus,
    activeProfileType: Account.shapes.profileType,
    // Implicit actions
    onGetDeviceData  : PropTypes.func,
  };


  componentDidMount() {
    this.onConnectionStatusChange();
  }


  componentDidUpdate(prevProps) {
    const { deviceData, connectionStatus, standards } = this.props;
    if (prevProps.connectionStatus !== connectionStatus) {
      this.onConnectionStatusChange();
    }

    if (prevProps.deviceData !== deviceData) {
      const { serialNumber, summary, deviceDate } = deviceData;
      let shouldShowWarning = false;

      if (hasMismatchBetweenSummaryAndStandards(summary, standards)) {
        shouldShowWarning = true;
        this.props.onAddWarning(constants.WARNING_TYPES.MISMATCH_TARGET_RANGES);
      }

      if (!deviceDate) {
        shouldShowWarning = true;
        this.props.onAddWarning(constants.WARNING_TYPES.UNAVAILABLE_TIME);
      } else if (hasMismatchBetweenTimes(moment(), moment(deviceDate))) {
        shouldShowWarning = true;
        this.props.onAddWarning(constants.WARNING_TYPES.MISMATCH_TIME);
      }

      if (this.props.phiSet && this.isPwdProfile) {
        if (this.props.phiSet.imports) {
          const importedDevices = Object.keys(this.props.phiSet.imports);
          if (!importedDevices.includes(serialNumber)) {
            shouldShowWarning = true;
            this.props.onAddWarning(constants.WARNING_TYPES.NEW_DEVICE_CONNECTED);
          }
        } else {
          shouldShowWarning = true;
          this.props.onAddWarning(constants.WARNING_TYPES.NEW_DEVICE_CONNECTED);
        }
      }

      if (shouldShowWarning) {
        this.props.onSetComponent('WarningsBeforeDownloadData');
      } else {
        this.props.onSetComponent('Results');
      }
    }
  }


  onConnectionStatusChange() {
    const { connectorType, connectionId, connectionStatus, activeVisit } = this.props;
    if (connectionStatus === constants.CONNECTION_STATUSES.CANCELED) {
      this.props.onCancel();
      return;
    }
    if (connectionStatus === constants.CONNECTION_STATUSES.TIMEOUT) {
      this.props.onSetComponent('Timeout');
      return;
    }
    if (connectionStatus === constants.CONNECTION_STATUSES.SUCCESS) {
      this.props.onGetDeviceData(connectorType, connectionId, activeVisit);
    }
  }


  get isPwdProfile() {
    return this.props.activeProfileType === Account.constants.PROFILE_TYPES.PWD;
  }


  render() {
    return (
      <div className={styles.modal__content}>
        <h3 className="modal__subheader "><FormattedMessage {...messages.downloading.header} /></h3>
        {/* <p className="modal__info"><FormattedMessage {...messages.downloading.info} /></p> */}
        <div className={styles.illustration}>
          <img src="/assets/svg/loader.svg" className="rotatingLoader" alt="" />
        </div>
        <p className="modal__info text--center mb-5"><FormattedMessage {...messages.downloading.waiting} /></p>
      </div>
    );
  }

}

const mapStateToProps = (state) => {
  const activeProfileType = Account.selectors.activeProfileType(state);
  return {
    deviceData      : selectors.deviceData(state),
    connectionId    : selectors.connectionId(state),
    connectionStatus: selectors.connectionStatus(state),
    activeProfileType,
  };
};


const mapDispatchToProps = (dispatch) => ({
  onGetDeviceData: (connectorType, connectionId, visit) => dispatch(
    actions.getDeviceData(connectorType, connectionId, visit),
  ),
});


const ConnectedDownloading = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Downloading);


export default ConnectedDownloading;
