import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import { AppContext } from 'context';
import Button from 'components/Form/Button';
import intlShape from 'shapes/intlShape';
import QuestionMark from 'svg/question-mark.svg';
import messages from '../../messages';
import styles from '../../components/DownloadDataModal/DownloadDataModal.pcss';


class ConnectionFailed extends React.PureComponent {

  static contextType = AppContext;

  static propTypes = {
    // Implicit props
    intl           : intlShape.isRequired,
    // Explicit actions
    onSetComponent : PropTypes.func,
    onSetAgainPath : PropTypes.func,
    // Explicit props
    selectedChannel: PropTypes.string,
  };


  onRetry() {
    this.props.onSetComponent('Initializing');
    this.props.onSetAgainPath(true);
  }


  renderImage() {
    let imageSrc;
    switch (this.props.selectedChannel) {
      case 'BuiltInUsb':
        imageSrc = '/assets/svg/disconnect-by-usb.svg';
        break;
      case 'MicroUsb':
        imageSrc = '/assets/svg/disconnect-by-usb-cable.svg';
        break;
      case 'UsbC':
        imageSrc = '/assets/svg/disconnect-by-usb-c-cable.svg';
        break;
      case 'BlueCable':
        imageSrc = '/assets/svg/disconnect-by-blue-cable.svg';
        break;
      case 'Bluetooth':
        imageSrc = '/assets/svg/disconnect-by-bluetooth.svg';
        break;
      default:
        imageSrc = '/assets/svg/connection-failed.svg';
        break;
    }

    return (
      <div className={styles.illustration}>
        <img src={imageSrc} className={styles.connectionFailed__img} alt="" />
      </div>
    );
  }


  render() {
    return (
      <div className={styles.modal__content}>
        <h3 className="modal__subheader "><FormattedMessage {...messages.connectionFailed.header} /></h3>
        <p className="modal__info"><FormattedMessage {...messages.connectionFailed.info} /></p>
        <ul className={styles.connectionFailed__list}>
          <li className={styles.connectionFailed__list__item}>
            <div className={styles.connectionFailed__list__item__content}>
              <p><FormattedMessage {...messages.connectionFailed.steps.first} /></p>
            </div>
          </li>
          <li className={styles.connectionFailed__list__item}>
            <div className={styles.connectionFailed__list__item__content}>
              <p><FormattedMessage {...messages.connectionFailed.steps.second} />
                <a
                  href="https://support.glucocontro.online/hc"
                  target="_blank"
                  data-for="userBoxTooltip"
                  data-tip={this.props.intl.formatMessage(messages.connectionFailed.questionSteps.second)}
                >
                  <QuestionMark className={styles.connectionFailed__questionMark} />
                </a>
              </p>
            </div>
          </li>
          <li className={styles.connectionFailed__list__item}>
            <div className={styles.connectionFailed__list__item__content}>
              <p><FormattedMessage {...messages.connectionFailed.steps.third} /></p>
            </div>
          </li>
        </ul>
        { this.renderImage() }
        <div className="modal__actions">
          <Button
            styleModifier="primary"
            labelMessage={messages.buttons.tryAgain}
            className="btn--block btn--filled"
            onClick={() => this.onRetry()}
          />
        </div>
        <ReactTooltip
          id="userBoxTooltip"
          place="right"
          type="dark"
          effect="solid"
        />
      </div>
    );
  }

}


export default injectIntl(ConnectionFailed);
