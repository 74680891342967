import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { AppContext } from 'context';
import Button from 'components/Form/Button';
import * as actions from '../../actions';
import messages from '../../messages';
import styles from '../../components/DownloadDataModal/DownloadDataModal.pcss';


class NoConnection extends React.PureComponent {

  static contextType = AppContext;

  static propTypes = {
    // Explicit actions
    onSetComponent     : PropTypes.func,
    // Implicit actions
    onInstallDownloader: PropTypes.func,
  };


  onInstall() {
    this.props.onSetComponent('InstallingDownloader');
    this.props.onInstallDownloader();
  }


  render() {
    return (
      <div className={styles.modal__content}>
        <h3 className="modal__subheader "><FormattedMessage {...messages.noConnection.header} /></h3>
        <p className="modal__info"><FormattedMessage {...messages.noConnection.info} /></p>
        <div className={styles.illustration}>
          <img src="/assets/svg/no-connection.svg" className="w-100" alt="" />
        </div>
        <div className="modal__actions">
          <Button
            type="link"
            to={this.context.downloader.installUrl}
            styleModifier="primary"
            labelMessage={messages.buttons.runReinstallDownloader}
            className="btn--block btn--filled"
            onClick={() => this.onInstall()}
          />
        </div>
      </div>
    );
  }

}


const mapDispatchToProps = (dispatch) => ({
  onInstallDownloader: () => dispatch(actions.installDownloader()),
});


const ConnectedNoConnection = connect(
  null,
  mapDispatchToProps,
)(NoConnection);


export default ConnectedNoConnection;
