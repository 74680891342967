import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import find from 'lodash/find';
import get from 'lodash/get';
import includes from 'lodash/includes';
import cn from 'classnames';
import { AppContext } from 'context';
import history from 'helpers/history';
import { encrypt, getKeyFromPem } from 'helpers/crypto';
import { getSlug } from 'helpers/urlTools';
import { getStandards } from 'helpers/settings';
import Avatar from 'components/Avatar';
import Button from 'components/Form/Button';
import Loader from 'svg/loader.svg';
import ExclamationNegative from 'svg/exclamation-negative.svg';
import countrySettingsShape from 'shapes/countrySettingsShape';
import App from 'modules/App';
import Account from 'modules/Account';
import CloudDrive from 'modules/CloudDrive';
import DataSources from 'modules/DataSources';
import Notifications from 'modules/Notifications';
import Hcp from 'modules/Hcp';
import Patient from 'modules/Patient';
import Statistics from 'modules/Statistics';
import * as actions from '../../actions';
import * as shapes from '../../shapes';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import messages from '../../messages';
import ResultsContentCgm from './ResultsContentCgm';
import styles from './DownloadDataModal.pcss';


class Results extends React.PureComponent {

  static contextType = AppContext;


  static getDerivedStateFromProps(props, state) {
    const { patientProfile, isInProgress } = props;
    if (patientProfile && !state.isInitialized) {
      return { isInitialized: true };
    }

    if (isInProgress !== state.isInProgress) {
      return {
        isInProgress,
      };
    }
    return null;
  }


  static propTypes = {
    // Explicit props
    activeClinicMembership: Account.shapes.clinicMembership,
    activeVisit           : PropTypes.object,
    // Explicit actions
    onCancel              : PropTypes.func.isRequired,
    onSetComponent        : PropTypes.func,
    // Implicit props
    deviceData            : PropTypes.object,
    deviceDataType        : shapes.deviceDataType,
    account               : Account.shapes.account,
    activeProfileType     : Account.shapes.profileType,
    patientProfile        : PropTypes.shape({
      id                       : PropTypes.string, // only for clinic patient
      firstName                : PropTypes.string.isRequired,
      lastName                 : PropTypes.string.isRequired,
      phiSetReferenceKey       : PropTypes.string.isRequired,
      accessToken              : PropTypes.object.isRequired,
      storageProvider          : PropTypes.string.isRequired,
      devicesSerialNumberTokens: PropTypes.arrayOf(PropTypes.string),
    }),
    patients: PropTypes.arrayOf(PropTypes.shape({
      id                       : PropTypes.string.isRequired,
      devicesSerialNumberTokens: PropTypes.arrayOf(PropTypes.string),
    })),
    passphrase               : PropTypes.string,
    phiSet                   : PropTypes.object, // @TODO: Shape
    phiSetDocumentId         : PropTypes.string,
    isInProgress             : PropTypes.bool,
    hasErrors                : PropTypes.bool,
    isFetchPhiSetInProgress  : PropTypes.bool,
    hasFetchPhiSetErrors     : PropTypes.bool,
    isFetchReadingsInProgress: PropTypes.bool,
    hasFetchReadingsErrors   : PropTypes.bool,
    isSyncInProgress         : PropTypes.bool,
    isHCP                    : PropTypes.bool,
    countrySettings          : countrySettingsShape,
    // Implicit actions
    onImport                 : PropTypes.func.isRequired,
    onCgmImport              : PropTypes.func.isRequired,
    onLogEvent               : PropTypes.func.isRequired,
    onPreviewPatient         : PropTypes.func.isRequired,
    onSendClinicNotification : PropTypes.func.isRequired,
    onSendStats              : PropTypes.func.isRequired,
    onSendStatsForClinic     : PropTypes.func.isRequired,
    onUpdateProfile          : PropTypes.func.isRequired,
    onGetSerialNumberToken   : PropTypes.func.isRequired,
    onOpenModal              : PropTypes.func.isRequired,
  };


  constructor(props) {
    super(props);
    this.state = {
      isInitialized    : !!props.patientProfile,
      isInProgress     : false,
      afterDeviceImport: false,
    };

    this.totalReadingsCount = get(props.phiSet, 'summaryData.totalReadingsCount');

    const { deviceData } = this.props;
    if (!deviceData) {
      return;
    }
    props.onGetSerialNumberToken(deviceData);
  }


  componentDidMount() {
    this.props.onLogEvent();
  }


  componentDidUpdate(prevProps) {
    const { deviceData, activeVisit, isInProgress, hasErrors, phiSet } = this.props;
    if (phiSet && !prevProps.phiSet) {
      this.totalReadingsCount = get(phiSet, 'summaryData.totalReadingsCount');
    }
    const { isPwdProfile } = this;
    if (!isPwdProfile && !activeVisit && prevProps.deviceData !== deviceData && deviceData) {
      const prevDeviceSerialNumberToken = get(prevProps.deviceData, 'deviceSerialNumberToken');
      const deviceSerialNumberToken = get(deviceData, 'deviceSerialNumberToken');
      if (prevDeviceSerialNumberToken !== deviceSerialNumberToken && deviceSerialNumberToken) {
        this.onSetUser(deviceSerialNumberToken);
      }
    }
    if (prevProps.isInProgress !== isInProgress && !isInProgress && !hasErrors && this.state.afterDeviceImport) {
      const updatedTotalReadingsCount = get(phiSet, 'summaryData.totalReadingsCount');
      if (!isPwdProfile && updatedTotalReadingsCount > this.totalReadingsCount) {
        this.onSendClinicNotification(updatedTotalReadingsCount - this.totalReadingsCount);
      }
      this.onSendStats();
      this.props.onCancel();
    }
  }


  onSetUser(deviceSerialNumberToken) {
    const deviceUser = find(
      this.props.patients,
      (patient) => includes(patient.devicesSerialNumberTokens, deviceSerialNumberToken),
    );
    const currentPatientId = get(this.props.patientProfile, 'id');
    if (deviceUser && deviceUser.id !== currentPatientId) {
      const { organizationUID, name } = get(this.props.activeClinicMembership, 'clinic', {});
      const clinicSlug = getSlug(name);
      const patientSlug = getSlug(`${deviceUser.firstName} ${deviceUser.lastName}`);
      const url = this.context.getUrl(
        'hcp-results',
        { clinicSlug, organizationUID, patientSlug, patientId: deviceUser.id },
      );
      history.push(url);
    } else {
      this.setState({ isInitialized: true });
    }
  }


  onSendClinicNotification(readingsCount) {
    const { activeClinicMembership, patientProfile } = this.props;
    const { clinicId, name: clinicName, publicKey } = activeClinicMembership.clinic;
    const { id, firstName, lastName } = patientProfile;
    const pubKeyObj = getKeyFromPem(publicKey);
    const encryptedClinicPatientProfileId = encrypt(id, pubKeyObj);
    this.props.onSendClinicNotification({
      clinicId,
      clinicName,
      patientFirstName: firstName,
      patientLastName : lastName,
      encryptedClinicPatientProfileId,
      readingsCount,
    });
  }


  onSendStats() {
    const {
      deviceData, account, activeClinicMembership, passphrase, patientProfile, phiSet, countrySettings,
    } = this.props;
    let standards = getStandards(phiSet, countrySettings);
    if (this.isPwdProfile) {
      return this.props.onSendStats({ ...patientProfile, ...account }, passphrase, phiSet, deviceData, standards);
    }
    const clinicSettings = get(activeClinicMembership, 'clinic.settings');
    standards = getStandards(phiSet, countrySettings, clinicSettings);
    return this.props.onSendStatsForClinic(patientProfile, phiSet, deviceData, standards, activeClinicMembership);
  }


  onImport(importData) {
    this.setState({ isInProgress: true, afterDeviceImport: true });
    const { activeClinicMembership, patientProfile, phiSet, phiSetDocumentId } = this.props;

    if (!this.isPwdProfile) {
      const { deviceSerialNumberToken } = importData;
      const serialNumberTokens = get(patientProfile, 'devicesSerialNumberTokens') || [];
      if (!includes(serialNumberTokens, deviceSerialNumberToken)) {
        const devicesSerialNumberTokens = [...serialNumberTokens, deviceSerialNumberToken];
        this.props.onUpdateProfile(patientProfile, { devicesSerialNumberTokens }, activeClinicMembership);
      }
    }

    const successAction = this.isPwdProfile
      ? Patient.actions.onStoreReadingsSuccess
      : Hcp.actions.setImportedReadings;


    switch (this.props.deviceDataType) {
      case constants.DEVICE_DATA_TYPES.BGM: {
        this.props.onImport(importData, phiSet, phiSetDocumentId, patientProfile, successAction);
        break;
      }
      case constants.DEVICE_DATA_TYPES.CGM: {
        this.props.onCgmImport(importData, phiSet, phiSetDocumentId, patientProfile, successAction);
        break;
      }
      default: break;
    }
  }


  onPreviewPatient() {
    this.props.onPreviewPatient(this.props.deviceData, this.clinicSlug, this.organizationUID);
  }


  get clinicSlug() {
    const { name } = get(this.props.activeClinicMembership, 'clinic', {});
    const clinicSlug = getSlug(name);
    return clinicSlug;
  }


  get organizationUID() {
    const { organizationUID } = get(this.props.activeClinicMembership, 'clinic', {});
    return organizationUID;
  }


  get isPwdProfile() {
    return this.props.activeProfileType === Account.constants.PROFILE_TYPES.PWD;
  }


  get isProfileActivationInProgress() {
    return !this.state.isInitialized
      || this.props.isFetchPhiSetInProgress
      || this.props.isFetchReadingsInProgress
      || this.props.isSyncInProgress;
  }


  get isDisabled() {
    const deviceSerialNumberToken = get(this.props.deviceData, 'deviceSerialNumberToken');
    return !this.props.patientProfile
      || !deviceSerialNumberToken
      || this.isProfileActivationInProgress
      || this.props.hasFetchPhiSetErrors
      || this.props.hasFetchReadingsErrors;
  }


  renderPatientData(patientProfile) {
    if (this.isProfileActivationInProgress) {
      return (
        <Loader className={`${styles.results__patientLoader} rotatingLoader`} />
      );
    }

    if (!patientProfile) {
      return (
        <div className={styles.results__noPatient}>
          <ExclamationNegative className={styles.results__noPatient__icon} />
          <span><FormattedMessage {...messages.labels.noPatientMatched} /></span>
        </div>
      );
    }

    const { avatar, firstName, lastName } = patientProfile;
    return (
      <div className="row align-items-center">
        <div className="col-auto pr-0">
          <Avatar
            avatarImg={avatar}
            name={[firstName, lastName]}
            className={styles.results__avatar}
            imgClassName={styles.results__avatar__img}
            initialsClassName={styles.results__avatar__initials}
          />
        </div>
        <div className="col">
          <p data-hj-suppress className={styles.results__patient__name}>{ firstName } { lastName }</p>
        </div>
      </div>
    );
  }


  renderChoosePatient(patientProfile) {
    if (this.isPwdProfile || this.props.activeVisit) {
      return null;
    }
    return (
      <div className="col text--right">
        <Button
          styleModifier="transparent"
          labelMessage={patientProfile ? messages.buttons.changePatient : messages.buttons.choosePatient}
          className="btn--no-size text--primary m-0"
          onClick={() => this.props.onSetComponent('ChoosePatient')}
          id="choosePatient"
        />
      </div>
    );
  }


  renderPreviewData() {
    if (!this.props.isHCP) return null;
    return (
      <>
        <div className={cn(styles.divider, 'mt-0')}>
          <span className={styles.divider__text}><FormattedMessage {...messages.infos.or} /></span>
        </div>
        <div className="row justify-content-center">
          <Button
            styleModifier="transparent"
            labelMessage={messages.buttons.previewResults}
            className="btn--no-size text--primary m-0 pl-2 pr-2"
            onClick={() => this.onPreviewPatient()}
          />
        </div>
      </>
    );
  }


  renderInfo() {
    if (!this.props.isHCP) return null;
    const b = (chunk) => <b>{ chunk }</b>;

    return (
      <p className="brand__paragraph mt-0 mb-8">
        <FormattedMessage {...messages.infos.patientsResults} values={{ b }} />
      </p>
    );
  }


  renderPatient() {
    const { patientProfile } = this.props;
    return (
      <div className={styles.results__patient}>
        <div className={styles.results__patient__inner}>
          <div className="col">{ this.renderPatientData(patientProfile) }</div>
          <div className="col">{ this.renderChoosePatient(patientProfile) }</div>
        </div>
      </div>
    );
  }


  renderSummary() {
    const { deviceData } = this.props;
    return (
      <div className="row no-gutters align-items-center mt-6">
        <div className="col p-6 mr-6">
          <h3 className="text--small text--bold mb-3">{ deviceData.deviceName }</h3>
          <p className="text--small text--light text--nowrap">SN: { deviceData.serialNumber }</p>
        </div>
        <div className={`col-auto p-6 ${styles.highlightedRegion}`}>
          <p className="row no-gutters align-items-center">
            <span className="col mr-4"><FormattedMessage {...messages.labels.readingsNumber} /></span>
            <span className="col-auto text--large text--bold">{ deviceData.summary.readingsCount }</span>
          </p>
        </div>
      </div>
    );
  }


  renderResultsTableSubSegment(renderSubSegment) {
    if (!renderSubSegment) {
      return null;
    }
    return (
      <div className={styles.strippedTable__subSegment}>
        { renderSubSegment() }
      </div>
    );
  }


  renderResultsTableSegment(labelMessage, value, { icon, renderSubSegment } = {}) {
    return (
      <div className={styles.strippedTable__segment}>
        <div className="row no-gutters justify-content-between px-5 py-3">
          <div className="d-flex align-items-center">{ icon }<FormattedMessage {...labelMessage} /></div>
          <div>{ value }</div>
        </div>
        { this.renderResultsTableSubSegment(renderSubSegment) }
      </div>
    );
  }


  renderBgmTargetGlucoseCount() {
    const { deviceData } = this.props;
    const lowIcon = <i className={`bg--error ${styles.results__disc}`} />;
    const targetIcon = <i className={`bg--success ${styles.results__disc}`} />;
    const highIcon = <i className={`bg--warning ${styles.results__disc}`} />;
    return (
      <div className={styles.strippedTable}>
        { this.renderResultsTableSegment(messages.labels.low, deviceData.summary.lowCount, { icon: lowIcon }) }
        { this.renderResultsTableSegment(messages.labels.target, deviceData.summary.targetCount, { icon: targetIcon }) }
        { this.renderResultsTableSegment(messages.labels.high, deviceData.summary.highCount, { icon: highIcon }) }
      </div>
    );
  }


  renderBgmCount() {
    const { deviceData } = this.props;
    if (!deviceData.summary.highNorm || !deviceData.summary.lowNorm) {
      return null;
    }
    return (
      this.renderResultsTableSegment(messages.labels.bgm, deviceData.summary.readingsCount, {
        renderSubSegment: () => this.renderBgmTargetGlucoseCount(),
      })
    );
  }


  renderResultsCount() {
    const { deviceData } = this.props;
    if (!deviceData.summary.highNorm || !deviceData.summary.lowNorm) {
      return null;
    }
    return (
      <div className={`${styles.strippedTable} mt-6`}>
        { this.renderBgmCount() }
      </div>
    );
  }


  renderActions() {
    const { deviceData } = this.props;
    return (
      <div>
        <div className="row mt-5">

          <div className="col-6">
            <Button
              styleModifier="primary"
              labelMessage={messages.buttons.cancelImport}
              className="btn--block"
              isDisabled={this.state.isInProgress}
              onClick={this.props.onCancel}
            />
          </div>
          <div className="col-6">
            <Button
              styleModifier="primary"
              labelMessage={messages.buttons.importResults}
              className="btn--block btn--filled"
              isDisabled={!deviceData.summary.readingsCount || this.isDisabled}
              isInProgress={this.state.isInProgress}
              onClick={() => this.onImport(deviceData)}
            />
          </div>
        </div>
        { this.renderPreviewData() }
      </div>
    );
  }


  renderBgmContent() {
    return (
      <div>
        { this.renderSummary() }
        { this.renderResultsCount() }
        { this.renderActions() }
      </div>
    );
  }


  renderCgmContent() {
    return (
      <ResultsContentCgm
        deviceData={this.props.deviceData}
        isInProgress={this.state.isInProgress}
        isDisabled={this.isDisabled}
        onCancel={this.props.onCancel}
        onImport={(data) => this.onImport(data)}
      />
    );
  }


  renderContent() {
    switch (this.props.deviceDataType) {
      case constants.DEVICE_DATA_TYPES.BGM: return this.renderBgmContent();
      case constants.DEVICE_DATA_TYPES.CGM: return this.renderCgmContent();
      default: return null;
    }
  }


  render() {
    const { deviceData } = this.props;

    if (!deviceData) {
      return null;
    }

    return (
      <div className={cn(styles.modal__content, 'h-auto')}>
        { this.renderInfo() }
        { this.renderPatient() }
        { this.renderContent() }
      </div>
    );
  }

}


const mapStateToProps = (state) => {
  const activeProfileType = Account.selectors.activeProfileType(state);
  const isHCP = activeProfileType === Account.constants.PROFILE_TYPES.HCP;
  return {
    deviceData               : selectors.deviceData(state),
    deviceDataType           : selectors.deviceDataType(state),
    activeProfileType,
    patientProfile           : (isHCP ? Hcp.selectors.activePatient : Account.selectors.patientProfileExtended)(state),
    phiSet                   : (isHCP ? Hcp.selectors.phiSet : Patient.selectors.phiSet)(state),
    phiSetDocumentId         : (isHCP ? Hcp.selectors.phiSetDocumentId : Patient.selectors.phiSetDocumentId)(state),
    patients                 : isHCP ? Hcp.selectors.patients(state) : [],
    account                  : Account.selectors.account(state),
    passphrase               : Account.selectors.passphrase(state),
    countrySettings          : App.selectors.countrySettings(state),
    isHCP,
    isInProgress             : CloudDrive.selectors.isStoreReadingsInProgress(state),
    hasErrors                : CloudDrive.selectors.hasStoreReadingsErrors(state),
    isFetchPhiSetInProgress  : CloudDrive.selectors.isFetchPhiSetInProgress(state),
    hasFetchPhiSetErrors     : CloudDrive.selectors.hasFetchPhiSetErrors(state),
    isFetchReadingsInProgress: CloudDrive.selectors.isFetchReadingsInProgress(state),
    hasFetchReadingsErrors   : CloudDrive.selectors.hasFetchReadingsErrors(state),
    isSyncInProgress         : CloudDrive.selectors.isSyncInProgress(state) || DataSources.selectors.isSyncInProgress(state),
  };
};


const mapDispatchToProps = (dispatch) => ({
  onImport: (importData, phiSet, phiSetDocumentId, credentials, successAction) => dispatch(
    CloudDrive.actions.storeReadings(importData, phiSet, phiSetDocumentId, credentials, successAction),
  ),
  onCgmImport: (importData, phiSet, phiSetDocumentId, credentials, successAction) => dispatch(
    CloudDrive.actions.storeCgmReadings(importData, phiSet, phiSetDocumentId, credentials, successAction),
  ),
  onLogEvent              : () => dispatch(actions.logEvent('DataPulled')),
  onSendClinicNotification: (notificationPayload) => dispatch(
    Notifications.actions.sendClinicPatientNewReadingsAddedNotification(notificationPayload),
  ),
  onSendStats: (patientProfile, passphrase, phiSet, importData, standards) => dispatch(
    Statistics.actions.sendStatistics(patientProfile, phiSet, importData, standards, passphrase),
  ),
  onSendStatsForClinic: (patientProfile, phiSet, importData, standards, clinicMembership) => dispatch(
    Statistics.actions.sendStatisticsForClinic(patientProfile, phiSet, importData, standards, clinicMembership),
  ),
  onUpdateProfile: (patient, newPatientValues, clinicMembership) => dispatch(
    Hcp.actions.updatePatient(patient, newPatientValues, clinicMembership),
  ),
  onPreviewPatient: (deviceData, clinicSlug, organizationUID) => dispatch(
    Hcp.actions.previewPatientOpenTab(deviceData, clinicSlug, organizationUID),
  ),
  onGetSerialNumberToken: (importData) => dispatch(actions.getSerialNumberToken(importData)),
  onOpenModal           : (modalId) => dispatch(App.actions.openModal(modalId)),
});


const ConnectedResults = connect(mapStateToProps, mapDispatchToProps)(Results);


export default withStyles(styles)(ConnectedResults);
