import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import toNumber from 'lodash/toNumber';
import MetricConversions from 'libs/MetricConversions';
import App from 'modules/App';
import Account from 'modules/Account';
import Locked from 'components/Locked';
import * as constants from '../../constants';
import messages from '../../messages';
import TargetsPicker from './TargetsPicker';
import styles from './GlucoseLevelTargets.pcss';


class GlucoseLevelTargets extends React.PureComponent {

  static propTypes = {
    // Temporary flag to be determined with UX team
    withLocked            : PropTypes.bool,
    // Explicit props
    isDisabled            : PropTypes.bool,
    phiSet                : PropTypes.object,
    patient               : PropTypes.object,
    // Implicit props
    metricsUnits          : PropTypes.object, // @TODO : Shape
    formValues            : PropTypes.object,
    isInProgress          : PropTypes.bool,
    hasErrors             : PropTypes.bool,
    countrySettings       : PropTypes.object,
    activeClinicMembership: Account.shapes.clinicMembership,
    // Implicit actions
    onSetFormValue        : PropTypes.func.isRequired,
    onFormErrors          : PropTypes.func.isRequired,
    onClearForm           : PropTypes.func,
  };


  static defaultProps = {
    withLocked: false,
  };


  constructor(props) {
    super(props);
    this.validatorRules = {};
    const metricConversions = new MetricConversions(props.metricsUnits);
    this.conversion = metricConversions.bloodGlucoseConcentration;
    const { unit } = this.conversion;
    this.defaultValuesSliders = {
      valueMin     : 0,
      valueMax     : unit === 'MMOL_L' ? 20 : 350,
      valueStep    : unit === 'MMOL_L' ? 2.5 : 50,
      valueDragStep: unit === 'MMOL_L' ? 0.1 : 1,
    };
    this.state = {
      isLocked: props.withLocked,
    };
  }


  onSetValue(input) {
    const { id, value } = input;
    const { unit } = this.conversion;
    const precision = get(App.constants.GLUCOSE_LEVELS_INPUTS_PRECISION, unit, 0);
    const roundedValue = isNumber(value) ? toNumber(value.toFixed(precision)) : value;
    this.props.onSetFormValue({ id, value: roundedValue });
  }


  onLockedClick() {
    this.setState((prevState) => ({ isLocked: !prevState.isLocked }));
  }


  renderTargetRangeHeader(message) {
    return (
      <>
        <FormattedMessage {...message} />
        {` (${this.conversion.unitSymbol})`}
      </>
    );
  }


  render() {
    const { isDisabled, countrySettings, activeClinicMembership, phiSet, patient = {}, withLocked } = this.props;
    const { isLocked } = this.state;
    const { unit } = this.conversion;
    const precision = get(App.constants.GLUCOSE_LEVELS_INPUTS_PRECISION, unit, 0);
    const pickerProps = {
      onRangeChange: (input) => this.onSetValue(input),
      onTimeChange : this.props.onSetFormValue,
      formValues   : this.props.formValues,
      conversion   : this.conversion,
      ...this.defaultValuesSliders,
    };

    return (
      <div>
        {
        !isDisabled && (
          <>
            <h1 className={styles.headerTargetRanges}>
              <FormattedMessage
                {...messages.headers.targetRanges}
                values={{
                  name        : <b>{`${patient.firstName} ${patient.lastName}`}</b>,
                  diabetesType: <b>{phiSet.diabetesType}</b>,
                }}
              />
            </h1>
            {
              withLocked && (
                <div className={styles.locked}>
                  <Locked isLocked={isLocked} onClick={() => this.onLockedClick()} />
                </div>
              )
            }
          </>
        )
}
        <div className="row">
          <div className="col-4">
            <TargetsPicker
              defaultIsOpen
              header={this.renderTargetRangeHeader(messages.headers.preMeal)}
              targetType="preMeal"
              {...pickerProps}
              locked={isDisabled || isLocked}
              countrySettings={countrySettings}
              precision={precision}
              activeClinicMembership={activeClinicMembership}
              phiSet={phiSet}
              withoutOrder
            />
          </div>
          <div className="col-4">
            <TargetsPicker
              header={this.renderTargetRangeHeader(messages.headers.postMeal)}
              targetType="postMeal"
              {...pickerProps}
              locked={isDisabled || isLocked}
              countrySettings={countrySettings}
              precision={precision}
              activeClinicMembership={activeClinicMembership}
              phiSet={phiSet}
            />
          </div>
          <div className="col-4">
            <TargetsPicker
              header={this.renderTargetRangeHeader(messages.headers.critical)}
              targetType="critical"
              {...pickerProps}
              locked={isDisabled || isLocked}
              countrySettings={countrySettings}
              precision={precision}
              activeClinicMembership={activeClinicMembership}
              phiSet={phiSet}
            />
          </div>
        </div>
      </div>
    );
  }

}

const mapStateToProps = (state) => {
  const formName = constants.PATIENT_GLUCOSE_SETTINGS_FORM;
  return ({
    metricsUnits   : Account.selectors.metricsUnits(state),
    countrySettings: Account.selectors.countrySettings(state),
    formValues     : App.selectors.formSelector(formName)(state),
    featureToggles : App.selectors.featureToggles(state),
  });
};

const mapDispatchToProps = (dispatch) => {
  const formName = constants.PATIENT_GLUCOSE_SETTINGS_FORM;
  return {
    onSetFormValue: (input) => dispatch(App.actions.setFormValue(formName, input)),
    onFormErrors  : (errors) => dispatch(App.actions.setFormErrors(formName, errors)),
    onClearForm   : () => dispatch(App.actions.clearForm(formName)),
  };
};

const ConnectedGlucoseLevelTargets = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GlucoseLevelTargets);

export default withStyles(styles)(ConnectedGlucoseLevelTargets);
