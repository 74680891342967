import React from 'react';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import cn from 'classnames';
import includes from 'lodash/includes';
import { detectBrowser } from 'helpers/browser';
import ArrowDown from 'svg/arrow-down.svg';
import X from 'svg/x.svg';
import messages from '../../messages';
import styles from './LaunchDownloaderInstaller.pcss';


class LaunchDownloaderInstaller extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      folded: false,
    };
    this.browser = 'Unknown';
    if (process.env.BROWSER) {
      this.browser = detectBrowser();
    }
  }


  onClose(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.setState({ folded: true });
  }


  get browserModifier() {
    if (includes(['Firefox', 'Safari', 'Chrome'], this.browser)) {
      return this.browser;
    }
    return 'Other';
  }


  renderCloseBtn() {
    return (
      <div className={`modal__close ${styles.downloaderLauncher__close}`}>
        <button
          type="button"
          className="modal__close__btn"
          onClick={(evt) => this.onClose(evt)}
        >
          <span className="btn-inner">
            <X className="modal__close__btn__icon" />
          </span>
        </button>
      </div>
    );
  }


  renderFoldable(browserModifier) {
    if (this.state.folded) {
      return null;
    }
    const clickPosition = browserModifier === 'Other' ? 'Below' : 'Above';
    return (
      <>
        <div className="col">
          <div className={styles.downloaderLauncher__content}>
            <h3 className="text--h2 mb-3">
              <FormattedMessage {...messages.headers[`click${clickPosition}ToInstall`]} values={{ clickPosition }} />
            </h3>
            <p className={`${styles.downloaderLauncher__text} text--large text--light`}>
              <FormattedMessage {...messages.infos[`waitAndClick${clickPosition}ToInstall`]} />
            </p>
          </div>
        </div>
        { this.renderCloseBtn() }
      </>
    );
  }


  render() {
    if (!process.env.BROWSER || this.browser === 'EdgeChromium') {
      return null;
    }
    const { browserModifier } = this;
    return (
      <div
        className={cn(
          styles.downloaderLauncher,
          styles[`downloaderLauncher--${browserModifier}`],
          { [styles['downloaderLauncher--folded']]: this.state.folded },
          'row no-gutters align-items-center',
        )}
      >
        <div className={`${styles.downloaderLauncher__indicatorWrapper} col-auto`}>
          <div className={styles.downloaderLauncher__indicator}>
            <ArrowDown
              className={cn(
                styles.downloaderLauncher__indicator__icon,
              )}
            />
          </div>
        </div>
        { this.renderFoldable(browserModifier) }
      </div>
    );
  }

}

export default withStyles(styles)(LaunchDownloaderInstaller);
