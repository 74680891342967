import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { AnimatePresence, motion } from 'framer-motion';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import map from 'lodash/map';
import { AppContext } from 'context';
import { getSlug } from 'helpers/urlTools';
import SidebarPersonsLoader from 'components/Loaders/SidebarPersonsLoader';
import Avatar from 'components/Avatar';
import Link from 'components/Link';
import Button from 'components/Form/Button';
import Dots from 'svg/dots.svg';
import App from 'modules/App';
import Account from 'modules/Account';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import messages from '../../messages';
import filterBySearch from './helpers/filterBySearch';
import styles from './PatientsList.pcss';


class PendingPatients extends React.PureComponent {

  static contextType = AppContext;


  static getDerivedStateFromProps(props, state) {
    const { enrollingSharingRequests, search } = props;
    return filterBySearch(
      enrollingSharingRequests,
      'sharingRequestId',
      search,
      state,
      props.maxPatientLength,
      'patient',
    );
  }


  static propTypes = {
    // Explicit props
    id                       : PropTypes.string.isRequired,
    activeClinicMembership   : Account.shapes.clinicMembership,
    setEnrollingPatientLength: PropTypes.func,
    maxPatientLength         : PropTypes.number,
    // Implicit props
    isInProgress             : PropTypes.bool.isRequired,
    openDropdownId           : PropTypes.string,
    // Implicit actions
    onOpenDropdown           : PropTypes.func.isRequired,
    onCloseDropdown          : PropTypes.func.isRequired,
    onOpenModal              : PropTypes.func.isRequired,
    onSetFormContext         : PropTypes.func.isRequired,
  };

  static defaultProps = {
    setEnrollingPatientLength: () => {},
  };


  constructor(props) {
    super(props);
    this.state = {
      filteredCollection   : [],
      filteredCollectionIds: [], // eslint-disable-line react/no-unused-state
      search               : '', // eslint-disable-line react/no-unused-state
    };
  }


  componentDidMount() {
    const { filteredCollectionIds } = this.state;
    this.props.setEnrollingPatientLength(filteredCollectionIds.length);
  }


  componentDidUpdate(prevProps, prevState) {
    const { filteredCollectionIds } = this.state;
    if (prevState.filteredCollectionIds.length !== filteredCollectionIds.length) {
      this.props.setEnrollingPatientLength(filteredCollectionIds.length);
    }
  }


  async onOpenMenu(evt, sharingRequest) {
    evt.preventDefault();
    evt.stopPropagation();
    await this.props.onCloseDropdown();
    this.props.onOpenDropdown(`${constants.ENROLLING_SHARING_REQUEST_DROPDOWN}-${sharingRequest.sharingRequestId}`);
  }


  onReject(evt, sharingRequest) {
    evt.preventDefault();
    evt.stopPropagation();
    this.props.onSetFormContext(sharingRequest);
    this.props.onOpenModal(constants.DECLINE_ENROLL_MODAL);
  }


  renderMenu(sharingRequest) {
    const dropdownId = `${constants.ENROLLING_SHARING_REQUEST_DROPDOWN}-${sharingRequest.sharingRequestId}`;
    if (this.props.openDropdownId !== dropdownId) {
      return null;
    }
    return (
      <motion.div
        initial={{ width: 0 }}
        animate={{ width: 'auto' }}
        exit={{ width: 0 }}
        transition={{ ease: 'easeOut', duration: 0.15 }}
        className="dropdownMenu__container dropdownMenu__container--oposite ml-3"
      >
        <ul className="dropdownMenu__actions">
          <li className="dropdownMenu__action">
            <Button onClick={(evt) => this.onReject(evt, sharingRequest)}>
              <FormattedMessage {...messages.buttons.decline} />
            </Button>
          </li>
        </ul>
      </motion.div>
    );
  }


  renderMenuContainer(sharingRequest) {
    return (
      <div className="dropdownMenu">
        <button
          type="button"
          className="dropdownMenu__menuButton"
          onClick={(evt) => this.onOpenMenu(evt, sharingRequest)}
        >
          <Dots className="dropdownMenu__icon" />
        </button>
        <AnimatePresence>
          { this.renderMenu(sharingRequest) }
        </AnimatePresence>
      </div>
    );
  }


  renderSharingRequest(sharingRequest) {
    const { sharingRequestId, patient } = sharingRequest;
    const { organizationUID, name } = get(this.props.activeClinicMembership, 'clinic', {});
    const clinicSlug = getSlug(name);
    const patientSlug = getSlug(`${patient.firstName} ${patient.lastName}`);
    const patientId = patient.id;
    const url = this.context.getUrl('hcp-results', { clinicSlug, organizationUID, patientSlug, patientId });
    return (
      <li key={sharingRequestId} className={cn(styles.item__sidebarList)}>
        <Link to={url}>
          <div className="row flex-nowrap align-items-center">
            <div className="col-auto pr-2">
              <Avatar
                avatarImg={patient.avatar}
                name={[patient.firstName, patient.lastName]}
                className={cn(styles.item__avatar, styles['item__avatar--marked'])}
                imgClassName={styles.item__avatar__img}
                initialsClassName={styles.item__avatar__initials}
              />
            </div>
            <div className="col px-3">
              <p
                data-hj-suppress
                className={
                  cn(
                    styles.item__name,
                    styles['item__name--withMenu'],
                    {
                      [styles[`item__name--${this.props.id}`]]: styles[`item__name--${this.props.id}`],
                    },
                  )
                }
              >
                { patient.firstName } { patient.lastName }
              </p>
            </div>
            <div className="col-auto pl-2">
              { this.renderMenuContainer(sharingRequest) }
            </div>
          </div>
        </Link>
      </li>
    );
  }


  render() {
    if (this.props.isInProgress) {
      return <SidebarPersonsLoader itemsNumber={5} />;
    }
    return (
      <ul className={styles.items}>
        { map(this.state.filteredCollection, (sharingRequest) => this.renderSharingRequest(sharingRequest)) }
      </ul>
    );
  }

}


const mapStateToProps = (state, props) => ({
  enrollingSharingRequests: selectors.enrollingSharingRequests(state),
  search                  : selectors.searchSelector(props.id)(state),
  isInProgress            : selectors.isFetchEnrollingSharingRequestsInProgress(state),
  openDropdownId          : App.selectors.dropdown(state),
});


const mapDispatchToProps = (dispatch) => ({
  onOpenDropdown  : (dropdownId) => dispatch(App.actions.openDropdown(dropdownId)),
  onCloseDropdown : () => dispatch(App.actions.closeDropdown()),
  onOpenModal     : (modalId) => dispatch(App.actions.openModal(modalId)),
  onSetFormContext: (sharingRequest) => dispatch(
    App.actions.setFormContext(constants.MERGE_PATIENT_FORM, sharingRequest),
  ),
});


const ConnectedPendingPatients = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PendingPatients);


export default withStyles(styles)(ConnectedPendingPatients);
