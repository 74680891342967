import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import moment from 'moment';
import xor from 'lodash/xor';
import get from 'lodash/get';
import Button from 'components/Form/Button';
import messages from '../../../messages';
import Transmitter from './Transmitter';
import styles from './ResultsContentCgm.pcss';


class ResultsContentCgm extends React.PureComponent {

  constructor() {
    super();
    this.state = {
      activeTransmitters: [],
    };
  }


  static propTypes = {
    // Explicit props
    deviceData  : PropTypes.object,
    isInProgress: PropTypes.bool,
    isDisabled  : PropTypes.bool,
    // Explicit actions
    onCancel    : PropTypes.func,
    onImport    : PropTypes.func,
  };


  componentDidMount() {
    const firstTransmitterSerialNumber = get(this.props.deviceData, 'transmitters[0].serialNumber');
    if (firstTransmitterSerialNumber) {
      this.toggleTransmitter(firstTransmitterSerialNumber);
    }
  }


  onImport() {
    const { deviceData } = this.props;
    const { transmitters } = deviceData;
    deviceData.transmitters = transmitters.filter(((transmitter) => this.state.activeTransmitters.includes(transmitter.serialNumber)));
    deviceData.isCgmData = true;
    this.props.onImport(deviceData);
  }


  toggleTransmitter(serialNumber) {
    const { activeTransmitters } = this.state;
    this.setState({
      activeTransmitters: xor(activeTransmitters, [serialNumber]),
    });
  }


  renderTransmitterSelect() {
    const { deviceData } = this.props;
    return (
      <div className={styles.transmitterSelector}>
        {
          get(deviceData, 'transmitters', []).map((transmitter) => (
            <Transmitter
              key={transmitter.serialNumber}
              transmitter={transmitter}
              toggleTransmitter={(serialNumber) => this.toggleTransmitter(serialNumber)}
              active={this.state.activeTransmitters.includes(transmitter.serialNumber)}
            />
          ))
        }
      </div>
    );
  }


  render() {
    const { deviceData } = this.props;
    if (!deviceData) {
      return null;
    }
    return (
      <>
        <div className={styles.connectedResultsContentCgm}>
          <div className="feature">
            <div className="feature__count">
              <p className="text--small text--light mt-3 mb-2 featureTitle">
                <FormattedMessage {...messages.labels.measurements} />
              </p>
              <p className="feature__countItems">
                <span className="feature__countItem">
                  <i className="bg--gray feature__countDisc" />{ 0 }
                </span>
                <span className="feature__countItem">
                  <i className="bg--warning feature__countDisc" />{ deviceData.summary.lowCount }
                </span>
                <span className="feature__countItem">
                  <i className="bg--success feature__countDisc" />{ deviceData.summary.targetCount }
                </span>
                <span className="feature__countItem">
                  <i className="bg--error feature__countDisc" />{ deviceData.summary.highCount }
                </span>
              </p>
            </div>
            <div className="feature__range">
              <p className="text--small text--light mt-3 mb-2 featureTitle">
                <FormattedMessage {...messages.labels.measurementsRange} />
              </p>
              <p className="d-flex align-items-center text--large mb-3 feature__rangeItems">
                {moment.unix(deviceData.summary.minTimestamp).format('Mo MMM')}
                {' - '}
                {moment.unix(deviceData.summary.maxTimestamp).format('Mo MMM')}
              </p>
            </div>
            <div className="feature__type">
              <p className="text--small text--light mt-3 mb-2 featureTitle">
                <FormattedMessage {...messages.labels.measurementsType} />
              </p>
              <p className="d-flex align-items-center text--large mb-3">
              -
              </p>
            </div>
            <div className="feature__importFrom">
              <p className="text--small text--light mt-3 mb-2 featureTitle">
                <FormattedMessage {...messages.labels.importFrom} />
              </p>
              {this.renderTransmitterSelect()}
            </div>
          </div>
        </div>
        <div className="modal__actions row">
          <div className="col-6">
            <Button
              styleModifier="primary"
              labelMessage={messages.buttons.cancelImport}
              className="btn--block"
              isDisabled={this.props.isInProgress}
              onClick={this.props.onCancel}
            />
          </div>
          <div className="col-6">
            <Button
              styleModifier="primary"
              labelMessage={messages.buttons.importResults}
              className="btn--block btn--filled"
              isDisabled={this.props.isDisabled || !this.state.activeTransmitters.length}
              isInProgress={this.props.isInProgress}
              onClick={() => this.onImport()}
              id="importResults"
            />
          </div>
        </div>
      </>
    );
  }

}

export default withStyles(styles)(ResultsContentCgm);
