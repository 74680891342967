import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useAction } from 'hooks';
import Button from 'components/Form/Button';
import * as actions from '../../actions';
import messages from '../../messages';
import styles from '../../components/DownloadDataModal/DownloadDataModal.pcss';


const ConnectionFailed = ({ onSetComponent, onSetAgainPath }) => {
  const onResetScc = useAction(actions.resetScc);

  const onRetry = () => {
    onSetComponent('Initializing');
    onSetAgainPath(true);
  };

  const onReset = () => {
    onResetScc();
    onRetry();
  };

  return (
    <div className={styles.modal__content}>
      <h3 className="modal__subheader "><FormattedMessage {...messages.connectionFailed.sccHeader} /></h3>
      <div className={styles.illustration}>
        <img src="/assets/svg/connection-failed.svg" className={styles.connectionFailed__img} alt="" />
      </div>
      <div className="modal__actions">
        <Button
          styleModifier="primary"
          labelMessage={messages.buttons.tryAgain}
          className="btn--block mb-4"
          onClick={onRetry}
        />
        <Button
          styleModifier="primary"
          labelMessage={messages.sccInstall.header}
          className="btn--block btn--filled"
          onClick={onReset}
        />
      </div>
    </div>
  );
};


ConnectionFailed.propTypes = {
  onSetComponent: PropTypes.func,
  onSetAgainPath: PropTypes.func,
};


export default ConnectionFailed;
