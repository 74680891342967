import findKey from 'lodash/findKey';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import includes from 'lodash/includes';
import pick from 'lodash/pick';
import replace from 'lodash/replace';
import toLower from 'lodash/toLower';
import { all, call, spawn, delay, getContext, put, race, take, takeEvery, takeLatest, select } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';
import appInsights from 'helpers/appInsights';
import { ApiError, LocalError } from 'helpers/errorTypes';
import request from 'helpers/request';
import MetricConversions from 'libs/MetricConversions';
import { isAggregatedPreMeal } from 'libs/StatsCalculations';
import LocalStorage from 'libs/LocalStorage';
import ApiService from 'services/ApiService';
import App from 'modules/App';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import * as selectors from './selectors';
import * as constants from './constants';
import messages from './messages';


//----------------------------------------------------------------------------------------------------------------------

function* sendEvent(type) {
  const { mdaVersion: version } = yield select(selectors.downloader);
  const event = {
    source: 'MDA',
    type,
    version,
  };
  yield put(App.actions.logEvent(event));
}

//----------------------------------------------------------------------------------------------------------------------

function* sendConfiguration() {
  const devices = yield select(App.selectors.devices);
  const countries = yield select(App.selectors.countries);
  if (!devices || !devices.length) {
    throw new LocalError({ code: 'NoDevices' });
  }
  if (!countries || !countries.length) {
    throw new LocalError({ code: 'NoCountries' });
  }
  const fetch = yield getContext('fetch');
  const deviceConfigRequestUrl = 'http://localhost:12345/device/deviceConfig';
  const dictionariesConfigRequestUrl = 'http://localhost:12345/dictionaries/config';
  yield all([
    call(request, fetch, deviceConfigRequestUrl, {
      method: 'POST',
      body  : {
        devices,
      },
    }),
    call(request, fetch, dictionariesConfigRequestUrl, {
      method: 'POST',
      body  : {
        countries,
      },
    }),
  ]);
}


function* checkStatus() {
  if (!process.env.BROWSER) {
    return;
  }
  try {
    const fetch = yield getContext('fetch');
    const requestUrl = 'http://localhost:12345/service/status';
    /*
    {
      "status": "Ready",
      "apiVersion": "v1",
      "mdaVersion": "1.5.9.22",
      "bleAvailable": false,
      "bleAvailabilityReason": "NoBleTransmitter",
      "installedByClickOnce": true,
      "isInitialized": false
    }
     */
    const downloader = yield call(request, fetch, requestUrl);
    LocalStorage.setItem('downloader', JSON.stringify(pick(downloader, ['apiVersion', 'mdaVersion'])));
    if (!downloader.isInitialized) {
      yield call(sendConfiguration);
    }
    yield put(actions.checkStatusSuccess(downloader));
  } catch (err) {
    const downloader = JSON.parse(LocalStorage.getItem('downloader'));
    yield put(actions.checkStatusError(err, downloader));
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* installDownloaderWorker() {
  const fetch = yield getContext('fetch');
  const requestUrl = 'http://localhost:12345/service/status';
  let timeout = 3 * 60000;
  while (true) {
    try {
      if (timeout <= 0) {
        yield put(actions.installDownloaderTimeout());
      }
      const downloader = yield call(request, fetch, requestUrl);
      LocalStorage.setItem('downloader', JSON.stringify(pick(downloader, ['apiVersion', 'mdaVersion'])));
      yield put(actions.installDownloaderSuccess(downloader));
    } catch (err) {
      yield delay(3000);
      timeout -= 3000;
    }
  }
}


function* installDownloader() {
  const { cancel, timeout } = yield race({
    worker : call(installDownloaderWorker),
    cancel : take(actionTypes.INSTALL_DOWNLOADER_CANCEL),
    success: take(actionTypes.INSTALL_DOWNLOADER_SUCCESS),
    timeout: take(actionTypes.INSTALL_DOWNLOADER_TIMEOUT),
  });
  let eventType = 'InstallationSuccessful';
  if (cancel) eventType = 'InstallationCancelled';
  else if (timeout) eventType = 'InstallationTimeout';
  yield call(sendEvent, eventType);
}

//----------------------------------------------------------------------------------------------------------------------

function* updateDownloader() {
  try {
    yield call(sendEvent, 'UpgradeAttempt');
    const fetch = yield getContext('fetch');
    const requestUrl = 'http://localhost:12345/service/upgrade';
    yield call(request, fetch, requestUrl, {
      method: 'POST',
    });
    yield call(sendEvent, 'UpgradeSuccessful');
    yield put(actions.updateDownloaderSuccess());
  } catch (err) {
    if (err.status === 404) {
      yield call(sendEvent, 'UpgradeUnavailable');
      yield put(actions.updateDownloaderEndPointNotFoundError(err));
      return;
    }
    yield call(sendEvent, 'UpgradeFailed');
    yield put(actions.updateDownloaderError(err));
  }
}

//----------------------------------------------------------------------------------------------------------------------

function handleBgmData(deviceData, visit) {
  const summary = get(deviceData, 'summary', {});
  const { highNorm, lowNorm, preMealHighNorm, preMealLowNorm } = summary;
  let highCount = 0;
  let lowCount = 0;
  let targetCount = 0;

  const phisetVisitId = get(visit, 'phisetVisitId');

  const deviceReadings = get(deviceData, 'readings', []);
  const readings = [];
  const { UNITS_SYMBOLS } = App.constants;
  forEach(deviceReadings, (deviceReading) => {
    const { deviceUnit, deviceValue } = deviceReading;
    if (!deviceUnit || !deviceValue) {
      return;
    }

    const unit = replace(deviceUnit, '^P', '');
    const lowerUnit = toLower(unit);
    const readingUnit = findKey(UNITS_SYMBOLS, (symbol) => toLower(symbol) === lowerUnit);
    const conversions = new MetricConversions({ bloodGlucoseConcentration: readingUnit });
    const value = conversions.bloodGlucoseConcentration.toStorage(deviceValue);
    const reading = { ...deviceReading, value, deviceUnit: unit };
    if (phisetVisitId) {
      reading.phisetVisitId = phisetVisitId;
    }
    readings.push(reading);

    if (isAggregatedPreMeal(reading.flags)) {
      if (preMealHighNorm && value > preMealHighNorm) {
        highCount++;
      } else if (preMealLowNorm && value < preMealLowNorm) {
        lowCount++;
      } else {
        targetCount++;
      }
    } else if (highNorm && value > highNorm) {
      highCount++;
    } else if (lowNorm && value < lowNorm) {
      lowCount++;
    } else {
      targetCount++;
    }
  });

  deviceData.summary = { ...summary, highCount, lowCount, targetCount };
  deviceData.readings = readings;
  return deviceData;
}


function handleCgmData(deviceData) {
  deviceData.serialNumber = deviceData.serialNumber || 'true';
  const summary = get(deviceData, 'summary', {});
  const { highNorm, lowNorm } = summary;
  const transmitters = get(deviceData, 'transmitters', []);
  const { UNITS_SYMBOLS } = App.constants;
  forEach(transmitters, (transmitter) => {
    const readings = [];
    let highCount = 0;
    let lowCount = 0;
    let targetCount = 0;
    const deviceReadings = get(transmitter, 'readings', []);
    forEach(deviceReadings, (deviceReading) => {
      const { deviceUnit, deviceValue } = deviceReading;
      if (!deviceUnit || !deviceValue) {
        return;
      }

      const unit = replace(deviceUnit, '^P', '');
      const lowerUnit = toLower(unit);
      const readingUnit = findKey(UNITS_SYMBOLS, (symbol) => toLower(symbol) === lowerUnit);
      const conversions = new MetricConversions({ bloodGlucoseConcentration: readingUnit });

      const reading = { ...deviceReading, deviceUnit: unit };
      reading.value = conversions.bloodGlucoseConcentration.toStorage(deviceValue);
      readings.push(reading);

      if (highNorm && deviceValue > highNorm) {
        highCount++;
      } else if (lowNorm && deviceValue < lowNorm) {
        lowCount++;
      } else {
        targetCount++;
      }
    });
    const minTimestamp = Math.min(...readings.map((reading) => reading.timestamp));
    const maxTimestamp = Math.max(...readings.map((reading) => reading.timestamp));
    transmitter.summary = { ...summary, highCount, lowCount, targetCount, minTimestamp, maxTimestamp };
    transmitter.readings = readings;
  });
  deviceData.transmitters = transmitters;
  const minTimestamp = Math.min(...transmitters.map((transmitter) => get(transmitter, 'summary.minTimestamp', null)));
  const maxTimestamp = Math.max(...transmitters.map((transmitter) => get(transmitter, 'summary.maxTimestamp', null)));
  const highCount = deviceData.transmitters.reduce((acc, transmitter) => acc + get(transmitter, 'summary.highCount', 0), 0);
  const lowCount = deviceData.transmitters.reduce((acc, transmitter) => acc + get(transmitter, 'summary.lowCount', 0), 0);
  const targetCount = deviceData.transmitters.reduce((acc, transmitter) => acc + get(transmitter, 'summary.targetCount', 0), 0);
  deviceData.summary = {
    ...deviceData.summary,
    minTimestamp,
    maxTimestamp,
    highCount,
    lowCount,
    targetCount,
  };
  return deviceData;
}


function* fetchMdaDeviceData(connectionId, visit) {
  const deviceDataType = yield select(selectors.deviceDataType);
  const fetch = yield getContext('fetch');
  const requestUrl = `http://localhost:12345/${deviceDataType.toLowerCase()}/connections/${connectionId}/deviceData`;
  const deviceData = yield call(request, fetch, requestUrl);
  const handleData = deviceDataType === constants.DEVICE_DATA_TYPES.CGM ? handleCgmData : handleBgmData;
  return yield call(handleData, deviceData, visit);
}


function* fetchSccDeviceData(connectionId, visit) {
  const requestUrl = `/api/GDG/Connections/${connectionId}/DeviceData`;
  const deviceData = yield call(ApiService.regionalRequest, requestUrl);
  return yield call(handleBgmData, deviceData, visit);
}


function getFetchDeviceData(connectorType) {
  switch (connectorType) {
    case constants.CONNECTOR_TYPES.SCC: return fetchSccDeviceData;
    default: return fetchMdaDeviceData;
  }
}


function* getDeviceData({ payload }) {
  try {
    const { connectorType, connectionId, visit } = payload;
    const fetchDeviceData = getFetchDeviceData(connectorType);
    const deviceData = yield call(fetchDeviceData, connectionId, visit);
    yield put(actions.getDeviceDataSuccess(deviceData));
  } catch (err) {
    yield put(actions.getDeviceDataError(err));
    yield call(App.dispatchError, err);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* checkMdaConnectionStatus(connectionId) {
  const deviceDataType = yield select(selectors.deviceDataType);
  const fetch = yield getContext('fetch');
  const requestUrl = `http://localhost:12345/${deviceDataType.toLowerCase()}/connections/${connectionId}/status`;
  let { status } = yield call(request, fetch, requestUrl);
  if (status === 'Failed') status = constants.CONNECTION_STATUSES.ERROR;
  return status;
}


function* checkSccConnectionStatus(connectionId) {
  const requestUrl = `/api/GDG/Connections/${connectionId}`;
  const { status } = yield call(ApiService.regionalRequest, requestUrl);
  return status;
}


function getCheckConnectionStatus(connectorType) {
  switch (connectorType) {
    case constants.CONNECTOR_TYPES.SCC: return checkSccConnectionStatus;
    default: return checkMdaConnectionStatus;
  }
}


function* checkConnectionWorker(connectionId, connectorType) {
  const checkConnectionStatus = getCheckConnectionStatus(connectorType);
  const finalStatuses = [
    constants.CONNECTION_STATUSES.SUCCESS,
    constants.CONNECTION_STATUSES.CANCELED,
    constants.CONNECTION_STATUSES.ERROR,
    constants.CONNECTION_STATUSES.TIMEOUT,
  ];
  while (true) {
    try {
      yield delay(1000);
      const status = yield call(checkConnectionStatus, connectionId);
      if (includes(finalStatuses, status)) {
        yield put(actions.stopCheckingConnection(connectionId, status));
        return;
      }
      yield put(actions.setCheckingConnection(connectionId, status));
    } catch (err) {
      yield put(actions.checkingConnectionError(err));
    }
  }
}


function* checkConnection({ payload }) {
  const { connectionId, connectorType } = payload;
  yield race([
    call(checkConnectionWorker, connectionId, connectorType),
    take(actionTypes.CHECKING_CONNECTION_STOP),
    take(actionTypes.CHECKING_CONNECTION_ERROR),
  ]);
}


function* closeSccConnection(connectionId) {
  try {
    const requestUrl = `/api/GDG/Connections/${connectionId}`;
    yield call(ApiService.regionalRequest, requestUrl, { method: 'DELETE' });
  } catch (err) {
    // Background action
    if (__DEV__) {
      console.error(err);
    } else {
      appInsights.trackException(err);
    }
  }
}


function* closeConnection({ payload = {} }) {
  try {
    const { connectionId, connectionStatus } = payload;
    if (
      connectionStatus === constants.CONNECTION_STATUSES.SUCCESS
      || connectionStatus === constants.CONNECTION_STATUSES.TIMEOUT
      || connectionStatus === constants.CONNECTION_STATUSES.ERROR
      || !connectionId
    ) {
      return;
    }
    const connectorType = yield select(selectors.connectorType);
    if (connectorType === constants.CONNECTOR_TYPES.SCC) {
      yield spawn(closeSccConnection, connectionId);
    }
  } catch (err) {
    // Background action
    if (__DEV__) {
      console.error(err);
    } else {
      appInsights.trackException(err);
    }
  }
}

//----------------------------------------------------------------------------------------------------------------------


function* checkBlueCableDriver() {
  if (!process.env.BROWSER) {
    return;
  }
  try {
    const fetch = yield getContext('fetch');
    const statusURL = 'http://localhost:12345/driver/status';
    const driver = yield call(request, fetch, statusURL);
    yield put(actions.checkBlueCableDriverSuccess(driver));
  } catch (err) {
    yield put(actions.checkBlueCableDriverError(err));
  }
}


function* installBlueCableDriverWorker() {
  const fetch = yield getContext('fetch');
  const requestUrl = 'http://localhost:12345/driver/status';
  while (true) {
    yield delay(3000);
    const driver = yield call(request, fetch, requestUrl);
    const { isDriverInstalled, installationState } = driver;
    yield put(actions.checkBlueCableDriverSuccess(driver));
    if (isDriverInstalled || installationState === constants.DRIVER_STATUSES.ERROR) {
      yield put(actions.stopInstallBlueCableDriver());
    }
  }
}


function* installBlueCableDriver({ payload }) {
  try {
    const { installationState } = payload;
    if (!includes(['InProgress', 'Completed'], installationState)) {
      const fetch = yield getContext('fetch');
      const statusURL = 'http://localhost:12345/driver/install';
      const driver = yield call(request, fetch, statusURL, { method: 'POST' });
      yield put(actions.checkBlueCableDriverSuccess(driver));
    }
    yield race([
      call(installBlueCableDriverWorker),
      take(actionTypes.INSTALL_BLUE_CABLE_DRIVER_STOP),
    ]);
  } catch (err) {
    yield put(actions.stopInstallBlueCableDriver());
    const message = err.getBusinessError();
    if (message === 'Driver already installed') {
      yield put(actions.checkBlueCableDriverSuccess({
        installationState    : constants.DRIVER_STATUSES.COMPLETED,
        isDriverInstalled    : true,
        lastInstallationError: null,
      }));
    } else {
      yield put(actions.checkBlueCableDriverSuccess({
        installationState    : constants.DRIVER_STATUSES.ERROR,
        isDriverInstalled    : false,
        lastInstallationError: message,
      }));
    }
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* listenBluetoothWorker() {
  const deviceDataType = yield select(selectors.deviceDataType);
  const fetch = yield getContext('fetch');
  const requestUrl = `http://localhost:12345/${deviceDataType.toLowerCase()}/connections/bluetooth/watch/status`;
  while (true) {
    try {
      yield delay(3000);
      const status = yield call(request, fetch, requestUrl);
      yield put(actions.setAvailableDevices(get(status, 'discoveredDevices', [])));
    } catch (err) {
      yield put(actions.listeningBluetoothError(err));
    }
  }
}


function* listenBluetooth() {
  try {
    const deviceDataType = yield select(selectors.deviceDataType);
    const fetch = yield getContext('fetch');
    // start BT listener
    const startURL = `http://localhost:12345/${deviceDataType.toLowerCase()}/connections/bluetooth/watch/start`;
    yield call(request, fetch, startURL, { method: 'POST' });

    yield race([
      call(listenBluetoothWorker),
      take(actionTypes.CONNECT_BLUETOOTH),
      take(actionTypes.LISTENING_BLUETOOTH_STOP),
      take(actionTypes.LISTENING_BLUETOOTH_ERROR),
    ]);
  } catch (err) {
    yield put(actions.listeningBluetoothError(err));
  }
}


function* stopListenBluetooth() {
  try {
    const deviceDataType = yield select(selectors.deviceDataType);
    if (!deviceDataType || deviceDataType === constants.DEVICE_DATA_TYPES.CGM) {
      return;
    }
    const fetch = yield getContext('fetch');
    const requestUrl = `http://localhost:12345/${deviceDataType.toLowerCase()}/connections/bluetooth/watch/stop`;
    yield call(request, fetch, requestUrl, { method: 'POST' });
  } catch (err) {
    yield put(actions.listeningBluetoothError(err));
  }
}


function* sendBluetoothPin({ payload }) {
  try {
    const { deviceId, pin } = payload;
    const deviceDataType = yield select(selectors.deviceDataType);
    const fetch = yield getContext('fetch');
    const requestUrl = `http://localhost:12345/${deviceDataType.toLowerCase()}/connections/bluetooth/setPin`;
    const { id, status } = yield call(request, fetch, requestUrl, {
      method: 'POST',
      body  : {
        deviceId,
        pin,
      },
    });
    yield put(actions.sendBluetoothPinSuccess(id, status));
  } catch (err) {
    yield put(actions.sendBluetoothPinError(err));
    const businessError = { code: 'BluetoothPinError' };
    const apiError = new ApiError({ ...err, businessError });
    yield call(App.dispatchError, apiError, messages);
  }
}

//----------------------------------------------------------------------------------------------------------------------


function* connectBlueCable() {
  try {
    const deviceDataType = yield select(selectors.deviceDataType);
    const connectorType = constants.CONNECTOR_TYPES.MDA;
    const fetch = yield getContext('fetch');
    const requestUrl = `http://localhost:12345/${deviceDataType.toLowerCase()}/connections/blueCable/connect`;
    const { id: connectionId, status: connectionStatus } = yield call(request, fetch, requestUrl, { method: 'POST' });
    yield put(actions.connectSuccess(connectorType, connectionId, connectionStatus));
  } catch (err) {
    yield put(actions.connectError(err));
  }
}


function* connectBluetooth({ payload }) {
  try {
    const { device } = payload;
    const { id: deviceId } = device;
    const deviceDataType = yield select(selectors.deviceDataType);
    const connectorType = constants.CONNECTOR_TYPES.MDA;
    const fetch = yield getContext('fetch');
    const requestUrl = `http://localhost:12345/${deviceDataType.toLowerCase()}/connections/bluetooth/connect`;
    const { id: connectionId, status: connectionStatus } = yield call(request, fetch, requestUrl, {
      method: 'POST',
      body  : { deviceId },
    });
    yield put(actions.connectSuccess(connectorType, connectionId, connectionStatus));
  } catch (err) {
    yield put(actions.connectError(err));
  }
}


function* connectUsb() {
  try {
    const deviceDataType = yield select(selectors.deviceDataType);
    const connectorType = constants.CONNECTOR_TYPES.MDA;
    const fetch = yield getContext('fetch');
    const requestUrl = `http://localhost:12345/${deviceDataType.toLowerCase()}/connections/usb/connect`;
    const { id: connectionId, status: connectionStatus } = yield call(request, fetch, requestUrl, { method: 'POST' });
    yield put(actions.connectSuccess(connectorType, connectionId, connectionStatus));
  } catch (err) {
    yield put(actions.connectError(err));
  }
}


function* connectUsbC() {
  try {
    const fetch = yield getContext('fetch');
    const connectorType = constants.CONNECTOR_TYPES.MDA;
    const requestUrl = 'http://localhost:12345/cgm/connections/usbc/connect';
    const { id: connectionId, status: connectionStatus } = yield call(request, fetch, requestUrl, { method: 'POST' });
    yield put(actions.connectSuccess(connectorType, connectionId, connectionStatus));
  } catch (err) {
    yield put(actions.connectError(err));
  }
}


function* connectScc({ payload }) {
  try {
    const machineId = LocalStorage.getItem('sccMachineId');
    if (!machineId) {
      const err = new LocalError({ code: 'NoSccMachineID' });
      yield put(actions.connectError(err));
      return;
    }
    const { organizationUID } = payload;
    const connectorType = constants.CONNECTOR_TYPES.SCC;
    const connectionId = uuid();
    const requestUrl = '/api/GDG/Connections';
    yield call(ApiService.regionalRequest, requestUrl, {
      method: 'POST',
      body  : {
        connectorType,
        connectionId,
        machineId,
        organizationUID,
      },
    });
    const connectionStatus = constants.CONNECTION_STATUSES.INITIALIZED;
    yield put(actions.connectSuccess(connectorType, connectionId, connectionStatus));
  } catch (err) {
    yield put(actions.connectError(err));
  }
}

// SCC -----------------------------------------------------------------------------------------------------------------

function* checkSccStatus() {
  try {
    if (!process.env.BROWSER) {
      return;
    }
    let status = constants.DOWNLOADER_STATUSES.NO_CONNECTION;
    const machineId = LocalStorage.getItem('sccMachineId');
    if (machineId) {
      const requestUrl = '/api/GDG/Status';
      const response = yield call(ApiService.originalRequest, requestUrl, {
        method: 'POST',
        body  : {
          connectorType: constants.CONNECTOR_TYPES.SCC,
          machineId,
        },
      });
      status = response.status;
      // yield delay(3000);
    } else {
      yield delay(50);
    }
    yield put(actions.checkSccStatusSuccess(status, machineId));
  } catch (err) {
    yield put(actions.checkSccStatusError(err));
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* fetchSccPairingCodeWorker(organizationUID) {
  const requestUrl = '/api/GDG/Pairing/Codes';
  while (true) {
    const { pairingCode } = yield call(ApiService.originalRequest, requestUrl, {
      method: 'POST',
      body  : {
        connectorType: constants.CONNECTOR_TYPES.SCC,
        organizationUID,
      },
    });
    yield put(actions.pairSccSetCode(pairingCode));
    yield delay(5 * 60000);
  }
}


function* fetchSccPairingCode(organizationUID) {
  yield race([
    call(fetchSccPairingCodeWorker, organizationUID),
    take(actionTypes.PAIR_SCC_SUCCESS),
    take(actionTypes.PAIR_SCC_CANCEL),
  ]);
}


function* fetchSccMachineIdWorker(organizationUID) {
  let machineId;
  const requestUrl = '/api/GDG/Pairing/Check';
  while (!machineId) {
    yield delay(3000);
    const pairingCode = yield select(selectors.sccPairingCode);
    const response = yield call(ApiService.originalRequest, requestUrl, {
      method: 'POST',
      body  : {
        connectorType: constants.CONNECTOR_TYPES.SCC,
        pairingCode,
        organizationUID,
      },
    });
    machineId = get(response, 'machineId', null);
    if (machineId) {
      LocalStorage.setItem('sccMachineId', machineId);
      yield put(actions.pairSccSuccess(machineId));
    }
  }
}


function* fetchSccMachineId(organizationUID) {
  yield race([
    call(fetchSccMachineIdWorker, organizationUID),
    take(actionTypes.PAIR_SCC_CANCEL),
  ]);
}


function* pairScc({ payload }) {
  try {
    const { organizationUID } = payload;
    yield all([
      call(fetchSccPairingCode, organizationUID),
      call(fetchSccMachineId, organizationUID),
    ]);
  } catch (err) {
    yield call(App.dispatchError, err, messages);
    yield put(actions.pairSccError(err));
  }
}


function resetScc() {
  try {
    LocalStorage.removeItem('sccMachineId');
  } catch (err) {
    // Background action
    if (__DEV__) {
      console.error(err);
    } else {
      appInsights.trackException(err);
    }
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* getSerialNumberToken({ payload }) {
  try {
    const { serialNumber } = payload;
    // @TODO: Exchange serialNumber to token
    const serialNumberToken = serialNumber;
    yield put(actions.getSerialNumberTokenSuccess(serialNumber, serialNumberToken));
  } catch (err) {
    yield put(actions.getSerialNumberTokenError(err));
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* logEvent({ payload }) {
  try {
    const { eventType } = payload;
    yield call(sendEvent, eventType);
  } catch (err) {
    yield call(App.dispatchError, err, {}, null, true);
  }
}


function* sagas() {
  // MDA ---------------------------------------------------------------------------------------------------------------
  yield takeLatest(actionTypes.CHECK_STATUS, checkStatus);
  yield takeLatest(actionTypes.CHECK_BLUE_CABLE_DRIVER, checkBlueCableDriver);
  yield takeLatest(actionTypes.INSTALL_DOWNLOADER, installDownloader);
  yield takeLatest(actionTypes.UPDATE_DOWNLOADER, updateDownloader);
  yield takeLatest(actionTypes.CHECKING_CONNECTION_START, checkConnection);
  yield takeLatest(actionTypes.CHECKING_CONNECTION_STOP, closeConnection);
  yield takeLatest(actionTypes.CHECKING_CONNECTION_ERROR, closeConnection);
  yield takeLatest(actionTypes.GET_DEVICE_DATA, getDeviceData);
  yield takeLatest(actionTypes.INSTALL_BLUE_CABLE_DRIVER, installBlueCableDriver);
  yield takeLatest(actionTypes.LISTENING_BLUETOOTH_START, listenBluetooth);
  yield takeLatest(actionTypes.SEND_BLUETOOTH_PIN, sendBluetoothPin);
  yield takeLatest(actionTypes.LISTENING_BLUETOOTH_STOP, stopListenBluetooth);
  yield takeLatest(actionTypes.CONNECT_BLUE_CABLE, connectBlueCable);
  yield takeLatest(actionTypes.CONNECT_BLUETOOTH, connectBluetooth);
  yield takeLatest(actionTypes.CONNECT_USB, connectUsb);
  yield takeLatest(actionTypes.CONNECT_USB_C, connectUsbC);
  yield takeLatest(actionTypes.CONNECT_SCC, connectScc);
  // SCC ---------------------------------------------------------------------------------------------------------------
  yield takeLatest(actionTypes.CHECK_SCC_STATUS, checkSccStatus);
  yield takeLatest(actionTypes.PAIR_SCC, pairScc);
  yield takeLatest(actionTypes.RESET_SCC, resetScc);
  //--------------------------------------------------------------------------------------------------------------------
  yield takeLatest(actionTypes.GET_SERIAL_NUMBER_TOKEN, getSerialNumberToken);
  yield takeEvery(actionTypes.LOG_EVENT, logEvent);
}

export default [
  sagas,
];
