import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Form/Button';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import messages from '../../messages';
import styles from './DownloadDataModal.pcss';


class ConnectUsb extends React.PureComponent {


  static propTypes = {
    // Explicit actions
    onSetComponent           : PropTypes.func,
    // Implicit props
    connectionId             : PropTypes.string,
    // Implicit actions
    onConnect                : PropTypes.func,
    onStartCheckingConnection: PropTypes.func,
  };


  componentDidUpdate(prevProps) {
    const { connectionId } = this.props;
    if (prevProps.connectionId !== connectionId && connectionId) {
      this.props.onStartCheckingConnection(connectionId);
      this.props.onSetComponent('Downloading');
    }
  }


  render() {
    return (
      <div className={styles.modal__content}>
        <h3 className="modal__subheader "><FormattedMessage {...messages.headers.howToStartConnection} /></h3>
        <p className="modal__info"><FormattedMessage {...messages.connectUsb.info} /></p>
        <div className={styles.illustration}>
          <img src="/assets/svg/connect-by-usb.svg" className="w-100" alt="" />
        </div>
        <Button
          styleModifier="primary"
          labelMessage={messages.buttons.next}
          className="btn--block btn--filled mb-0"
          onClick={this.props.onConnect}
        />
        <div className={styles.divider}>
          <span className={styles.divider__text}><FormattedMessage {...messages.infos.or} /></span>
        </div>
        <Button
          styleModifier="transparent"
          labelMessage={messages.buttons.changeDeviceType}
          className="btn--no-size text--primary m-0"
          onClick={() => this.props.onSetComponent('ChooseConnection')}
        />
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  connectionId: selectors.connectionId(state),
});

const mapDispatchToProps = (dispatch) => ({
  onConnect                : () => dispatch(actions.connectUsb()),
  onStartCheckingConnection: (connectionId) => dispatch(actions.startCheckingConnection(connectionId)),
});

const ConnectedConnectUsb = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectUsb);


export default ConnectedConnectUsb;
