import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import * as constants from '../../constants';
import * as shapes from '../../shapes';
import MdaInit from '../../partials/MdaInit';
import SccInit from '../../partials/SccInit';


class Initializing extends React.PureComponent {

  static propTypes = {
    // Explicit props
    subComponent          : PropTypes.string,
    connectorType         : shapes.connectorType,
    activeClinicMembership: PropTypes.object,
    phiSet                : PropTypes.object, // @TODO: shape
    tryAgainPath          : PropTypes.bool,
    // Explicit actions
    onSetComponent        : PropTypes.func,
    onSetChannel          : PropTypes.func,
    onSetAgainPath        : PropTypes.func,
  };


  onSetChooseConnection() {
    const lastConnectionType = get(this.props.phiSet, 'summaryData.lastConnectionType');
    let componentName;
    switch (lastConnectionType) {
      case 'Bluetooth': {
        componentName = 'ConnectBluetooth';
        break;
      }
      case 'BuiltInUsb': {
        componentName = 'ConnectUsb';
        break;
      }
      case 'MicroUsb': {
        componentName = 'ConnectUsbCable';
        break;
      }
      case 'UsbC': {
        componentName = 'ConnectUsbCCable';
        break;
      }
      case 'BlueCable': {
        componentName = 'ConnectBlueCable';
        break;
      }
      default: {
        componentName = 'ChooseConnection';
      }
    }
    if (this.props.tryAgainPath) {
      componentName = 'ChooseConnection';
      this.props.onSetAgainPath(false);
    }
    this.props.onSetChannel(lastConnectionType);
    this.props.onSetComponent(componentName);
  }


  onSccInit() {
    this.props.onSetComponent('SccWaiting');
  }


  get InitComponent() {
    switch (this.props.connectorType) {
      case constants.CONNECTOR_TYPES.SCC: return SccInit;
      default: return MdaInit;
    }
  }


  getOnInitialized() {
    switch (this.props.connectorType) {
      case constants.CONNECTOR_TYPES.SCC: return () => this.onSccInit();
      default: return () => this.onSetChooseConnection();
    }
  }


  render() {
    const { InitComponent } = this;
    return (
      <InitComponent
        initComponent={this.props.subComponent}
        phiSet={this.props.phiSet}
        activeClinicMembership={this.props.activeClinicMembership}
        onSetAgainPath={this.props.onSetAgainPath}
        onSetComponent={this.props.onSetComponent}
        onInitialized={this.getOnInitialized()}
      />
    );
  }

}


export default Initializing;
