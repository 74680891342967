import { defineMessages } from 'react-intl';


export default defineMessages({
  meta: {
    title      : { id: 'layout.meta.title' },
    description: { id: 'layout.meta.description' },
  },

  topBar: {
    buttons: {
      switchProfile  : { id: 'layout.topBar.buttons.switchProfile' },
      profileSettings: { id: 'layout.topBar.buttons.profileSettings' },
      clinicSettings : { id: 'layout.topBar.buttons.clinicSettings' },
      accountSettings: { id: 'layout.topBar.buttons.accountSettings' },
      logout         : { id: 'layout.topBar.buttons.logout' },
      enroll         : { id: 'layout.topBar.buttons.enroll' },
      gc             : { id: 'layout.topBar.buttons.gc' },
      cnc            : { id: 'layout.topBar.buttons.cnc' },
    },
    headers: {
      yourApplications: { id: 'layout.topBar.headers.yourApplications' },
    },
  },

  sidebar: {
    headers: {
      myFamily          : { id: 'layout.sidebar.headers.myFamily' },
      myHCPs            : { id: 'layout.sidebar.headers.myHCPs' },
      notifications     : { id: 'layout.sidebar.headers.notifications' },
      pendingInvitations: { id: 'layout.sidebar.headers.pendingInvitations' },
    },

    buttons: {
      accountSettings     : { id: 'layout.sidebar.buttons.accountSettings' },
      helpCenter          : { id: 'layout.sidebar.buttons.helpCenter' },
      notifications       : { id: 'layout.sidebar.buttons.notifications' },
      invite              : { id: 'layout.sidebar.buttons.invite' },
      viewAllNotifications: { id: 'layout.sidebar.buttons.viewAllNotifications' },
      menu                : { id: 'layout.sidebar.buttons.menu' },
      fullPatientsList    : { id: 'layout.sidebar.buttons.fullPatientsList' },
      addPatient          : { id: 'layout.sidebar.buttons.addPatient' },
      clinicSettings      : { id: 'layout.sidebar.buttons.clinicSettings' },
      otherClinics        : { id: 'layout.sidebar.buttons.otherClinics' },
      addClinic           : { id: 'layout.sidebar.buttons.addClinic' },
      patients            : { id: 'layout.sidebar.buttons.patients' },
      myResults           : { id: 'layout.sidebar.buttons.myResults' },
      myFamily            : { id: 'layout.sidebar.buttons.myFamily' },
      inviteFamilyMember  : { id: 'layout.sidebar.buttons.inviteFamilyMember' },
      enrollInClinic      : { id: 'layout.sidebar.buttons.enrollInClinic' },
      mySharingRequests   : { id: 'layout.sidebar.buttons.mySharingRequests' },
      otherSharingRequests: { id: 'layout.sidebar.buttons.otherSharingRequests' },
      decline             : { id: 'layout.sidebar.buttons.decline' },
      accept              : { id: 'layout.sidebar.buttons.accept' },
      refreshResults      : { id: 'layout.sidebar.buttons.refreshResults' },
    },

    labels: {
      patientProfile  : { id: 'layout.sidebar.labels.patientProfile' },
      hcpProfile      : { id: 'layout.sidebar.labels.hcpProfile' },
      fullPatientsList: { id: 'layout.sidebar.labels.fullPatientsList' },
      agpView         : { id: 'layout.sidebar.labels.agpView' },
    },
  },

  infos: {
    wantToCreate         : { id: 'layout.infos.wantToCreate' },
    wantToJoin           : { id: 'layout.infos.wantToJoin' },
    waitForApprovalCreate: { id: 'layout.infos.waitForApprovalCreate' },
    waitForApprovalJoin  : { id: 'layout.infos.waitForApprovalJoin' },
    clinicNotConfigured  : { id: 'layout.infos.clinicNotConfigured' },
    clinicLicence        : { id: 'layout.infos.clinicLicence' },
    clinicLicenceNotAdmin: { id: 'layout.infos.clinicLicenceNotAdmin' },
  },

  printMode: {
    closePrintMode: { id: 'layout.printMode.closePrintMode' },
  },

  buttons: {
    setLicenceKey: { id: 'layout.buttons.setLicenceKey' },
  },

  reports: {
    headers: {
      files   : { id: 'layout.reports.headers.files' },
      allFiles: { id: 'layout.reports.headers.allFiles' },
    },

    buttons: {
      print   : { id: 'layout.reports.buttons.print' },
      download: { id: 'layout.reports.buttons.download' },
    },

    labels: {
      hideEmptyRows  : { id: 'layout.reports.labels.hideEmptyRows' },
      showDayDividers: { id: 'layout.reports.labels.showDayDividers' },
    },
  },

});
