import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import get from 'lodash/get';
import has from 'lodash/has';
import * as selectors from '../../selectors';
import * as shapes from '../../shapes';
import styles from '../../components/DownloadDataModal/DownloadDataModal.pcss';

import Initializing from './Initializing';
import InstallDownloader from './InstallDownloader';
import NoConnection from './NoConnection';
import NoLicense from './NoLicense';
import ConnectionFailed from './ConnectionFailed';


class SccInit extends React.PureComponent {

  static propTypes = {
    // Explicit props
    initComponent         : PropTypes.string,
    activeClinicMembership: PropTypes.object,
    // Explicit actions
    onInitialized         : PropTypes.func.isRequired,
    onSetAgainPath        : PropTypes.func,
    onSetComponent        : PropTypes.func,
    // Implicit props
    status                : shapes.status,
    scc                   : shapes.scc,
  };


  constructor(props) {
    super(props);

    this.components = {
      Initializing,
      InstallDownloader,
      NoConnection,
      NoLicense,
      ConnectionFailed,
    };

    this.defaultComponent = 'Initializing';

    this.state = {
      component: props.initComponent || this.defaultComponent,
    };

  }


  onSetComponent(componentName) {
    if (!has(this.components, componentName)) {
      this.props.onSetComponent(componentName);
      return;
    }
    this.setState({ component: componentName });
  }


  render() {
    const { component } = this.state;
    const Component = get(this.components, component, null);

    return (
      <Component
        status={this.props.status}
        scc={this.props.scc}
        isOpen={this.isOpen}
        activeClinicMembership={this.props.activeClinicMembership}
        onInitialized={this.props.onInitialized}
        onSetComponent={(componentName) => this.onSetComponent(componentName)}
        onCancel={() => this.onClose()}
        onSetAgainPath={this.props.onSetAgainPath}
      />
    );
  }

}


const mapStateToProps = (state) => ({
  status: selectors.status(state),
  scc   : selectors.downloader(state),
});


const ConnectedSccInit = connect(
  mapStateToProps,
)(SccInit);


export default withStyles(styles)(ConnectedSccInit);
