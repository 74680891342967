import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Button from 'components/Form/Button';
import DownloadWithCircle from 'svg/download-with-circle.svg';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import * as shapes from '../../shapes';
import messages from '../../messages';
import styles from './DownloadDataModal.pcss';


class DownloaderOutdated extends React.PureComponent {

  static propTypes = {
    // Explicit props
    onSetComponent    : PropTypes.func,
    // Implicit actions
    status            : shapes.status,
    onLogEvent        : PropTypes.func,
    onUpdateDownloader: PropTypes.func,
  };


  componentDidMount() {
    this.props.onLogEvent();
  }


  componentDidUpdate() {
    if (this.props.status === constants.DOWNLOADER_STATUSES.UPDATE_ERROR) {
      this.props.onSetComponent('Initializing');
    }
  }


  onUpdateNow() {
    if (this.isDownloaderAppCloseNeeded) {
      this.props.onSetComponent('Initializing');
      return;
    }
    this.props.onUpdateDownloader();
  }


  get isDownloaderAppCloseNeeded() {
    return this.props.status === constants.DOWNLOADER_STATUSES.UPDATE_ENDPOINT_NOT_FOUND;
  }


  renderCloseAppInfo() {
    if (!this.isDownloaderAppCloseNeeded) {
      return null;
    }

    return (
      <div className={styles.downloader_outdated__close_app_info}>
        <FormattedMessage
          {...messages.downloaderOutdated.closeAppInfo}
          values={{ appName: <b>{constants.DOWNLOADER_TRAY_APP_NAME}</b> }}
        />
        <FormattedMessage
          {...messages.downloaderOutdated.clickUpdateNow}
          values={{ b: (chunk) => <b>{chunk}</b> }}
        />
      </div>
    );
  }


  renderInfo() {
    return (
      <div className={styles.downloader_outdated__info}>
        <FormattedMessage
          {...messages.downloaderOutdated.info}
          values={{ appName: <b>{constants.DOWNLOADER_APP_NAME}</b> }}
        />
        { this.renderCloseAppInfo() }
      </div>
    );
  }


  renderImg() {
    if (this.isDownloaderAppCloseNeeded) {
      return <img src="/assets/img/close_downloader_tray.png" alt="Close Downloader tray" />;
    }

    return <DownloadWithCircle className={styles.downloader_outdated__loader_icon} />;
  }


  render() {
    return (
      <div>
        <div className={styles.downloader_outdated}>
          { this.renderInfo() }
        </div>
        <div className={styles.downloader_outdated__img}>
          { this.renderImg() }
        </div>
        <div className="modal__actions">
          <Button
            type="button"
            styleModifier="primary"
            labelMessage={messages.buttons.updateNow}
            className="btn--block btn--filled"
            onClick={() => this.onUpdateNow()}
          />
        </div>
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  status: selectors.status(state),
});

const mapDispatchToProps = (dispatch) => ({
  onLogEvent        : () => dispatch(actions.logEvent('UpgradePromptDisplayed')),
  onUpdateDownloader: () => dispatch(actions.updateDownloader()),
});

const ConnectedDownloaderOutdated = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DownloaderOutdated);


export default ConnectedDownloaderOutdated;
