import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import { AppContext } from 'context';
import Button from 'components/Form/Button';
import Link from 'components/Link';
import Account from 'modules/Account';
import * as constants from '../../constants';
import messages from '../../messages';
import styles from './DownloadDataModal.pcss';


class WarningsBeforeDownloadData extends React.PureComponent {

  static contextType = AppContext;

  static propTypes = {
    // Explicit props
    country          : PropTypes.object,
    warnings         : PropTypes.arrayOf(PropTypes.string),
    activeProfileType: Account.shapes.profileType,
    // Explicit actions
    onCancel         : PropTypes.func.isRequired,
    onSetComponent   : PropTypes.func,
    onClearWarnings  : PropTypes.func,
  };


  componentWillUnmount() {
    this.props.onClearWarnings();
  }


  get isPwdProfile() {
    return this.props.activeProfileType === Account.constants.PROFILE_TYPES.PWD;
  }


  get haveMoreThanOneWarning() {
    const { warnings } = this.props;
    return warnings.length > 1;
  }


  renderHeader(message) {
    if (!this.haveMoreThanOneWarning) {
      return null;
    }

    return (
      <div className="brand__paragraph--bold">
        <FormattedMessage {...message} />
      </div>
    );
  }


  renderHr(type) {
    const { warnings } = this.props;

    if (!this.haveMoreThanOneWarning) {
      return null;
    }

    if (
      type === constants.WARNING_TYPES.MISMATCH_TIME
      && !warnings.includes(constants.WARNING_TYPES.MISMATCH_TARGET_RANGES)
    ) {
      return null;
    }

    if (
      type === constants.WARNING_TYPES.NEW_DEVICE_CONNECTED
      && !warnings.includes(constants.WARNING_TYPES.MISMATCH_TARGET_RANGES)
      && !warnings.includes(constants.WARNING_TYPES.MISMATCH_TIME)
    ) {
      return null;
    }

    return (
      <div className={styles.warnings__hr} />
    );
  }


  renderNewDeviceConnected() {
    const { warnings } = this.props;
    if (!warnings.includes(constants.WARNING_TYPES.NEW_DEVICE_CONNECTED)) {
      return null;
    }

    let infoMessage = messages.infos.warningConnectedNewDeviceSingle;
    if (this.haveMoreThanOneWarning) {
      infoMessage = messages.infos.warningConnectedNewDevice;
    }

    return (
      <>
        <div className="row align-items-center">
          <div className="col">
            { this.renderHeader(messages.headers.warningConnectedNewDeviceSingle) }
            <p className="brand__paragraph brand__fontSecondary">
              <FormattedMessage {...infoMessage} />
            </p>
            { this.renderHr() }
          </div>
        </div>
      </>
    );
  }


  renderMismatchTime() {
    const { warnings } = this.props;
    if (!warnings.includes(constants.WARNING_TYPES.MISMATCH_TIME)) {
      return null;
    }

    let infoMessage = messages.infos.warningMismatchTimeSingle;
    if (this.haveMoreThanOneWarning) {
      infoMessage = messages.infos.warningMismatchTime;
    }

    return (
      <>
        <div className="row align-items-center">
          <div className="col">
            { this.renderHeader(messages.headers.warningMismatchTime) }
            <p className="brand__paragraph brand__fontSecondary">
              <FormattedMessage {...infoMessage} />
            </p>
            { this.renderHr(constants.WARNING_TYPES.MISMATCH_TIME) }
          </div>
        </div>
      </>
    );
  }


  renderUnavailableTime() {
    const { warnings } = this.props;
    if (!warnings.includes(constants.WARNING_TYPES.UNAVAILABLE_TIME)) {
      return null;
    }

    let infoMessage = messages.infos.warningUnavailableTimeSingle;
    if (this.haveMoreThanOneWarning) {
      infoMessage = messages.infos.warningUnavailableTime;
    }

    return (
      <>
        <div className="row align-items-center">
          <div className="col">
            { this.renderHeader(messages.headers.warningUnavailableTime) }
            <p className="brand__paragraph brand__fontSecondary">
              <FormattedMessage {...infoMessage} />
            </p>
            { this.renderHr(constants.WARNING_TYPES.MISMATCH_TIME) }
          </div>
        </div>
      </>
    );
  }


  renderMismatchTargetRanges() {
    const { warnings, country } = this.props;
    const iosUrl = get(country, 'settings.iosStoreURL', null);
    const androidUrl = get(country, 'settings.androidStoreURL', null);
    if (!warnings.includes(constants.WARNING_TYPES.MISMATCH_TARGET_RANGES)) {
      return null;
    }

    let infoMessage = this.isPwdProfile
      ? messages.infos.warningMismatchTargetRangesSingle : messages.infos.warningMismatchTargetRangesSingleHcp;
    if (this.haveMoreThanOneWarning) {
      infoMessage = this.isPwdProfile
        ? messages.infos.warningMismatchTargetRanges : messages.infos.warningMismatchTargetRangesHcp;
    }

    const additionalInfo = this.isPwdProfile && !this.haveMoreThanOneWarning ? (
      <div>
        <br />
        <FormattedMessage
          {...messages.infos.warningMismatchTargetRangesSingleInfo}
          values={{
            contour: (
              <b>
                <FormattedMessage {...messages.labels.contour} />
              </b>
            ),
          }}
        />
      </div>
    ) : null;

    return (
      <>
        <div className="row align-items-center">
          <div className="col">
            { this.renderHeader(messages.headers.warningMismatchTargetRanges) }
            <div className="brand__paragraph brand__fontSecondary">
              <FormattedMessage {...infoMessage} />
              { additionalInfo}
            </div>
            {
              this.isPwdProfile
                && (
                  <>
                    <Link
                      to={iosUrl}
                      className={styles.warnings__stores}
                    >
                      <img src="/assets/svg/ios.svg" alt="" />
                      <div>
                        <p className="brand__paragraph--bold">
                          <FormattedMessage {...messages.labels.iOSStore} />
                        </p>
                        <p className="brand__caption">
                          <FormattedMessage {...messages.labels.forIOS} />
                        </p>
                      </div>
                    </Link>
                    <Link
                      to={androidUrl}
                      className={styles.warnings__stores}
                    >
                      <img src="/assets/svg/android.svg" alt="" />
                      <div>
                        <p className="brand__paragraph--bold">
                          <FormattedMessage {...messages.labels.androidStore} />
                        </p>
                        <p className="brand__caption brand__fontSecondary">
                          <FormattedMessage {...messages.labels.forAndroid} />
                        </p>
                      </div>
                    </Link>
                  </>
                )
            }
          </div>
        </div>
      </>
    );
  }


  renderActions() {
    return (
      <div className="modal__actions row">
        <div className="col-6">
          <Button
            styleModifier="primary"
            labelMessage={messages.buttons.warningReject}
            className="btn--block"
            onClick={this.props.onCancel}
          />
        </div>
        <div className="col-6">
          <Button
            styleModifier="primary"
            labelMessage={messages.buttons.warningAccept}
            className="btn--block btn--filled"
            onClick={() => this.props.onSetComponent('Results')}
          />
        </div>
      </div>
    );
  }


  render() {
    return (
      <div>
        { this.renderNewDeviceConnected() }
        { this.renderUnavailableTime() }
        { this.renderMismatchTime() }
        { this.renderMismatchTargetRanges() }
        { this.renderActions() }
      </div>
    );
  }

}

export default WarningsBeforeDownloadData;
