import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import * as shapes from '../../shapes';
import messages from '../../messages';
import LaunchDownloaderInstaller from '../LaunchDownloaderInstaller';
import styles from '../../components/DownloadDataModal/DownloadDataModal.pcss';


class InstallingDownloader extends React.Component {


  static propTypes = {
    // Explicit props
    downloader        : shapes.downloader,
    // Explicit actions
    onSetComponent    : PropTypes.func,
    // Implicit props
    status            : shapes.status,
    isInProgress      : PropTypes.bool,
    // Implicit actions
    onCancelInstalling: PropTypes.func,
  };


  componentDidUpdate(prevProps) {
    const { status, downloader } = this.props;
    if (prevProps.status !== status) {
      switch (status) {
        case constants.DOWNLOADER_STATUSES.NO_CONNECTION: {
          if (downloader.mdaVersion) {
            this.props.onSetComponent('NoConnection');
          } else {
            this.props.onSetComponent('InstallDownloader');
          }
          break;
        }
        case constants.DOWNLOADER_STATUSES.TIMEOUT: {
          this.props.onSetComponent('Timeout');
          break;
        }
        default: {
          this.props.onSetComponent('InstalledDownloader');
        }
      }
    }
  }


  componentWillUnmount() {
    if (this.props.isInProgress) {
      this.props.onCancelInstalling();
    }
  }


  render() {
    return (
      <div
        className={styles.modal__content}
        role="presentation"
      >
        <h3 className="modal__subheader "><FormattedMessage {...messages.installingDownloader.header} /></h3>
        <p className="modal__info"><FormattedMessage {...messages.installingDownloader.info} /></p>
        <div className={styles.illustration}>
          <img src="/assets/svg/loader.svg" className="rotatingLoader" alt="" />
        </div>
        <p className="modal__info text--center mb-5"><FormattedMessage {...messages.installingDownloader.waiting} /></p>
        <LaunchDownloaderInstaller />
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  status      : selectors.status(state),
  isInProgress: selectors.isInstallDownloaderInProgress(state),
});

const mapDispatchToProps = (dispatch) => ({
  onCancelInstalling: () => dispatch(actions.installDownloaderCancel()),
});

const ConnectedInstallingDownloader = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InstallingDownloader);


export default ConnectedInstallingDownloader;
