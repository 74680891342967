import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { injectIntl } from 'react-intl';
import { AnimatePresence, motion } from 'framer-motion';
import withStyles from 'isomorphic-style-loader/withStyles';
import isEmpty from 'lodash/isEmpty';
import shapeIntl from 'shapes/intlShape';
import Account from 'modules/Account';
import Hcp from 'modules/Hcp';
import HcpExports from 'modules/HcpExports';
import Meter from 'modules/Meter';
import PatientResults from 'modules/PatientResults';
import AmbulatoryGlucoseProfile from 'modules/AmbulatoryGlucoseProfile';
import Patient from 'modules/Patient';
import App from 'modules/App';
import CloudDrive from 'modules/CloudDrive';
import Link from 'components/Link';
import Switch from 'components/Switch';
import * as constants from '../../../constants';
import messages from '../../../messages';
import ActiveClinicMembershipBtn from './ActiveClinicMembershipBtn';
import EnrollCodeBtn from './EnrollCodeBtn';
import ProfileMenu from './ProfileMenu';
import Notifications from './Notifications';
import ReportButton from './ReportButton';
import styles from './TopBar.pcss';


class TopBar extends React.Component {

  static propTypes = {
    // Explicit props
    isReadOnly                   : PropTypes.bool,
    activeProfileType            : Account.shapes.profileType,
    activeVisit                  : PropTypes.object,
    // Implicit props
    activeClinicMembership       : Account.shapes.clinicMembership,
    printMode                    : PropTypes.bool,
    search                       : PropTypes.string,
    deviceMode                   : PropTypes.string,
    featureToggles               : PropTypes.arrayOf(PropTypes.string),
    route                        : App.shapes.route,
    resultsIsInProgress          : PropTypes.bool,
    patientPhiSet                : PropTypes.object,
    hcpPhiSet                    : PropTypes.object,
    hcpPhiSetDocumentId          : PropTypes.string,
    patientPhiSetDocumentId      : PropTypes.string,
    patientProfile               : PropTypes.object,
    hcpActivePatient             : PropTypes.object,
    intl                         : shapeIntl,
    isEnrollPatientInProgress    : PropTypes.bool,
    isMergePatientInProgress     : PropTypes.bool,
    isStartPatientVisitInProgress: PropTypes.bool,
    isEndPatientVisitInProgress  : PropTypes.bool,
    isFetchPhiSetInProgress      : PropTypes.bool,
    isFetchReadingsInProgress    : PropTypes.bool,
    isSyncInProgress             : PropTypes.bool,
    // Implicit actions
    setDeviceMode                : PropTypes.func,
    prepareAgpPreview            : PropTypes.func,
    storeSelectedReportTypes     : PropTypes.func,
    goToReportsView              : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.state = {
      activePatientsLength: 0,
    };
  }


  onSetActivePatientsLength(length) {
    this.setState({ activePatientsLength: length });
  }


  onSetDeviceMode() {
    const { setDeviceMode, deviceMode, prepareAgpPreview } = this.props;
    if (deviceMode === 'BGM') {
      prepareAgpPreview(this.activePatient, this.phiSet, this.phiSetDocumentId, this.prepareAgpPreviewSuccessAction);
    }
    setDeviceMode(deviceMode === 'AGP' ? 'BGM' : 'AGP');
  }


  get activePatient() {
    const {
      hcpActivePatient, activeProfileType, patientProfile,
    } = this.props;

    const phiSet = activeProfileType === Account.constants.PROFILE_TYPES.HCP
      ? hcpActivePatient
      : patientProfile;

    return phiSet;
  }


  get phiSet() {
    const {
      patientPhiSet, hcpPhiSet, activeProfileType,
    } = this.props;

    const phiSet = activeProfileType === Account.constants.PROFILE_TYPES.HCP
      ? hcpPhiSet
      : patientPhiSet;

    return phiSet;
  }


  get phiSetDocumentId() {
    const {
      patientPhiSetDocumentId, hcpPhiSetDocumentId, activeProfileType,
    } = this.props;

    const phiSet = activeProfileType === Account.constants.PROFILE_TYPES.HCP
      ? hcpPhiSetDocumentId
      : patientPhiSetDocumentId;

    return phiSet;
  }


  get prepareAgpPreviewSuccessAction() {
    const { activeProfileType } = this.props;

    const successAction = activeProfileType === Account.constants.PROFILE_TYPES.HCP
      ? Hcp.actions.setReadings
      : Patient.actions.setReadings;

    return successAction;
  }


  get isPrintMode() {
    return this.props.printMode;
  }


  renderDownloadMeter() {
    if (this.props.activeProfileType === Account.constants.PROFILE_TYPES.CG) {
      return null;
    }

    const isDisabled = this.props.isEnrollPatientInProgress
      || this.props.isMergePatientInProgress
      || this.props.isFetchPhiSetInProgress
      || this.props.isFetchReadingsInProgress
      || this.props.isStartPatientVisitInProgress
      || this.props.isEndPatientVisitInProgress
      || this.props.isSyncInProgress;

    return (
      <div className={styles.element}>
        <Meter.partials.DownloadDataFromMeterBtn
          className="btn--block btn--filled"
          isReadOnly={this.props.isReadOnly}
          isDisabled={isDisabled}
        />
      </div>
    );
  }


  renderActivePatientOptions() {
    const { deviceMode, resultsIsInProgress } = this.props;
    if (!this.phiSet) return null;
    return (
      <>
        <Switch
          isDisabled={resultsIsInProgress}
          labelMessage={messages.sidebar.labels.agpView}
          isActive={deviceMode === 'AGP'}
          onClick={() => this.onSetDeviceMode()}
        />
        <ReportButton
          storeSelectedReportTypes={this.props.storeSelectedReportTypes}
          goToReportsView={this.props.goToReportsView}
          isInProgress={resultsIsInProgress}
          featureToggles={this.props.featureToggles}
        />
      </>
    );
  }


  renderPatientListOptions() {
    // TODO: Make components injection in the top bar!!!
    const { route } = this.props;
    if (!route || route.name !== 'patients-list') {
      return null;
    }
    return (
      <HcpExports.components.ExportDataMenu
        isInProgress={false}
      />
    );
  }


  renderPwdContentType() {
    return (
      <>
        <div className="d-flex align-items-center">
          <div className={styles.element}>
            <Link to="/">
              <img src="/assets/img/gco-logo.png" className={styles.logo} alt="GlucoContro.online" />
            </Link>
          </div>
          {this.renderDownloadMeter()}
        </div>
        <div className={cn(styles.element, 'd-flex align-items-center')}>
          { this.renderActivePatientOptions() }
          <div className={cn(styles.separate)} />
          <Notifications />
          <ProfileMenu />
        </div>
      </>
    );
  }


  renderPatientList() {
    const { activePatientsLength } = this.state;

    if (isEmpty(this.props.search)) return null;
    return (
      <AnimatePresence>
        <motion.div
          initial={{ height: 0 }}
          animate={activePatientsLength === 0 ? { display: 'none' } : { height: 'auto', display: 'block' }}
          exit={{ height: 0 }}
          className={styles.searchModal}
        >
          <Hcp.partials.ActivePatients
            id="topBar"
            activeClinicMembership={this.props.activeClinicMembership}
            isWithoutLoad
            maxPatientLength={Hcp.constants.PATIENTS_LIST_LENGTH}
            setActivePatientsLength={(length) => this.onSetActivePatientsLength(length)}
          />
        </motion.div>
      </AnimatePresence>
    );
  }


  renderHcpContentType() {
    const { activeVisit, intl } = this.props;
    const isDisabled = !!activeVisit;
    const tooltipText = activeVisit ? intl.formatMessage(App.messages.infos.menuDisabledIfActiveVisit) : '';
    return (
      <>
        <div className="d-flex align-items-center">
          <div
            className={cn(styles.element, { [styles['element--isDisabled']]: isDisabled })}
            data-for="globalTooltip"
            data-tip={tooltipText}
          >
            <Link to={activeVisit ? '' : '/'}>
              <img src="/assets/img/gco-logo.png" className={styles.logo} alt="GlucoContro.online" />
            </Link>
          </div>
          <div
            className={cn(styles.element, { [styles['element--isDisabled']]: isDisabled })}
            data-for="globalTooltip"
            data-tip={tooltipText}
          >
            <ActiveClinicMembershipBtn />
          </div>
          <div className={styles.element}>
            <EnrollCodeBtn />
          </div>
          {this.renderDownloadMeter()}
        </div>
        <div
          className={cn(styles.element, styles.search, 'd-flex align-items-center', {
            [styles['element--isDisabled']]: isDisabled,
          })}
          data-for="globalTooltip"
          data-tip={tooltipText}
        >
          <Hcp.partials.SearchPatients
            forId="topBar"
            activeClinicMembership={this.props.activeClinicMembership}
            formName={constants.TOPBAR_SEARCH_FORM}
            clearOnBlur
            tooltipText={tooltipText}
            isDisabled={isDisabled}
          />
          {this.renderPatientList()}
        </div>
        <div className={cn(styles.element, 'd-flex align-items-center')}>
          { this.renderActivePatientOptions() }
          { this.renderPatientListOptions() }
          <div className={cn(styles.separate)} />
          <div
            className={cn({ [styles['element--isDisabled']]: isDisabled })}
            data-for="globalTooltip"
            data-tip={tooltipText}
          >
            <Notifications />
          </div>
          <div
            className={cn({ [styles['element--isDisabled']]: isDisabled })}
            data-for="globalTooltip"
            data-tip={tooltipText}
          >
            <ProfileMenu />
          </div>
        </div>
      </>
    );
  }


  render() {
    return (
      <div className={cn(styles.topBarHolder, {
        [styles.hide]: this.isPrintMode,
      })}
      >
        <div className={styles.topBar}>
          {
            this.props.activeProfileType === Account.constants.PROFILE_TYPES.HCP
              ? this.renderHcpContentType()
              : this.renderPwdContentType()
          }
        </div>
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  search                       : Hcp.selectors.searchSelector('topBar')(state),
  hcpPhiSet                    : Hcp.selectors.phiSet(state),
  patientPhiSet                : Patient.selectors.phiSet(state),
  hcpActivePatient             : Hcp.selectors.activePatient(state),
  patientProfile               : Account.selectors.patientProfile(state),
  hcpPhiSetDocumentId          : Hcp.selectors.phiSetDocumentId(state),
  patientPhiSetDocumentId      : Patient.selectors.phiSetDocumentId(state),
  activeClinicMembership       : Account.selectors.activeClinicMembership(state),
  featureToggles               : App.selectors.featureToggles(state),
  printMode                    : App.selectors.printMode(state),
  route                        : App.selectors.route(state),
  deviceMode                   : PatientResults.selectors.deviceMode(state),
  resultsIsInProgress          : PatientResults.selectors.resultsIsInProgress(state),
  isEnrollPatientInProgress    : Hcp.selectors.isEnrollPatientInProgress(state),
  isMergePatientInProgress     : Hcp.selectors.isMergePatientInProgress(state),
  isStartPatientVisitInProgress: Hcp.selectors.isStartPatientVisitInProgress(state),
  isEndPatientVisitInProgress  : Hcp.selectors.isEndPatientVisitInProgress(state),
  isFetchPhiSetInProgress      : CloudDrive.selectors.isFetchPhiSetInProgress(state),
  isFetchReadingsInProgress    : CloudDrive.selectors.isFetchReadingsInProgress(state),
  isSyncInProgress             : Hcp.selectors.isSyncInProgress(state) || Patient.selectors.isSyncInProgress(state),
});


const mapDispatchToProps = (dispatch) => ({
  setDeviceMode    : (deviceMode) => dispatch(PatientResults.actions.setDeviceMode(deviceMode)),
  prepareAgpPreview: (activePatient, phiSet, phiSetDocumentId, successAction) =>
    dispatch(AmbulatoryGlucoseProfile.actions.prepareAgpPreview(
      activePatient, phiSet, phiSetDocumentId, successAction
    )),
  storeSelectedReportTypes: (reportTypes) => dispatch(PatientResults.actions.storeSelectedReportTypes(reportTypes)),
  goToReportsView         : () => dispatch(App.actions.goToReportsView()),
});


const ConnectedTopBar = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(TopBar));


export default withStyles(styles)(ConnectedTopBar);
