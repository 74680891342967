import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { AppContext } from 'context';
import { getSlug } from 'helpers/urlTools';
import Alert from 'components/Alert';
import App from 'modules/App';
import history from 'helpers/history';
import messages from 'modules/Hcp/messages';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import * as actions from '../../actions';


class EnrolledPatientAlert extends React.PureComponent {

  static contextType = AppContext;

  static propTypes = {
    // Explicit props
    activePatient: PropTypes.shape({
      id                       : PropTypes.string,
      source                   : PropTypes.string,
      createTimestamp          : PropTypes.number,
      devicesSerialNumberTokens: PropTypes.arrayOf(PropTypes.string),
      isExplicitBannerDiscarded: PropTypes.bool,
    }),
    activeClinicMembership: PropTypes.object,
    patients              : PropTypes.array,
    // Implicit props
    isMergeInProgress     : PropTypes.bool,
    isRemoveInProgress    : PropTypes.bool,
    hasRemoveErrors       : PropTypes.bool,
    // Implicit actions
    onUpdatePatientProfile: PropTypes.func,
    onOpenModal           : PropTypes.func,
    onSetFormContext      : PropTypes.func,
  };


  componentDidUpdate(prevProps) {
    const { isMergeInProgress, isRemoveInProgress, hasRemoveErrors } = this.props;
    if (
      prevProps.isRemoveInProgress !== isRemoveInProgress
        && !isMergeInProgress && !isRemoveInProgress && !hasRemoveErrors
    ) {
      const { organizationUID, name } = get(this.props.activeClinicMembership, 'clinic', {});
      const url = this.context.getUrl('patients-list', { clinicSlug: getSlug(name), organizationUID });
      history.push(url);
    }
  }


  onMergePatient() {
    this.props.onOpenModal(constants.MERGE_PATIENT_MODAL);
  }


  onRemovePatient() {
    this.props.onSetFormContext(constants.REMOVE_PATIENT_FORM, this.props.activePatient);
    this.props.onOpenModal(constants.REMOVE_PATIENT_MODAL);
  }


  onClose() {
    const { activePatient, activeClinicMembership } = this.props;
    this.props.onUpdatePatientProfile(activePatient, { isExplicitBannerDiscarded: true }, activeClinicMembership);
  }


  render() {
    const { activePatient, patients } = this.props;
    if (
      !activePatient
      || activePatient.isExplicitBannerDiscarded
      || !activePatient.createTimestamp
      || !isEmpty(activePatient.devicesSerialNumberTokens)
      || activePatient.source !== App.constants.PATIENTS_SOURCES.ENROLL_CODE
    ) {
      return null;
    }
    const { createTimestamp } = activePatient;
    const nowTimestamp = +moment().locale('en--account').format('X');
    const expireDiff = 7 * 86400;
    if (nowTimestamp - createTimestamp > expireDiff) {
      return null;
    }

    const alertActions = [
      {
        action : () => this.onRemovePatient(),
        message: messages.buttons.removePatient,
      },
    ];
    if (patients && patients.length) {
      alertActions.push({
        action : () => this.onMergePatient(),
        message: messages.buttons.mergePatient,
      });
    }
    return (
      <Alert
        type="warning"
        message={messages.alerts.enrolledPatient}
        actions={alertActions}
        onClose={() => this.onClose()}
      />
    );
  }

}


const mapStateToProps = (state) => ({
  isMergeInProgress : selectors.isMergePatientInProgress(state),
  isRemoveInProgress: selectors.isRemovePatientInProgress(state),
  hasRemoveErrors   : selectors.hasRemovePatientErrors(state),
});


const mapDispatchToProps = (dispatch) => ({
  onUpdatePatientProfile: (patient, newPatientValues, clinicMembership) =>
    dispatch(actions.updatePatient(patient, newPatientValues, clinicMembership)),
  onOpenModal     : (modalId) => dispatch(App.actions.openModal(modalId)),
  onSetFormContext: (formName, contextData) => dispatch(App.actions.setFormContext(formName, contextData)),
});


const ConnectedEnrolledPatientAlert = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EnrolledPatientAlert);


export default ConnectedEnrolledPatientAlert;
