import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import App from 'modules/App';
import Account from 'modules/Account';
import Hcp from 'modules/Hcp';
import * as constants from '../../constants';


class AddPatient extends React.PureComponent {

  static propTypes = {
    // Explicit actions
    onSetComponent: PropTypes.func,
    // Implicit props
    isHcpAccount  : PropTypes.bool,
    openModalId   : PropTypes.string,
  };


  onClose() {
    this.props.onSetComponent(this.props.openModalId ? 'Results' : 'ChooseConnection');
  }


  render() {
    if (!this.props.isHcpAccount) {
      return null;
    }

    return (
      <Hcp.partials.AddPatientForm
        modalId={constants.DOWNLOAD_DATA_MODAL}
        isAutoActivation
        onClose={() => this.onClose()}
      />
    );
  }

}


const mapStateToProps = (state) => ({
  isHcpAccount: Account.selectors.isHcpAccount(state),
  openModalId : App.selectors.modal(state),
});


const mapDispatchToProps = (dispatch) => ({
  onCloseModal: () => dispatch(App.actions.closeModal()),
});


const ConnectedAddPatient = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddPatient);


export default ConnectedAddPatient;
