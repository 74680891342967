import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Form/Button';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import * as shapes from '../../shapes';
import messages from '../../messages';
import styles from './DownloadDataModal.pcss';


class ConnectBlueCable extends React.PureComponent {

  static propTypes = {
    // Explicit actions
    onSetComponent           : PropTypes.func,
    // Implicit props
    connectionId             : PropTypes.string,
    connectionStatus         : shapes.connectionStatus,
    // Implicit actions
    onConnect                : PropTypes.func,
    onStartCheckingConnection: PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.state = {
      step: 1,
    };
  }


  componentDidUpdate(prevProps) {
    const { connectionId, connectionStatus } = this.props;
    if (prevProps.connectionId !== connectionId && connectionId) {
      this.onStartCheckingConnection(connectionId);
    }

    if (
      prevProps.connectionStatus !== connectionStatus
      && (
        connectionStatus === constants.CONNECTION_STATUSES.IN_PROGRESS
        || connectionStatus === constants.CONNECTION_STATUSES.SUCCESS
      )
    ) {
      this.props.onSetComponent('Downloading');
    }
  }


  onChangeDeviceType() {
    this.props.onSetComponent('ChooseConnection');
    this.setState({ step: 1 });
  }


  onStartCheckingConnection(connectionId) {
    this.setState({ step: 2 });
    this.props.onStartCheckingConnection(connectionId);
  }


  onConnect() {
    this.props.onConnect();
    this.setState({ step: 2 });
  }


  renderStep1() {
    return (
      <>
        <p className="modal__info"><FormattedMessage {...messages.connectBlueCable.info} /></p>
        <div className={styles.illustration}>
          <img src="/assets/svg/connect-by-blue-cable.svg" className="w-100" alt="" />
        </div>
        <div className="modal__actions">
          <Button
            styleModifier="primary"
            labelMessage={messages.buttons.next}
            className="btn--block btn--filled"
            onClick={() => this.onConnect()}
          />
        </div>
      </>
    );
  }


  renderStep2() {
    return (
      <>
        <p className="modal__info"><FormattedMessage {...messages.connectBlueCable.pressM} /></p>
        <div className={styles.illustration}>
          <img src="/assets/svg/press-m-button.svg" alt="" />
        </div>
      </>
    );
  }


  render() {
    const step = this.state.step === 1 ? this.renderStep1() : this.renderStep2();
    return (
      <div className={styles.modal__content}>
        <h3 className="modal__subheader "><FormattedMessage {...messages.headers.howToStartConnection} /></h3>
        { step }
        <div className={styles.divider}>
          <span className={styles.divider__text}><FormattedMessage {...messages.infos.or} /></span>
        </div>
        <Button
          styleModifier="transparent"
          labelMessage={messages.buttons.changeDeviceType}
          className="btn--no-size text--primary m-0"
          onClick={() => this.onChangeDeviceType()}
        />
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  connectionId    : selectors.connectionId(state),
  connectionStatus: selectors.connectionStatus(state),
});

const mapDispatchToProps = (dispatch) => ({
  onConnect                : () => dispatch(actions.connectBlueCable()),
  onStartCheckingConnection: (connectionId) => dispatch(actions.startCheckingConnection(connectionId)),
});

const ConnectedConnectBlueCable = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectBlueCable);


export default ConnectedConnectBlueCable;
