import { call, put, takeLatest, select } from 'redux-saga/effects';
import ApiService from 'services/ApiService';
import App from 'modules/App';
import { accountCCAccessToken } from 'modules/Account/selectors';
import { signOut } from 'modules/Account/actions';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import messages from './messages';


export function* getMe() {
  try {
    const token = yield select(accountCCAccessToken);
    const options = {};
    if (token) {
      const { accessToken } = token;
      if (accessToken) {
        options.headers = {
          ContourCloudAccessToken: accessToken,
        };
      }
    }
    const requestUrl = '/api/ContourCloud/me';
    const profile = yield call(ApiService.regionalRequest, requestUrl, options);
    yield put(actions.getMeSuccess(profile));
  } catch (err) {
    yield put(actions.getMeError(err));
    if (err && err.status === 401) {
      yield put(signOut());
      return;
    }
    yield call(App.dispatchError, err, messages);
  }
}


export function* reauth({ payload }) {
  try {
    const { email, password } = payload;
    const body = {
      contourCloudEmail   : email,
      contourCloudPassword: password,
    };
    const requestUrl = '/api/ContourCloud/reauth';
    const profile = yield call(ApiService.regionalRequest, requestUrl, {
      method: 'POST',
      body,
    });

    yield call(getMe);

    yield put(actions.reauthSuccess(profile));
  } catch (err) {
    yield put(actions.reauthError(err));
    yield call(App.dispatchError, err, messages);
  }
}


function* sagas() {
  yield takeLatest(actionTypes.CONTOUR_CLOUD_ME, getMe);
  yield takeLatest(actionTypes.CONTOUR_CLOUD_REAUTH, reauth);
}

export default [sagas];
